import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const login = (id, token, user) => {
    setUserId(id);
    setToken(token);
    setUser(user);
    localStorage.setItem('userId', id);
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
  };

  const logout = () => {
    setUserId(null);
    setToken(null);
    setUser({});
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/');
  };

  const loginFromLocalStorage = () => {
    const id = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (id !== null && token !== null) {
      setUserId(id);
      setToken(token);
      setUser(user);
    } else {
      logout();
    }
  }

  return (
    <AuthContext.Provider value={{ userId, token, user, login, logout, loginFromLocalStorage, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
