import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";

import CheckoutForm from "./CheckoutForm";
import "./Payment.css";
import { useAuth } from "../AuthContext";
import { BACKEND_URL } from "../constants";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe(
  "pk_test_51P54g2SEjL4XxD4RakF3NLpmNaztNjLJXgVRorF7CP8TM5aeXqAjFCVfMK61cTx6HgQB1yfhZiZ0BR5J3BZA2jJO00jBwVMlMj"
);

const Payment = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [mounted, setMounted] = useState(false);
  const { userId, token, loginFromLocalStorage, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      loginFromLocalStorage();
    }
  }, [mounted, loginFromLocalStorage]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (userId !== null) {
      axios
        .post(
          `${BACKEND_URL}/payment/create-payment-intent`,
          {
            items: [{ id: userId }],
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setClientSecret(response.data.clientSecret);
          setTotalAmount(response.data.amount);
        })
        .catch((error) => {
          console.error("Error fetching client secret:", error);
          if (error.response.status === 401) {
            logout();
            navigate("/");
          }
        });
    }
  }, [userId, token, logout, navigate]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="Payment">
      <div className="payment-container">
        <h3>Total Amount Payable: ₹{totalAmount / 100}</h3> {/* Convert amount from paise to rupees */}
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm clientSecret={clientSecret} userId={userId} />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default Payment;
