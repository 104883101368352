export default `
<!DOCTYPE html>
<html lang="en">

<head>
  <!-- Initial CSS -->
  <style>
    body.device-mobile-optimized {
      overflow-x: hidden;
      overflow-y: scroll
    }

    body.device-mobile-optimized:not(.responsive) #SITE_CONTAINER {
      margin-left: auto;
      margin-right: auto;
      overflow-x: visible;
      position: relative;
      width: 320px
    }

    body.device-mobile-optimized:not(.responsive):not(.blockSiteScrolling) #SITE_CONTAINER {
      margin-top: 0
    }

    body.device-mobile-optimized>* {
      max-width: 100% !important
    }

    body.device-mobile-optimized #site-root {
      overflow-x: hidden;
      overflow-y: hidden
    }

    @supports(overflow:clip) {
      body.device-mobile-optimized #site-root {
        overflow-x: clip;
        overflow-y: clip
      }
    }

    body.device-mobile-non-optimized:not(.removeWrappers) #SITE_CONTAINER #site-root {
      overflow-x: hidden;
      overflow-y: auto
    }

    body.device-mobile-non-optimized.removeWrappers #site-root {
      overflow-x: clip
    }

    body.device-mobile-non-optimized.fullScreenMode {
      background-color: #5f6360
    }

    body.device-mobile-non-optimized.fullScreenMode #MOBILE_ACTIONS_MENU,
    body.device-mobile-non-optimized.fullScreenMode #SITE_BACKGROUND,
    body.device-mobile-non-optimized.fullScreenMode #site-root,
    body.fullScreenMode #WIX_ADS {
      visibility: hidden
    }

    body.fullScreenMode {
      overflow-x: hidden !important;
      overflow-y: hidden !important
    }

    body.fullScreenMode.device-mobile-optimized #TINY_MENU {
      opacity: 0;
      pointer-events: none
    }

    body.fullScreenMode-scrollable.device-mobile-optimized {
      overflow-x: hidden !important;
      overflow-y: auto !important
    }

    body.fullScreenMode-scrollable.device-mobile-optimized #masterPage,
    body.fullScreenMode-scrollable.device-mobile-optimized #site-root {
      overflow-x: hidden !important;
      overflow-y: hidden !important
    }

    body.fullScreenMode-scrollable.device-mobile-optimized #SITE_BACKGROUND,
    body.fullScreenMode-scrollable.device-mobile-optimized #masterPage {
      height: auto !important
    }

    body.fullScreenMode-scrollable.device-mobile-optimized #masterPage.mesh-layout {
      height: 0 !important
    }

    body.blockSiteScrolling {
      position: fixed;
      width: 100%
    }

    body.blockSiteScrolling #SITE_CONTAINER {
      margin-top: calc(var(--blocked-site-scroll-margin-top)*-1)
    }

    body.blockSiteScrolling:not(.responsive) #WIX_ADS {
      margin-top: var(--blocked-site-scroll-margin-top)
    }

    body.blockSiteScrollingWithOverflow {
      overflow-y: hidden
    }

    @media(prefers-reduced-motion:reduce) {

      ::view-transition-group(*),
      ::view-transition-new(*),
      ::view-transition-old(*) {
        animation: none !important
      }
    }

    body,
    html {
      background: transparent;
      border: 0;
      margin: 0;
      outline: 0;
      padding: 0;
      vertical-align: baseline
    }

    body {
      --scrollbar-width: 0px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 10px
    }

    body,
    html {
      height: 100%
    }

    body {
      overflow-x: auto;
      overflow-y: scroll
    }

    body:not(.responsive) #site-root {
      min-width: var(--site-width);
      width: 100%
    }

    body:not([data-js-loaded]) [data-hide-prejs] {
      visibility: hidden
    }

    #SITE_CONTAINER {
      position: relative
    }

    :root {
      --one-unit: 1vw;
      --section-max-width: 9999px
    }

    @supports(-webkit-appearance:none) and (stroke-color:transparent) {
      :root {
        --safari-sticky-fix: opacity
      }
    }

    @supports(container-type:inline-size) {
      :root {
        --one-unit: 1cqw
      }
    }

    [data-mesh-id$=inlineContent-gridContainer]:has(>[id^=oldHoverBox-]) {
      isolation: isolate
    }
  </style>
  <style>
    a,
    abbr,
    acronym,
    address,
    applet,
    b,
    big,
    blockquote,
    button,
    caption,
    center,
    cite,
    code,
    dd,
    del,
    dfn,
    div,
    dl,
    dt,
    em,
    fieldset,
    font,
    footer,
    form,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    header,
    i,
    iframe,
    img,
    ins,
    kbd,
    label,
    legend,
    li,
    nav,
    object,
    ol,
    p,
    pre,
    q,
    s,
    samp,
    section,
    small,
    span,
    strike,
    strong,
    sub,
    sup,
    table,
    tbody,
    td,
    tfoot,
    th,
    thead,
    title,
    tr,
    tt,
    u,
    ul,
    var {
      background: transparent;
      border: 0;
      margin: 0;
      outline: 0;
      padding: 0;
      vertical-align: baseline
    }

    input,
    select,
    textarea {
      box-sizing: border-box;
      font-family: Helvetica, Arial, sans-serif
    }

    ol,
    ul {
      list-style: none
    }

    blockquote,
    q {
      quotes: none
    }

    ins {
      text-decoration: none
    }

    del {
      text-decoration: line-through
    }

    table {
      border-collapse: collapse;
      border-spacing: 0
    }

    a {
      cursor: pointer;
      text-decoration: none
    }

    :focus {
      outline: none
    }

    #site-root {
      margin: 0 auto;
      min-height: 100%;
      position: relative;
      top: var(--wix-ads-height)
    }

    #site-root img:not([src]) {
      visibility: hidden
    }

    #site-root svg img:not([src]) {
      visibility: visible
    }

    #SCROLL_TO_TOP {
      height: 0
    }

    .fullScreenOverlay {
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      overflow-y: hidden;
      position: fixed;
      right: 0;
      top: -60px;
      z-index: 1005
    }

    .fullScreenOverlay>.fullScreenOverlayContent {
      bottom: 0;
      left: 0;
      margin: 0 auto;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 60px;
      transform: translateZ(0)
    }

    [data-mesh-id$=centeredContent],
    [data-mesh-id$=form],
    [data-mesh-id$=inlineContent] {
      pointer-events: none;
      position: relative
    }

    [data-mesh-id$=-gridWrapper],
    [data-mesh-id$=-rotated-wrapper] {
      pointer-events: none
    }

    [data-mesh-id$=-gridContainer]>*,
    [data-mesh-id$=-rotated-wrapper]>*,
    [data-mesh-id$=inlineContent]>:not([data-mesh-id$=-gridContainer]) {
      pointer-events: auto
    }

    .device-mobile-optimized #masterPage.mesh-layout #SOSP_CONTAINER_CUSTOM_ID {
      grid-area: 2/1/3/2;
      -ms-grid-row: 2;
      position: relative
    }

    #masterPage.mesh-layout {
      -ms-grid-rows: max-content max-content min-content max-content;
      -ms-grid-columns: 100%;
      align-items: start;
      display: -ms-grid;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: max-content max-content min-content max-content;
      justify-content: stretch
    }

    #masterPage.mesh-layout #PAGES_CONTAINER,
    #masterPage.mesh-layout #SITE_FOOTER-placeholder,
    #masterPage.mesh-layout #SITE_FOOTER_WRAPPER,
    #masterPage.mesh-layout #SITE_HEADER-placeholder,
    #masterPage.mesh-layout #SITE_HEADER_WRAPPER,
    #masterPage.mesh-layout #SOSP_CONTAINER_CUSTOM_ID[data-state~=mobileView],
    #masterPage.mesh-layout #soapAfterPagesContainer,
    #masterPage.mesh-layout #soapBeforePagesContainer {
      -ms-grid-row-align: start;
      -ms-grid-column-align: start;
      -ms-grid-column: 1
    }

    #masterPage.mesh-layout #SITE_HEADER-placeholder,
    #masterPage.mesh-layout #SITE_HEADER_WRAPPER {
      grid-area: 1/1/2/2;
      -ms-grid-row: 1
    }

    #masterPage.mesh-layout #PAGES_CONTAINER,
    #masterPage.mesh-layout #soapAfterPagesContainer,
    #masterPage.mesh-layout #soapBeforePagesContainer {
      grid-area: 3/1/4/2;
      -ms-grid-row: 3
    }

    #masterPage.mesh-layout #soapAfterPagesContainer,
    #masterPage.mesh-layout #soapBeforePagesContainer {
      width: 100%
    }

    #masterPage.mesh-layout #PAGES_CONTAINER {
      align-self: stretch
    }

    #masterPage.mesh-layout main#PAGES_CONTAINER {
      display: block
    }

    #masterPage.mesh-layout #SITE_FOOTER-placeholder,
    #masterPage.mesh-layout #SITE_FOOTER_WRAPPER {
      grid-area: 4/1/5/2;
      -ms-grid-row: 4
    }

    #masterPage.mesh-layout #SITE_PAGES,
    #masterPage.mesh-layout [data-mesh-id=PAGES_CONTAINERcenteredContent],
    #masterPage.mesh-layout [data-mesh-id=PAGES_CONTAINERinlineContent] {
      height: 100%
    }

    #masterPage.mesh-layout.desktop>* {
      width: 100%
    }

    #masterPage.mesh-layout #PAGES_CONTAINER,
    #masterPage.mesh-layout #SITE_FOOTER_WRAPPER,
    #masterPage.mesh-layout #SITE_HEADER_WRAPPER,
    #masterPage.mesh-layout #SITE_PAGES,
    #masterPage.mesh-layout #masterPageinlineContent,
    #masterPage.mesh-layout:not(.one-doc) #SITE_FOOTER,
    #masterPage.mesh-layout:not(.one-doc) #SITE_HEADER {
      position: relative
    }

    #masterPage.mesh-layout.remove-wrappers #SITE_HEADER {
      grid-area: 1/1/2/2
    }

    #masterPage.mesh-layout.remove-wrappers #SITE_FOOTER {
      grid-area: 4/1/5/2
    }

    [data-z-counter] {
      z-index: 0
    }

    [data-z-counter="0"] {
      z-index: auto
    }
  </style>

  <style>
    .xTjc1A {
      box-sizing: border-box;
      height: 100%;
      overflow: visible;
      position: relative;
      width: auto
    }

    .UiHgGh, .xTjc1A {
      display: inline-block;
      height: 100%;
      width: 100%
    }

    .xTjc1A {
      --display: inline-block;
      cursor: pointer;
      display: var(--display);
      font: var(--fnt, var(--font_1))
    }

    .xTjc1A .yRj2ms {
      padding: 0 var(--pad, 5px)
    }

    .xTjc1A .JS76Uv {
      color: rgb(var(--txt, var(--color_15, color_15)));
      display: inline-block;
      padding: 0 10px;
      transition: var(--trans, color .4s ease 0s)
    }

    .xTjc1A[data-state~=drop] {
      display: block;
      width: 100%
    }

    .xTjc1A[data-state~=drop] .JS76Uv {
      padding: 0 .5em
    }

    .xTjc1A[data-state~=link]:hover .JS76Uv,
    .xTjc1A[data-state~=over] .JS76Uv {
      color: rgb(var(--txth, var(--color_14, color_14)));
      transition: var(--trans, color .4s ease 0s)
    }

    .xTjc1A[data-state~=selected] .JS76Uv {
      color: rgb(var(--txts, var(--color_14, color_14)));
      transition: var(--trans, color .4s ease 0s)
    }

    .NHM1d1 {
      overflow-x: hidden
    }

    .NHM1d1 .R_TAzU {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%
    }

    .NHM1d1 .R_TAzU {
      flex: 1
    }

    .NHM1d1 .R_TAzU .y7qwii {
      height: calc(100% - (var(--menuTotalBordersY, 0px)));
      overflow: visible;
      white-space: nowrap;
      width: calc(100% - (var(--menuTotalBordersX, 0px)))
    }

    .NHM1d1 .R_TAzU .y7qwii .Tg1gOB {
      display: inline-block
    }

    .NHM1d1 .R_TAzU .y7qwii {
      display: block;
      width: 100%
    }

    .NHM1d1 .h3jCPd {
      display: block;
      opacity: 1;
      z-index: 99999
    }

    .NHM1d1 .h3jCPd .wkJ2fp {
      display: inherit;
      overflow: visible;
      visibility: inherit;
      white-space: nowrap;
      width: auto
    }

    .NHM1d1 .h3jCPd.DlGBN0 {
      transition: visibility;
      transition-delay: .2s;
      visibility: visible
    }

    .NHM1d1 .h3jCPd .p90CkU {
      display: inline-block
    }

    .NHM1d1 .vh74Xw {
      display: none
    }

    .XwCBRN>nav {
      bottom: 0;
      left: 0;
      right: 0;
      top: 0
    }

    .XwCBRN .h3jCPd,
    .XwCBRN .y7qwii,
    .XwCBRN>nav {
      position: absolute
    }

    .XwCBRN .h3jCPd {
      margin-top: 7px;
      visibility: hidden
    }

    .XwCBRN .h3jCPd[data-dropMode=dropUp] {
      margin-bottom: 7px;
      margin-top: 0
    }

    .XwCBRN .wkJ2fp {
      background-color: rgba(var(--bgDrop, var(--color_11, color_11)), var(--alpha-bgDrop, 1));
      border-radius: var(--rd, 0);
      box-shadow: var(--shd, 0 1px 4px rgba(0, 0, 0, .6))
    }
  </style>
  <style>
    body:not(.responsive) .JsJXaX {
      overflow-x: clip
    }

    .AnQkDU {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      height: 100%
    }

    .AnQkDU>div {
      align-self: stretch !important;
      grid-area: 1/1/2/2;
      justify-self: stretch !important
    }

    .a9YhBi {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0
    }

    .AKxYR5 {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      fill: var(--corvid-fill-color, var(--fill));
      fill-opacity: var(--fill-opacity);
      stroke: var(--corvid-stroke-color, var(--stroke));
      stroke-opacity: var(--stroke-opacity);
      stroke-width: var(--stroke-width);
      filter: var(--drop-shadow, none);
      opacity: var(--opacity);
      transform: var(--flip)
    }

    .AKxYR5,
    .AKxYR5 svg {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0
    }

    .AKxYR5 svg {
      height: var(--svg-calculated-height, 100%);
      margin: auto;
      padding: var(--svg-calculated-padding, 0);
      width: var(--svg-calculated-width, 100%)
    }

    .AKxYR5 svg:not([data-type=ugc]) {
      overflow: visible
    }

    .VZYmYf * {
      vector-effect: non-scaling-stroke
    }

    @supports(-webkit-hyphens:none) {
      .AKxYR5.vv0uGt {
        will-change: filter
      }
    }

    .HcOXKn {
      -webkit-text-size-adjust: 100%;
      -moz-text-size-adjust: 100%;
      text-size-adjust: 100%
    }

    .QxJLC3 [data-attr-richtext-marker=true] {
      display: block
    }

    .QxJLC3 [data-attr-richtext-marker=true] table {
      border-collapse: collapse;
      margin: 15px 0;
      width: 100%
    }

    .QxJLC3 [data-attr-richtext-marker=true] table td {
      padding: 12px;
      position: relative
    }

    .QxJLC3 [data-attr-richtext-marker=true] table td:after {
      border-bottom: 1px solid currentColor;
      border-left: 1px solid currentColor;
      bottom: 0;
      content: "";
      left: 0;
      opacity: .2;
      position: absolute;
      right: 0;
      top: 0
    }

    .QxJLC3 [data-attr-richtext-marker=true] table tr td:last-child:after {
      border-right: 1px solid currentColor
    }

    .QxJLC3 [data-attr-richtext-marker=true] table tr:first-child td:after {
      border-top: 1px solid currentColor
    }

    @supports(-webkit-appearance:none) and (stroke-color:transparent) {
      .lq2cno>*>:first-child {
        vertical-align: top
      }
    }

    @supports(-webkit-touch-callout:none) {
      .lq2cno>*>:first-child {
        vertical-align: top
      }
    }

    .SxM0TO blockquote,
    .SxM0TO h1,
    .SxM0TO h2,
    .SxM0TO h3,
    .SxM0TO h4,
    .SxM0TO h5,
    .SxM0TO h6,
    .SxM0TO p {
      letter-spacing: normal;
      line-height: normal
    }

    .SxM0TO {
      word-wrap: break-word;
      direction: var(--text-direction);
      min-height: var(--min-height);
      min-width: var(--min-width);
      mix-blend-mode: var(--blendMode, normal);
      overflow-wrap: break-word;
      pointer-events: none;
      text-align: start;
      text-shadow: var(--textOutline, 0 0 transparent), var(--textShadow, 0 0 transparent);
      text-transform: var(--textTransform, "none")
    }

    .SxM0TO>* {
      pointer-events: auto
    }

    .SxM0TO li {
      font-style: inherit;
      font-weight: inherit;
      letter-spacing: normal;
      line-height: inherit
    }

    .SxM0TO ol,
    .SxM0TO ul {
      letter-spacing: normal;
      line-height: normal;
      margin-inline-end: 0;
      margin-inline-start: .5em;
      padding-inline-end: 0;
      padding-inline-start: 1.3em
    }

    .SxM0TO ul {
      list-style-type: disc
    }

    .SxM0TO ol {
      list-style-type: decimal
    }

    .SxM0TO ol ul,
    .SxM0TO ul ul {
      list-style-type: circle
    }

    .SxM0TO ol ol ul,
    .SxM0TO ol ul ul,
    .SxM0TO ul ol ul,
    .SxM0TO ul ul ul {
      list-style-type: square
    }

    .SxM0TO blockquote,
    .SxM0TO h1,
    .SxM0TO h2,
    .SxM0TO h3,
    .SxM0TO h4,
    .SxM0TO h5,
    .SxM0TO h6,
    .SxM0TO p {
      margin-block: 0;
      margin: 0
    }

    .SxM0TO a {
      color: inherit
    }

    .HlRz5e {
      display: block;
      height: 100%;
      width: 100%
    }

    .HlRz5e img {
      max-width: var(--wix-img-max-width, 100%)
    }

    .HlRz5e[data-animate-blur] img {
      filter: blur(9px);
      transition: filter .8s ease-in
    }

    .HlRz5e[data-animate-blur] img[data-load-done] {
      filter: none
    }

    .WzbAF8 .mpGTIt .O6KwRn {
      display: var(--item-display);
      height: var(--item-size);
      margin: var(--item-margin);
      width: var(--item-size)
    }

    .WzbAF8 .mpGTIt .O6KwRn:last-child {
      margin: 0
    }

    .WzbAF8 .mpGTIt .O6KwRn .oRtuWN {
      display: block
    }

    .WzbAF8 .mpGTIt .O6KwRn .oRtuWN .YaS0jR {
      height: var(--item-size);
      width: var(--item-size)
    }

    .WzbAF8 .mpGTIt {
      height: 100%;
      position: absolute;
      white-space: nowrap;
      width: 100%
    }

    :host(.device-mobile-optimized) .WzbAF8 .mpGTIt,
    body.device-mobile-optimized .WzbAF8 .mpGTIt {
      white-space: normal
    }

    .big2ZD {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      height: calc(100% - var(--wix-ads-height));
      left: 0;
      margin-top: var(--wix-ads-height);
      position: fixed;
      top: 0;
      width: 100%
    }

    .big2ZD {
      pointer-events: none;
      z-index: var(--pinned-layer-in-container, var(--above-all-in-container))
    }
  </style>
  <style>
    .PlZyDq {
      touch-action: manipulation
    }

    .uDW_Qe {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      justify-content: var(--label-align);
      min-width: 100%;
      text-align: initial;
      width: -moz-max-content;
      width: max-content
    }

    .uDW_Qe:before {
      max-width: var(--margin-start, 0)
    }

    .uDW_Qe:after,
    .uDW_Qe:before {
      align-self: stretch;
      content: "";
      flex-grow: 1
    }

    .uDW_Qe:after {
      max-width: var(--margin-end, 0)
    }

    .FubTgk {
      height: 100%
    }

    .FubTgk .uDW_Qe {
      border-radius: var(--corvid-border-radius, var(--rd, 0));
      bottom: 0;
      box-shadow: var(--shd, 0 1px 4px rgba(0, 0, 0, .6));
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: var(--trans1, border-color .4s ease 0s, background-color .4s ease 0s)
    }

    .FubTgk .uDW_Qe:link,
    .FubTgk .uDW_Qe:visited {
      border-color: transparent
    }

    .FubTgk .l7_2fn {
      color: var(--corvid-color, rgb(var(--txt, var(--color_15, color_15))));
      font: var(--fnt, var(--font_5));
      margin: 0;
      position: relative;
      transition: var(--trans2, color .4s ease 0s);
      white-space: nowrap
    }

    .FubTgk[aria-disabled=false] .uDW_Qe {
      background-color: var(--corvid-background-color, rgba(var(--bg, var(--color_17, color_17)), var(--alpha-bg, 1)));
      border: solid var(--corvid-border-color, rgba(var(--brd, var(--color_15, color_15)), var(--alpha-brd, 1))) var(--corvid-border-width, var(--brw, 0));
      cursor: pointer !important
    }

    :host(.device-mobile-optimized) .FubTgk[aria-disabled=false]:active .uDW_Qe,
    body.device-mobile-optimized .FubTgk[aria-disabled=false]:active .uDW_Qe {
      background-color: rgba(var(--bgh, var(--color_18, color_18)), var(--alpha-bgh, 1));
      border-color: rgba(var(--brdh, var(--color_15, color_15)), var(--alpha-brdh, 1))
    }

    :host(.device-mobile-optimized) .FubTgk[aria-disabled=false]:active .l7_2fn,
    body.device-mobile-optimized .FubTgk[aria-disabled=false]:active .l7_2fn {
      color: rgb(var(--txth, var(--color_15, color_15)))
    }

    :host(:not(.device-mobile-optimized)) .FubTgk[aria-disabled=false]:hover .uDW_Qe,
    body:not(.device-mobile-optimized) .FubTgk[aria-disabled=false]:hover .uDW_Qe {
      background-color: rgba(var(--bgh, var(--color_18, color_18)), var(--alpha-bgh, 1));
      border-color: rgba(var(--brdh, var(--color_15, color_15)), var(--alpha-brdh, 1))
    }

    :host(:not(.device-mobile-optimized)) .FubTgk[aria-disabled=false]:hover .l7_2fn,
    body:not(.device-mobile-optimized) .FubTgk[aria-disabled=false]:hover .l7_2fn {
      color: rgb(var(--txth, var(--color_15, color_15)))
    }

    .FubTgk[aria-disabled=true] .uDW_Qe {
      background-color: rgba(var(--bgd, 204, 204, 204), var(--alpha-bgd, 1));
      border-color: rgba(var(--brdd, 204, 204, 204), var(--alpha-brdd, 1));
      border-style: solid;
      border-width: var(--corvid-border-width, var(--brw, 0))
    }

    .FubTgk[aria-disabled=true] .l7_2fn {
      color: rgb(var(--txtd, 255, 255, 255))
    }

    :host(:not(.device-mobile-optimized)) .CohWsy,
    body:not(.device-mobile-optimized) .CohWsy {
      display: flex
    }

    :host(:not(.device-mobile-optimized)) .V5AUxf,
    body:not(.device-mobile-optimized) .V5AUxf {
      -moz-column-gap: var(--margin);
      column-gap: var(--margin);
      display: flex;
      flex-direction: var(--items-direction);
      margin: 0 auto;
      position: relative;
      width: calc(100% - var(--padding)*2)
    }

    :host(:not(.device-mobile-optimized)) .V5AUxf>*,
    body:not(.device-mobile-optimized) .V5AUxf>* {
      flex: var(--column-flex) 1 0%;
      left: 0;
      margin-bottom: var(--padding);
      margin-top: var(--padding);
      min-width: 0;
      position: relative;
      top: 0
    }

    :host(.device-mobile-optimized) .V5AUxf,
    body.device-mobile-optimized .V5AUxf {
      display: block;
      padding: var(--padding) 0;
      position: relative
    }

    :host(.device-mobile-optimized) .V5AUxf>*,
    body.device-mobile-optimized .V5AUxf>* {
      margin-bottom: var(--margin);
      position: relative
    }

    :host(.device-mobile-optimized) .V5AUxf>:first-child,
    body.device-mobile-optimized .V5AUxf>:first-child {
      margin-top: var(--firstChildMarginTop, 0)
    }

    :host(.device-mobile-optimized) .V5AUxf>:last-child,
    body.device-mobile-optimized .V5AUxf>:last-child {
      margin-bottom: var(--lastChildMarginBottom)
    }

    .HlRz5e {
      display: block;
      height: 100%;
      width: 100%
    }

    .HlRz5e img {
      max-width: var(--wix-img-max-width, 100%)
    }

    .HlRz5e[data-animate-blur] img {
      filter: blur(9px);
      transition: filter .8s ease-in
    }

    .HlRz5e[data-animate-blur] img[data-load-done] {
      filter: none
    }

    .if7Vw2 {
      height: 100%;
      width: 100%
    }

    .if7Vw2 {
      left: 0;
      -webkit-mask-image: var(--mask-image, none);
      mask-image: var(--mask-image, none);
      -webkit-mask-position: var(--mask-position, 0);
      mask-position: var(--mask-position, 0);
      -webkit-mask-repeat: var(--mask-repeat, no-repeat);
      mask-repeat: var(--mask-repeat, no-repeat);
      -webkit-mask-size: var(--mask-size, 100%);
      mask-size: var(--mask-size, 100%);
      overflow: hidden;
      pointer-events: var(--fill-layer-background-media-pointer-events);
      position: absolute;
      top: 0
    }

    .if7Vw2.f0uTJH {
      clip: rect(0, auto, auto, 0)
    }

    .if7Vw2 .i1tH8h {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%
    }

    .if7Vw2 .DXi4PB {
      height: var(--fill-layer-image-height, 100%);
      opacity: var(--fill-layer-image-opacity)
    }

    .if7Vw2 .DXi4PB img {
      height: 100%;
      width: 100%
    }

    @supports(-webkit-hyphens:none) {
      .if7Vw2.f0uTJH {
        clip: auto;
        -webkit-clip-path: inset(0)
      }
    }

    .wG8dni {
      height: 100%
    }

    .tcElKx {
      background-color: var(--bg-overlay-color);
      background-image: var(--bg-gradient);
      transition: var(--inherit-transition)
    }

    .YzqVVZ {
      overflow: visible;
      position: relative
    }

    .mwF7X1 {
      backface-visibility: hidden
    }

    .MW5IWV {
      height: 100%;
      width: 100%;
      left: 0;
      -webkit-mask-image: var(--mask-image, none);
      mask-image: var(--mask-image, none);
      -webkit-mask-position: var(--mask-position, 0);
      mask-position: var(--mask-position, 0);
      -webkit-mask-repeat: var(--mask-repeat, no-repeat);
      mask-repeat: var(--mask-repeat, no-repeat);
      -webkit-mask-size: var(--mask-size, 100%);
      mask-size: var(--mask-size, 100%);
      overflow: hidden;
      pointer-events: var(--fill-layer-background-media-pointer-events);
      position: absolute;
      top: 0
    }

    .MW5IWV.N3eg0s {
      clip: rect(0, auto, auto, 0)
    }

    .MW5IWV .Kv1aVt {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%
    }

    .MW5IWV .dLPlxY {
      height: var(--fill-layer-image-height, 100%);
      opacity: var(--fill-layer-image-opacity)
    }

    .MW5IWV .dLPlxY img {
      height: 100%;
      width: 100%
    }

    @supports(-webkit-hyphens:none) {
      .MW5IWV.N3eg0s {
        clip: auto;
        -webkit-clip-path: inset(0)
      }
    }

    .VgO9Yg {
      height: 100%
    }

    .LWbAav {
      background-color: var(--bg-overlay-color);
      background-image: var(--bg-gradient);
      transition: var(--inherit-transition)
    }

    .mNGsUM {
      transform: scale(var(--scale, 1));
      transition: var(--transform-duration, transform 0s)
    }

    .SUz0WK {
      left: 0;
      pointer-events: var(--fill-layer-background-media-pointer-events);
      position: var(--fill-layer-background-media-position)
    }

    .SUz0WK,
    .m4khSP {
      height: 100%;
      top: 0;
      width: 100%
    }

    .m4khSP {
      background-color: var(--fill-layer-background-overlay-color);
      opacity: var(--fill-layer-background-overlay-blend-opacity-fallback, 1);
      position: var(--fill-layer-background-overlay-position);
      transform: var(--fill-layer-background-overlay-transform)
    }

    @supports(mix-blend-mode:overlay) {
      .m4khSP {
        mix-blend-mode: var(--fill-layer-background-overlay-blend-mode);
        opacity: var(--fill-layer-background-overlay-blend-opacity, 1)
      }
    }

    ._C0cVf {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%
    }

    .CJF7A2 {
      height: auto
    }

    .CJF7A2,
    .U4Bvut {
      position: relative;
      width: 100%
    }

    :host(:not(.device-mobile-optimized)) .G5K6X8,
    body:not(.device-mobile-optimized) .G5K6X8 {
      margin-left: calc((100% - var(--site-width))/2);
      width: var(--site-width)
    }

    .xU8fqS[data-focuscycled=active] {
      outline: 1px solid transparent
    }

    .xU8fqS[data-focuscycled=active]:not(:focus-within) {
      outline: 2px solid transparent;
      transition: outline .01s ease
    }

    .xU8fqS ._4XcTfy {
      background-color: var(--screenwidth-corvid-background-color, rgba(var(--bg, var(--color_11, color_11)), var(--alpha-bg, 1)));
      border-bottom: var(--brwb, 0) solid var(--screenwidth-corvid-border-color, rgba(var(--brd, var(--color_15, color_15)), var(--alpha-brd, 1)));
      border-top: var(--brwt, 0) solid var(--screenwidth-corvid-border-color, rgba(var(--brd, var(--color_15, color_15)), var(--alpha-brd, 1)));
      bottom: 0;
      box-shadow: var(--shd, 0 0 5px rgba(0, 0, 0, .7));
      left: 0;
      position: absolute;
      right: 0;
      top: 0
    }

    .xU8fqS .gUbusX {
      background-color: rgba(var(--bgctr, var(--color_11, color_11)), var(--alpha-bgctr, 1));
      border-radius: var(--rd, 0);
      bottom: var(--brwb, 0);
      top: var(--brwt, 0)
    }

    .xU8fqS .G5K6X8,
    .xU8fqS .gUbusX {
      left: 0;
      position: absolute;
      right: 0
    }

    .xU8fqS .G5K6X8 {
      bottom: 0;
      top: 0
    }

    :host(.device-mobile-optimized) .xU8fqS .G5K6X8,
    body.device-mobile-optimized .xU8fqS .G5K6X8 {
      left: 10px;
      right: 10px
    }

    .SPY_vo {
      pointer-events: none
    }

    .j7pOnl {
      box-sizing: border-box;
      height: 100%;
      width: 100%
    }

    .BI8PVQ {
      min-height: var(--image-min-height);
      min-width: var(--image-min-width)
    }

    .BI8PVQ img,
    img.BI8PVQ {
      filter: var(--filter-effect-svg-url);
      -webkit-mask-image: var(--mask-image, none);
      mask-image: var(--mask-image, none);
      -webkit-mask-position: var(--mask-position, 0);
      mask-position: var(--mask-position, 0);
      -webkit-mask-repeat: var(--mask-repeat, no-repeat);
      mask-repeat: var(--mask-repeat, no-repeat);
      -webkit-mask-size: var(--mask-size, 100% 100%);
      mask-size: var(--mask-size, 100% 100%);
      -o-object-position: var(--object-position);
      object-position: var(--object-position)
    }

    .MazNVa {
      left: var(--left, auto);
      position: var(--position-fixed, static);
      top: var(--top, auto);
      z-index: var(--z-index, auto)
    }

    .MazNVa .BI8PVQ img {
      box-shadow: 0 0 0 #000;
      position: static;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none
    }

    .MazNVa .j7pOnl {
      display: block;
      overflow: hidden
    }

    .MazNVa .BI8PVQ {
      overflow: hidden
    }

    .PFkO7r {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0
    }

    .HT5ybB {
      height: auto;
      position: relative;
      width: 100%
    }

    body:not(.responsive) .dBAkHi {
      align-self: start;
      grid-area: 1/1/1/1;
      height: 100%;
      justify-self: stretch;
      left: 0;
      position: relative
    }

    :host(:not(.device-mobile-optimized)) .PFkO7r,
    body:not(.device-mobile-optimized) .PFkO7r {
      margin-left: calc((100% - var(--site-width))/2);
      width: var(--site-width)
    }

    :host(.device-mobile-optimized) .PFkO7r,
    body.device-mobile-optimized .PFkO7r {
      left: 10px;
      right: 10px
    }
  </style>
  <style>

    .jbrj02 {
      --container-corvid-background-color: rgba(var(--bg, var(--color_11, color_11)), var(--alpha-bg, 1))
    }

    .IvR70V {
      background-color: var(--container-corvid-background-color, rgba(var(--bg, var(--color_11, color_11)), var(--alpha-bg, 1)));
      bottom: 0;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0
    }
  </style>
  <style>
    .QOGIpb {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      list-style-type: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 100%
    }

    .tCKgoz {
      direction: var(--direction)
    }

    .tCKgoz .jpyXrk {
      align-items: center;
      cursor: default;
      display: flex;
      flex-direction: var(--orientation, "row")
    }

    .tCKgoz .jpyXrk.vuZaJx .zJEZL4,
    .tCKgoz .jpyXrk:active .zJEZL4,
    .tCKgoz .jpyXrk:hover .zJEZL4 {
      fill: rgb(var(--symbolColorSelected, var(--color_2, color_2)));
      stroke: rgb(var(--symbolColorSelected, var(--color_2, color_2)))
    }

    .tCKgoz .zJEZL4 {
      fill: transparent;
      stroke: rgb(var(--symbolColor, var(--color_15, color_15)));
      stroke-width: 4px;
      cursor: pointer;
      height: 12px;
      transition: all .2s ease-in-out;
      width: 12px
    }

    .tCKgoz .JnSp5B {
      display: none;
      white-space: nowrap
    }
  </style>
  <style>
    .aVng1S {
      border-top: var(--lnw, 2px) solid rgba(var(--brd, var(--color_15, color_15)), var(--alpha-brd, 1));
      box-sizing: border-box;
      height: 0
    }
  </style>
  <style>
    .KvoMHf.ErucfC[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      margin: 0
    }

    .KvoMHf[disabled] {
      pointer-events: none
    }

    .MpKiNN {
      --display: flex;
      direction: var(--direction);
      display: var(--display);
      flex-direction: column;
      min-height: 25px;
      position: relative;
      text-align: var(--align, start)
    }

    .MpKiNN .pUnTVX {
      height: var(--inputHeight);
      position: relative
    }

    .MpKiNN .eXvLsN {
      align-items: center;
      display: flex;
      font: var(--fntprefix, normal normal normal 16px/1.4em helvetica-w01-roman);
      justify-content: center;
      left: 0;
      max-height: 100%;
      min-height: 100%;
      position: absolute;
      top: 0;
      width: 50px
    }

    .MpKiNN .KvoMHf,
    .MpKiNN .eXvLsN {
      color: var(--corvid-color, rgb(var(--txt, var(--color_15, color_15))))
    }

    .MpKiNN .KvoMHf {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: var(--corvid-background-color, rgba(var(--bg, 255, 255, 255), var(--alpha-bg, 1)));
      border-color: var(--corvid-border-color, rgba(var(--brd, 227, 227, 227), var(--alpha-brd, 1)));
      border-radius: var(--corvid-border-radius, var(--rd, 0));
      border-style: solid;
      border-width: var(--corvid-border-width, var(--brw, 1px));
      box-shadow: var(--shd, 0 0 0 transparent);
      box-sizing: border-box !important;
      direction: var(--inputDirection, "inherit");
      font: var(--fnt, var(--font_8));
      margin: 0;
      max-width: 100%;
      min-height: var(--inputHeight);
      padding: var(--textPadding);
      padding-inline-end: var(--textPadding_end);
      padding-inline-start: var(--textPadding_start);
      text-align: var(--inputAlign, "inherit");
      text-overflow: ellipsis;
      width: 100%
    }

    .MpKiNN .KvoMHf[type=number] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      width: 100%
    }

    .MpKiNN .KvoMHf::-moz-placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .MpKiNN .KvoMHf:-ms-input-placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .MpKiNN .KvoMHf::placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .MpKiNN .KvoMHf:hover {
      background-color: rgba(var(--bgh, 255, 255, 255), var(--alpha-bgh, 1));
      border-color: rgba(var(--brdh, 163, 217, 246), var(--alpha-brdh, 1));
      border-style: solid;
      border-width: var(--brwh, 1px)
    }

    .MpKiNN .KvoMHf:disabled {
      background-color: rgba(var(--bgd, 204, 204, 204), var(--alpha-bgd, 1));
      border-color: rgba(var(--brdd, 163, 217, 246), var(--alpha-brdd, 1));
      border-style: solid;
      border-width: var(--brwd, 1px);
      color: rgb(var(--txtd, 255, 255, 255))
    }

    .MpKiNN:not(.LyB02C) .KvoMHf:focus {
      background-color: rgba(var(--bgf, 255, 255, 255), var(--alpha-bgf, 1));
      border-color: rgba(var(--brdf, 163, 217, 246), var(--alpha-brdf, 1));
      border-style: solid;
      border-width: var(--brwf, 1px)
    }

    .MpKiNN.LyB02C .KvoMHf:invalid {
      background-color: rgba(var(--bge, 255, 255, 255), var(--alpha-bge, 1));
      border-color: rgba(var(--brde, 163, 217, 246), var(--alpha-brde, 1));
      border-style: solid;
      border-width: var(--brwe, 1px)
    }

    .MpKiNN.LyB02C .KvoMHf:not(:invalid):focus {
      background-color: rgba(var(--bgf, 255, 255, 255), var(--alpha-bgf, 1));
      border-color: rgba(var(--brdf, 163, 217, 246), var(--alpha-brdf, 1));
      border-style: solid;
      border-width: var(--brwf, 1px)
    }

    .MpKiNN .wPeA6j {
      display: none
    }

    .MpKiNN.qzvPmW .wPeA6j {
      color: rgb(var(--txtlbl, var(--color_15, color_15)));
      direction: var(--labelDirection, inherit);
      display: inline-block;
      font: var(--fntlbl, var(--font_8));
      line-height: 1;
      margin-bottom: var(--labelMarginBottom);
      padding-inline-end: var(--labelPadding_end);
      padding-inline-start: var(--labelPadding_start);
      text-align: var(--labelAlign, inherit);
      word-break: break-word
    }

    .MpKiNN.qzvPmW.lPl_oN .wPeA6j:after {
      color: rgba(var(--txtlblrq, 0, 0, 0), var(--alpha-txtlblrq, 0));
      content: " *";
      display: var(--requiredIndicationDisplay, none)
    }

    .ZXdXNL {
      --display: flex;
      direction: var(--direction);
      display: var(--display);
      flex-direction: column;
      justify-content: var(--align, start);
      text-align: var(--align, start)
    }

    .ZXdXNL.Bedjyp {
      height: inherit;
      min-height: inherit
    }

    .ZXdXNL .pUnTVX {
      display: flex;
      flex: 1;
      flex-direction: column;
      position: relative
    }

    .ZXdXNL .eXvLsN {
      align-items: center;
      display: flex;
      font: var(--fntprefix, normal normal normal 16px/1.4em helvetica-w01-roman);
      justify-content: center;
      left: 0;
      max-height: 100%;
      position: absolute;
      top: 0;
      width: 50px
    }

    .ZXdXNL .KvoMHf,
    .ZXdXNL .eXvLsN {
      color: var(--corvid-color, rgb(var(--txt, var(--color_15, color_15))));
      min-height: 100%
    }

    .ZXdXNL .KvoMHf {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: var(--corvid-background-color, rgba(var(--bg, 255, 255, 255), var(--alpha-bg, 1)));
      border-color: var(--corvid-border-color, rgba(var(--brd, 227, 227, 227), var(--alpha-brd, 1)));
      border-radius: var(--corvid-border-radius, var(--rd, 0));
      border-style: solid;
      border-width: var(--corvid-border-width, var(--brw, 1px));
      box-shadow: var(--shd, 0 0 0 transparent);
      box-sizing: border-box !important;
      direction: var(--inputDirection, "inherit");
      flex: 1;
      font: var(--fnt, var(--font_8));
      margin: 0;
      padding: var(--textPadding);
      padding-inline-end: var(--textPadding_end);
      padding-inline-start: var(--textPadding_start);
      text-align: var(--inputAlign, "inherit");
      text-overflow: ellipsis;
      width: 100%
    }

    .ZXdXNL .KvoMHf[type=number] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield
    }

    .ZXdXNL .KvoMHf::-moz-placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .ZXdXNL .KvoMHf:-ms-input-placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .ZXdXNL .KvoMHf::placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .ZXdXNL .KvoMHf:hover {
      background-color: rgba(var(--bgh, 255, 255, 255), var(--alpha-bgh, 1));
      border-color: rgba(var(--brdh, 163, 217, 246), var(--alpha-brdh, 1));
      border-style: solid;
      border-width: var(--brwh, 1px)
    }

    .ZXdXNL .KvoMHf:disabled {
      background-color: rgba(var(--bgd, 204, 204, 204), var(--alpha-bgd, 1));
      border-color: rgba(var(--brdd, 163, 217, 246), var(--alpha-brdd, 1));
      border-style: solid;
      border-width: var(--brwd, 1px);
      color: rgb(var(--txtd, 255, 255, 255))
    }

    .ZXdXNL:not(.LyB02C) .KvoMHf:focus {
      background-color: rgba(var(--bgf, 255, 255, 255), var(--alpha-bgf, 1));
      border-color: rgba(var(--brdf, 163, 217, 246), var(--alpha-brdf, 1));
      border-style: solid;
      border-width: var(--brwf, 1px)
    }

    .ZXdXNL.LyB02C .KvoMHf:invalid {
      background-color: rgba(var(--bge, 255, 255, 255), var(--alpha-bge, 1));
      border-color: rgba(var(--brde, 163, 217, 246), var(--alpha-brde, 1));
      border-style: solid;
      border-width: var(--brwe, 1px)
    }

    .ZXdXNL.LyB02C .KvoMHf:not(:invalid):focus {
      background-color: rgba(var(--bgf, 255, 255, 255), var(--alpha-bgf, 1));
      border-color: rgba(var(--brdf, 163, 217, 246), var(--alpha-brdf, 1));
      border-style: solid;
      border-width: var(--brwf, 1px)
    }

    .ZXdXNL .wPeA6j {
      display: none
    }

    .ZXdXNL.qzvPmW .wPeA6j {
      color: rgb(var(--txtlbl, var(--color_15, color_15)));
      direction: var(--labelDirection, inherit);
      display: inline-block;
      font: var(--fntlbl, var(--font_8));
      line-height: 1;
      margin-bottom: var(--labelMarginBottom);
      padding-inline-end: var(--labelPadding_end);
      padding-inline-start: var(--labelPadding_start);
      text-align: var(--labelAlign, inherit);
      word-break: break-word
    }

    .ZXdXNL.qzvPmW.lPl_oN .wPeA6j:after {
      color: rgba(var(--txtlblrq, 0, 0, 0), var(--alpha-txtlblrq, 0));
      content: " *";
      display: var(--requiredIndicationDisplay, none)
    }

    .TLWrjM {
      align-items: center;
      color: rgb(var(--errorTextColor, #ff4040));
      direction: var(--errorDirection);
      display: flex;
      flex-direction: row;
      gap: 4px;
      justify-content: var(--errorAlign, inherit);
      padding: 8px 0 0
    }

    .TLWrjM .GIPQqH {
      flex: none;
      flex-grow: 0;
      order: 0
    }

    .TLWrjM .JNgJbf {
      display: inline-block;
      flex-grow: 0;
      font: var(--errorTextFont, var(--font_8));
      line-height: 1;
      order: 1;
      word-break: break-word
    }
  </style>
  <style>
    .snt4Te {
      direction: var(--direction);
      text-align: var(--align)
    }

    .snt4Te .rEindN {
      -webkit-appearance: none;
      background-color: var(--corvid-background-color, rgba(var(--bg, 255, 255, 255), var(--alpha-bg, 1)));
      border-color: var(--corvid-border-color, rgba(var(--brd, 227, 227, 227), var(--alpha-brd, 1)));
      border-radius: var(--corvid-border-radius, var(--rd, 0));
      border-style: solid;
      border-width: var(--corvid-border-width, var(--brw, 1px));
      box-shadow: var(--shd, 0 0 0 transparent);
      box-sizing: border-box !important;
      color: var(--corvid-color, rgb(var(--txt, var(--color_15, color_15))));
      direction: var(--inputDirection);
      display: block;
      font: var(--fnt, var(--font_8));
      height: var(--inputHeight);
      margin: 0;
      max-width: 100%;
      min-width: 100%;
      overflow-y: auto;
      padding-bottom: 3px;
      padding-inline-end: var(--textPaddingEnd);
      padding-top: var(--textPaddingTop);
      padding-inline-start: var(--textPaddingStart);
      padding-top: .75em;
      resize: none;
      text-align: var(--inputAlign, "inherit")
    }

    .snt4Te .rEindN::-moz-placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .snt4Te .rEindN:-ms-input-placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .snt4Te .rEindN::placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .snt4Te .rEindN:hover {
      background-color: rgba(var(--bgh, 255, 255, 255), var(--alpha-bgh, 1));
      border-color: rgba(var(--brdh, 163, 217, 246), var(--alpha-brdh, 1));
      border-style: solid;
      border-width: var(--brwh, 1px)
    }

    .snt4Te .rEindN:disabled {
      background-color: rgba(var(--bgd, 204, 204, 204), var(--alpha-bgd, 1));
      border-color: rgba(var(--brdd, 163, 217, 246), var(--alpha-brdd, 1));
      border-style: solid;
      border-width: var(--brwd, 1px);
      color: rgb(var(--txtd, 255, 255, 255));
      pointer-events: none
    }

    .snt4Te:not(.pOExcs) .rEindN:focus {
      background-color: rgba(var(--bgf, 255, 255, 255), var(--alpha-bgf, 1));
      border-color: rgba(var(--brdf, 163, 217, 246), var(--alpha-brdf, 1));
      border-style: solid;
      border-width: var(--brwf, 1px)
    }

    .snt4Te.pOExcs .rEindN:invalid {
      background-color: rgba(var(--bge, 255, 255, 255), var(--alpha-bge, 1));
      border-color: rgba(var(--brde, 163, 217, 246), var(--alpha-brde, 1));
      border-style: solid;
      border-width: var(--brwe, 1px)
    }

    .snt4Te.pOExcs .rEindN:not(:invalid):focus {
      background-color: rgba(var(--bgf, 255, 255, 255), var(--alpha-bgf, 1));
      border-color: rgba(var(--brdf, 163, 217, 246), var(--alpha-brdf, 1));
      border-style: solid;
      border-width: var(--brwf, 1px)
    }

    .snt4Te .PSkPrR {
      display: none
    }

    .snt4Te.oKe0Po .PSkPrR {
      box-sizing: border-box;
      color: rgb(var(--txtlbl, var(--color_15, color_15)));
      direction: var(--labelDirection);
      display: inline-block;
      font: var(--fntlbl, var(--font_8));
      line-height: 1;
      margin-bottom: var(--labelMarginBottom, 14px);
      padding-inline-end: var(--labelPadding_end, 0);
      padding-inline-start: var(--labelPadding_start, 0);
      text-align: var(--labelAlign, inherit);
      width: 100%;
      word-break: break-word
    }

    .snt4Te.oKe0Po.NyPO4H .PSkPrR:after {
      color: rgba(var(--txtlblrq, 0, 0, 0), var(--alpha-txtlblrq, 0));
      content: " *";
      display: var(--requiredIndicationDisplay, none)
    }

    .lk9PkF {
      --display: flex;
      direction: var(--direction);
      display: var(--display);
      flex-direction: column;
      text-align: var(--align)
    }

    .lk9PkF.q2xQkn {
      height: inherit;
      min-height: inherit
    }

    .lk9PkF .rEindN {
      -webkit-appearance: none;
      background-color: var(--corvid-background-color, rgba(var(--bg, 255, 255, 255), var(--alpha-bg, 1)));
      border-color: var(--corvid-border-color, rgba(var(--brd, 227, 227, 227), var(--alpha-brd, 1)));
      border-radius: var(--corvid-border-radius, var(--rd, 0));
      border-style: solid;
      border-width: var(--corvid-border-width, var(--brw, 1px));
      box-shadow: var(--shd, 0 0 0 transparent);
      box-sizing: border-box !important;
      color: var(--corvid-color, rgb(var(--txt, var(--color_15, color_15))));
      direction: var(--inputDirection);
      flex: 1;
      font: var(--fnt, var(--font_8));
      height: 100%;
      margin: 0;
      overflow-y: auto;
      padding-bottom: 3px;
      padding-inline-end: var(--textPaddingEnd);
      padding-top: var(--textPaddingTop);
      padding-inline-start: var(--textPaddingStart);
      resize: none;
      text-align: var(--inputAlign, "inherit");
      width: 100%
    }

    .lk9PkF .rEindN::-moz-placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .lk9PkF .rEindN:-ms-input-placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .lk9PkF .rEindN::placeholder {
      color: rgb(var(--txt2, var(--color_15, color_15)))
    }

    .lk9PkF .rEindN:hover {
      background-color: rgba(var(--bgh, 255, 255, 255), var(--alpha-bgh, 1));
      border-color: rgba(var(--brdh, 163, 217, 246), var(--alpha-brdh, 1));
      border-style: solid;
      border-width: var(--brwh, 1px)
    }

    .lk9PkF .rEindN:disabled {
      background-color: rgba(var(--bgd, 204, 204, 204), var(--alpha-bgd, 1));
      border-color: rgba(var(--brdd, 163, 217, 246), var(--alpha-brdd, 1));
      border-style: solid;
      border-width: var(--brwd, 1px);
      color: rgb(var(--txtd, 255, 255, 255));
      pointer-events: none
    }

    .lk9PkF:not(.pOExcs) .rEindN:focus {
      background-color: rgba(var(--bgf, 255, 255, 255), var(--alpha-bgf, 1));
      border-color: rgba(var(--brdf, 163, 217, 246), var(--alpha-brdf, 1));
      border-style: solid;
      border-width: var(--brwf, 1px)
    }

    .lk9PkF.pOExcs .rEindN:invalid {
      background-color: rgba(var(--bge, 255, 255, 255), var(--alpha-bge, 1));
      border-color: rgba(var(--brde, 163, 217, 246), var(--alpha-brde, 1));
      border-style: solid;
      border-width: var(--brwe, 1px)
    }

    .lk9PkF.pOExcs .rEindN:not(:invalid):focus {
      background-color: rgba(var(--bgf, 255, 255, 255), var(--alpha-bgf, 1));
      border-color: rgba(var(--brdf, 163, 217, 246), var(--alpha-brdf, 1));
      border-style: solid;
      border-width: var(--brwf, 1px)
    }

    .lk9PkF .PSkPrR {
      display: none
    }

    .lk9PkF.oKe0Po .PSkPrR {
      box-sizing: border-box;
      color: rgb(var(--txtlbl, var(--color_15, color_15)));
      direction: var(--labelDirection);
      display: inline-block;
      font: var(--fntlbl, var(--font_8));
      line-height: 1;
      margin-bottom: var(--labelMarginBottom, 14px);
      padding-inline-end: var(--labelPadding_end, 0);
      padding-inline-start: var(--labelPadding_start, 0);
      text-align: var(--labelAlign, inherit);
      width: 100%;
      word-break: break-word
    }

    .lk9PkF.oKe0Po.NyPO4H .PSkPrR:after {
      color: rgba(var(--txtlblrq, 0, 0, 0), var(--alpha-txtlblrq, 0));
      content: " *";
      display: var(--requiredIndicationDisplay, none)
    }

    .TLWrjM {
      align-items: center;
      color: rgb(var(--errorTextColor, #ff4040));
      direction: var(--errorDirection);
      display: flex;
      flex-direction: row;
      gap: 4px;
      justify-content: var(--errorAlign, inherit);
      padding: 8px 0 0
    }

    .TLWrjM .GIPQqH {
      flex: none;
      flex-grow: 0;
      order: 0
    }

    .TLWrjM .JNgJbf {
      display: inline-block;
      flex-grow: 0;
      font: var(--errorTextFont, var(--font_8));
      line-height: 1;
      order: 1;
      word-break: break-word
    }
  </style>
  <style>
    .MW5IWV {
      height: 100%;
      left: 0;
      -webkit-mask-image: var(--mask-image, none);
      mask-image: var(--mask-image, none);
      -webkit-mask-position: var(--mask-position, 0);
      mask-position: var(--mask-position, 0);
      -webkit-mask-repeat: var(--mask-repeat, no-repeat);
      mask-repeat: var(--mask-repeat, no-repeat);
      -webkit-mask-size: var(--mask-size, 100%);
      mask-size: var(--mask-size, 100%);
      overflow: hidden;
      pointer-events: var(--fill-layer-background-media-pointer-events);
      position: absolute;
      top: 0;
      width: 100%
    }

    .MW5IWV.N3eg0s {
      clip: rect(0, auto, auto, 0)
    }

    .MW5IWV .Kv1aVt {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%
    }

    .MW5IWV .dLPlxY {
      height: var(--fill-layer-image-height, 100%);
      opacity: var(--fill-layer-image-opacity)
    }

    .MW5IWV .dLPlxY img {
      height: 100%;
      width: 100%
    }

    @supports(-webkit-hyphens:none) {
      .MW5IWV.N3eg0s {
        clip: auto;
        -webkit-clip-path: inset(0)
      }
    }

    .VgO9Yg {
      height: 100%
    }

    .LWbAav {
      background-color: var(--bg-overlay-color);
      background-image: var(--bg-gradient);
      transition: var(--inherit-transition)
    }

    .mNGsUM {
      transform: scale(var(--scale, 1));
      transition: var(--transform-duration, transform 0s)
    }

    .Oqnisf {
      overflow: visible
    }

    .Oqnisf .MW5IWV .LWbAav {
      background-color: var(--corvid-background-color, var(--bg-overlay-color))
    }

    .cM88eO {
      backface-visibility: hidden
    }

    .YtfWHd {
      left: 0;
      position: absolute;
      top: 0
    }

    .HlRz5e {
      display: block;
      height: 100%;
      width: 100%
    }

    .HlRz5e img {
      max-width: var(--wix-img-max-width, 100%)
    }

    .HlRz5e[data-animate-blur] img {
      filter: blur(9px);
      transition: filter .8s ease-in
    }

    .HlRz5e[data-animate-blur] img[data-load-done] {
      filter: none
    }

    .SUz0WK {
      left: 0;
      pointer-events: var(--fill-layer-background-media-pointer-events);
      position: var(--fill-layer-background-media-position)
    }

    .SUz0WK,
    .m4khSP {
      height: 100%;
      top: 0;
      width: 100%
    }

    .m4khSP {
      background-color: var(--fill-layer-background-overlay-color);
      opacity: var(--fill-layer-background-overlay-blend-opacity-fallback, 1);
      position: var(--fill-layer-background-overlay-position);
      transform: var(--fill-layer-background-overlay-transform)
    }

    @supports(mix-blend-mode:overlay) {
      .m4khSP {
        mix-blend-mode: var(--fill-layer-background-overlay-blend-mode);
        opacity: var(--fill-layer-background-overlay-blend-opacity, 1)
      }
    }

    .dkukWC {
      --divider-pin-height__: min(1, calc(var(--divider-layers-pin-factor__) + 1));
      --divider-pin-layer-height__: var(--divider-layers-pin-factor__);
      --divider-pin-border__: min(1, calc(var(--divider-layers-pin-factor__) / -1 + 1));
      height: calc(var(--divider-height__) + var(--divider-pin-height__)*var(--divider-layers-size__)*var(--divider-layers-y__))
    }

    .dkukWC,
    .dkukWC .FRCqDF {
      left: 0;
      position: absolute;
      width: 100%
    }

    .dkukWC .FRCqDF {
      --divider-layer-i__: var(--divider-layer-i, 0);
      background-position: left calc(50% + var(--divider-offset-x__) + var(--divider-layers-x__)*var(--divider-layer-i__)) bottom;
      background-repeat: repeat-x;
      border-bottom-style: solid;
      border-bottom-width: calc(var(--divider-pin-border__)*var(--divider-layer-i__)*var(--divider-layers-y__));
      height: calc(var(--divider-height__) + var(--divider-pin-layer-height__)*var(--divider-layer-i__)*var(--divider-layers-y__));
      opacity: calc(1 - var(--divider-layer-i__)/(var(--divider-layer-i__) + 1))
    }

    .xnZvZH {
      --divider-height__: var(--divider-top-height, auto);
      --divider-offset-x__: var(--divider-top-offset-x, 0px);
      --divider-layers-size__: var(--divider-top-layers-size, 0);
      --divider-layers-y__: var(--divider-top-layers-y, 0px);
      --divider-layers-x__: var(--divider-top-layers-x, 0px);
      --divider-layers-pin-factor__: var(--divider-top-layers-pin-factor, 0);
      border-top: var(--divider-top-padding, 0) solid var(--divider-top-color, currentColor);
      opacity: var(--divider-top-opacity, 1);
      top: 0;
      transform: var(--divider-top-flip, scaleY(-1))
    }

    .xnZvZH .FRCqDF {
      background-image: var(--divider-top-image, none);
      background-size: var(--divider-top-size, contain);
      border-color: var(--divider-top-color, currentColor);
      bottom: 0;
      filter: var(--divider-top-filter, none)
    }

    .xnZvZH .FRCqDF[data-divider-layer="1"] {
      display: var(--divider-top-layer-1-display, block)
    }

    .xnZvZH .FRCqDF[data-divider-layer="2"] {
      display: var(--divider-top-layer-2-display, block)
    }

    .xnZvZH .FRCqDF[data-divider-layer="3"] {
      display: var(--divider-top-layer-3-display, block)
    }

    .MBOSCN {
      --divider-height__: var(--divider-bottom-height, auto);
      --divider-offset-x__: var(--divider-bottom-offset-x, 0px);
      --divider-layers-size__: var(--divider-bottom-layers-size, 0);
      --divider-layers-y__: var(--divider-bottom-layers-y, 0px);
      --divider-layers-x__: var(--divider-bottom-layers-x, 0px);
      --divider-layers-pin-factor__: var(--divider-bottom-layers-pin-factor, 0);
      border-bottom: var(--divider-bottom-padding, 0) solid var(--divider-bottom-color, currentColor);
      bottom: 0;
      opacity: var(--divider-bottom-opacity, 1);
      transform: var(--divider-bottom-flip, none)
    }

    .MBOSCN .FRCqDF {
      background-image: var(--divider-bottom-image, none);
      background-size: var(--divider-bottom-size, contain);
      border-color: var(--divider-bottom-color, currentColor);
      bottom: 0;
      filter: var(--divider-bottom-filter, none)
    }

    .MBOSCN .FRCqDF[data-divider-layer="1"] {
      display: var(--divider-bottom-layer-1-display, block)
    }

    .MBOSCN .FRCqDF[data-divider-layer="2"] {
      display: var(--divider-bottom-layer-2-display, block)
    }

    .MBOSCN .FRCqDF[data-divider-layer="3"] {
      display: var(--divider-bottom-layer-3-display, block)
    }
  </style>
  <style>

    .vsejaj {
      --container-corvid-border-color: rgba(var(--brd, var(--color_15, color_15)), var(--alpha-brd, 1));
      --container-corvid-border-size: var(--brw, 3px);
      --container-corvid-background-color: rgba(var(--bg, var(--color_11, color_11)), var(--alpha-bg, 1))
    }

    .T6TiIN {
      background-color: var(--container-corvid-background-color, rgba(var(--bg, var(--color_11, color_11)), var(--alpha-bg, 1)));
      border: var(--container-corvid-border-width, var(--brw, 3px)) solid var(--container-corvid-border-color, rgba(var(--brd, var(--color_15, color_15)), var(--alpha-brd, 1)));
      border-radius: 50%;
      bottom: 0;
      box-shadow: var(--shd, 0 1px 4px rgba(0, 0, 0, .6));
      left: 0;
      position: absolute;
      right: 0;
      top: 0
    }
  </style>
  <style>
    .JMUd8Z.mDzRgi,
    .QodGTM.mDzRgi {
      --display: grid;
      background-color: transparent;
      box-sizing: border-box;
      display: var(--display);
      min-height: 50px;
      position: relative
    }

    .JMUd8Z.mDzRgi .XvQ3FE,
    .QodGTM.mDzRgi .XvQ3FE {
      align-self: center;
      cursor: pointer;
      display: var(--nav-button-display);
      grid-area: 1/1/1/1;
      margin: 0 var(--nav-button-offset);
      position: absolute;
      width: var(--nav-button-width);
      z-index: 1
    }

    .JMUd8Z.mDzRgi .XvQ3FE.CdshHv,
    .QodGTM.mDzRgi .XvQ3FE.CdshHv {
      justify-self: start
    }

    .JMUd8Z.mDzRgi .XvQ3FE.Qinjwp,
    .QodGTM.mDzRgi .XvQ3FE.Qinjwp {
      justify-self: end
    }

    .JMUd8Z.mDzRgi .hDJzl4,
    .QodGTM.mDzRgi .hDJzl4 {
      grid-area: 1/1/1/1;
      height: 100%;
      overflow: var(--slides-overflow);
      position: absolute;
      width: 100%
    }

    .JMUd8Z.mDzRgi .hDJzl4.tAoGqR,
    .QodGTM.mDzRgi .hDJzl4.tAoGqR {
      overflow: var(--transition-overflow, var(--slides-overflow))
    }

    .JMUd8Z.mDzRgi .hDJzl4>*,
    .QodGTM.mDzRgi .hDJzl4>* {
      overflow: var(--slides-overflow)
    }

    .JMUd8Z.mDzRgi .T23gn7.hDJzl4,
    .QodGTM.mDzRgi .T23gn7.hDJzl4 {
      height: auto;
      position: relative
    }

    .JMUd8Z.mDzRgi .T23gn7.hDJzl4>*,
    .QodGTM.mDzRgi .T23gn7.hDJzl4>* {
      top: 0
    }

    .JMUd8Z.mDzRgi .T23gn7.hDJzl4>:not(:first-child),
    .QodGTM.mDzRgi .T23gn7.hDJzl4>:not(:first-child) {
      --force-state-metadata: false;
      position: absolute !important
    }

    .JMUd8Z.mDzRgi .N2NGoO,
    .QodGTM.mDzRgi .N2NGoO {
      border-radius: var(--rd, 0);
      bottom: 0;
      box-shadow: var(--shd, 0 0 0 rgba(0, 0, 0, .6));
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0
    }

    .JMUd8Z.mDzRgi .lZ5yqT,
    .QodGTM.mDzRgi .lZ5yqT {
      align-self: end;
      display: var(--nav-dot-section-display);
      grid-area: 1/1/1/1;
      justify-self: center;
      margin-bottom: var(--nav-dot-section-bottom-margin);
      position: absolute;
      transform: translateY(50%)
    }

    .JMUd8Z.mDzRgi .lZ5yqT .dZxZOk,
    .QodGTM.mDzRgi .lZ5yqT .dZxZOk {
      align-items: center;
      display: flex;
      justify-content: center;
      pointer-events: none;
      position: relative;
      width: 100%
    }

    .JMUd8Z.mDzRgi .lZ5yqT .ZVUGJp,
    .QodGTM.mDzRgi .lZ5yqT .ZVUGJp {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      height: var(--nav-dot-size);
      margin: 0 var(--nav-dot-margin);
      pointer-events: auto;
      position: relative;
      width: var(--nav-dot-size)
    }

    .JMUd8Z.mDzRgi .lZ5yqT .ZVUGJp.Ale4Rm,
    .QodGTM.mDzRgi .lZ5yqT .ZVUGJp.Ale4Rm {
      height: var(--nav-dot-size-selected);
      width: var(--nav-dot-size-selected)
    }

    .JMUd8Z .XvQ3FE:hover,
    .QodGTM .XvQ3FE:hover {
      opacity: .6
    }

    .JMUd8Z .XvQ3FE.Qinjwp,
    .QodGTM .XvQ3FE.Qinjwp {
      transform: scaleX(-1)
    }

    .JMUd8Z .XvQ3FE svg,
    .QodGTM .XvQ3FE svg {
      fill: rgba(var(--arrowColor, var(--color_12, color_12)), var(--alpha-arrowColor, 1));
      stroke: rgba(var(--arrowColor, var(--color_12, color_12)), var(--alpha-arrowColor, 1));
      stroke-width: 1px
    }

    .JMUd8Z .ZVUGJp {
      background-color: rgba(var(--dotsColor, var(--color_12, color_12)), var(--alpha-dotsColor, 1));
      border-radius: 50%
    }

    .JMUd8Z .ZVUGJp.Ale4Rm {
      background-color: rgba(var(--dotsSelectedColor, var(--color_11, color_11)), var(--alpha-dotsSelectedColor, 1))
    }

    .QodGTM .ZVUGJp {
      background-color: rgba(var(--dotsColor, var(--color_12, color_12)), var(--alpha-dotsColor, 1));
      border-radius: 50%
    }

    .QodGTM .ZVUGJp.Ale4Rm {
      background-color: transparent;
      border: 2px solid rgba(var(--dotsColor, var(--color_12, color_12)), var(--alpha-dotsColor, 1))
    }

    .ENJ6Ca.mDzRgi {
      --display: grid;
      background-color: transparent;
      box-sizing: border-box;
      display: var(--display);
      min-height: 50px;
      position: relative
    }

    .ENJ6Ca.mDzRgi .XvQ3FE {
      align-self: center;
      cursor: pointer;
      display: var(--nav-button-display);
      grid-area: 1/1/1/1;
      margin: 0 var(--nav-button-offset);
      position: absolute;
      width: var(--nav-button-width);
      z-index: 1
    }

    .ENJ6Ca.mDzRgi .XvQ3FE.CdshHv {
      justify-self: start
    }

    .ENJ6Ca.mDzRgi .XvQ3FE.Qinjwp {
      justify-self: end
    }

    .ENJ6Ca.mDzRgi .hDJzl4 {
      grid-area: 1/1/1/1;
      height: 100%;
      overflow: var(--slides-overflow);
      position: absolute;
      width: 100%
    }

    .ENJ6Ca.mDzRgi .hDJzl4.tAoGqR {
      overflow: var(--transition-overflow, var(--slides-overflow))
    }

    .ENJ6Ca.mDzRgi .hDJzl4>* {
      overflow: var(--slides-overflow)
    }

    .ENJ6Ca.mDzRgi .T23gn7.hDJzl4 {
      height: auto;
      position: relative
    }

    .ENJ6Ca.mDzRgi .T23gn7.hDJzl4>* {
      top: 0
    }

    .ENJ6Ca.mDzRgi .T23gn7.hDJzl4>:not(:first-child) {
      --force-state-metadata: false;
      position: absolute !important
    }

    .ENJ6Ca.mDzRgi .N2NGoO {
      border-radius: var(--rd, 0);
      bottom: 0;
      box-shadow: var(--shd, 0 0 0 rgba(0, 0, 0, .6));
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0
    }

    .ENJ6Ca.mDzRgi .lZ5yqT {
      align-self: end;
      display: var(--nav-dot-section-display);
      grid-area: 1/1/1/1;
      justify-self: center;
      margin-bottom: var(--nav-dot-section-bottom-margin);
      position: absolute;
      transform: translateY(50%)
    }

    .ENJ6Ca.mDzRgi .lZ5yqT .dZxZOk {
      align-items: center;
      display: flex;
      justify-content: center;
      pointer-events: none;
      position: relative;
      width: 100%
    }

    .ENJ6Ca.mDzRgi .lZ5yqT .ZVUGJp {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      height: var(--nav-dot-size);
      margin: 0 var(--nav-dot-margin);
      pointer-events: auto;
      position: relative;
      width: var(--nav-dot-size)
    }

    .ENJ6Ca.mDzRgi .lZ5yqT .ZVUGJp.Ale4Rm {
      height: var(--nav-dot-size-selected);
      width: var(--nav-dot-size-selected)
    }

    .ENJ6Ca .XvQ3FE:hover {
      opacity: .6
    }

    .ENJ6Ca .XvQ3FE.CdshHv {
      transform: scaleX(-1)
    }

    .ENJ6Ca .XvQ3FE svg {
      fill: rgba(var(--arrowColor, var(--color_12, color_12)), var(--alpha-arrowColor, 1))
    }

    .ENJ6Ca .ZVUGJp {
      background-color: rgba(var(--dotsColor, var(--color_12, color_12)), var(--alpha-dotsColor, 1));
      border-radius: 50%
    }

    .ENJ6Ca .ZVUGJp.Ale4Rm {
      background-color: transparent;
      border: 2px solid rgba(var(--dotsColor, var(--color_12, color_12)), var(--alpha-dotsColor, 1))
    }

    .ghJlnq.mDzRgi {
      --display: grid;
      background-color: transparent;
      box-sizing: border-box;
      display: var(--display);
      min-height: 50px;
      position: relative
    }

    .ghJlnq.mDzRgi .XvQ3FE {
      align-self: center;
      cursor: pointer;
      display: var(--nav-button-display);
      grid-area: 1/1/1/1;
      margin: 0 var(--nav-button-offset);
      position: absolute;
      width: var(--nav-button-width);
      z-index: 1
    }

    .ghJlnq.mDzRgi .XvQ3FE.CdshHv {
      justify-self: start
    }

    .ghJlnq.mDzRgi .XvQ3FE.Qinjwp {
      justify-self: end
    }

    .ghJlnq.mDzRgi .hDJzl4 {
      grid-area: 1/1/1/1;
      height: 100%;
      overflow: var(--slides-overflow);
      position: absolute;
      width: 100%
    }

    .ghJlnq.mDzRgi .hDJzl4.tAoGqR {
      overflow: var(--transition-overflow, var(--slides-overflow))
    }

    .ghJlnq.mDzRgi .hDJzl4>* {
      overflow: var(--slides-overflow)
    }

    .ghJlnq.mDzRgi .T23gn7.hDJzl4 {
      height: auto;
      position: relative
    }

    .ghJlnq.mDzRgi .T23gn7.hDJzl4>* {
      top: 0
    }

    .ghJlnq.mDzRgi .T23gn7.hDJzl4>:not(:first-child) {
      --force-state-metadata: false;
      position: absolute !important
    }

    .ghJlnq.mDzRgi .N2NGoO {
      border-radius: var(--rd, 0);
      bottom: 0;
      box-shadow: var(--shd, 0 0 0 rgba(0, 0, 0, .6));
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0
    }

    .ghJlnq.mDzRgi .lZ5yqT {
      align-self: end;
      display: var(--nav-dot-section-display);
      grid-area: 1/1/1/1;
      justify-self: center;
      margin-bottom: var(--nav-dot-section-bottom-margin);
      position: absolute;
      transform: translateY(50%)
    }

    .ghJlnq.mDzRgi .lZ5yqT .dZxZOk {
      align-items: center;
      display: flex;
      justify-content: center;
      pointer-events: none;
      position: relative;
      width: 100%
    }

    .ghJlnq.mDzRgi .lZ5yqT .ZVUGJp {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      height: var(--nav-dot-size);
      margin: 0 var(--nav-dot-margin);
      pointer-events: auto;
      position: relative;
      width: var(--nav-dot-size)
    }

    .ghJlnq.mDzRgi .lZ5yqT .ZVUGJp.Ale4Rm {
      height: var(--nav-dot-size-selected);
      width: var(--nav-dot-size-selected)
    }

    .ghJlnq .XvQ3FE:hover {
      opacity: .6
    }

    .ghJlnq .XvQ3FE.Qinjwp {
      transform: scaleX(-1)
    }

    .ghJlnq .XvQ3FE svg path:first-child {
      fill: rgba(var(--arrowContainerColor, var(--color_11, color_11)), var(--alpha-arrowContainerColor, 1))
    }

    .ghJlnq .XvQ3FE svg path:last-child {
      fill: rgba(var(--arrowColor, var(--color_12, color_12)), var(--alpha-arrowColor, 1))
    }

    .ghJlnq .ZVUGJp {
      background-color: rgba(var(--dotsColor, var(--color_12, color_12)), var(--alpha-dotsColor, 1))
    }

    .ghJlnq .ZVUGJp.Ale4Rm {
      background-color: rgba(var(--dotsSelectedColor, var(--color_11, color_11)), var(--alpha-dotsSelectedColor, 1))
    }

    .XVHXq_.mDzRgi {
      --display: grid;
      background-color: transparent;
      box-sizing: border-box;
      display: var(--display);
      min-height: 50px;
      position: relative
    }

    .XVHXq_.mDzRgi .XvQ3FE {
      align-self: center;
      cursor: pointer;
      display: var(--nav-button-display);
      grid-area: 1/1/1/1;
      margin: 0 var(--nav-button-offset);
      position: absolute;
      width: var(--nav-button-width);
      z-index: 1
    }

    .XVHXq_.mDzRgi .XvQ3FE.CdshHv {
      justify-self: start
    }

    .XVHXq_.mDzRgi .XvQ3FE.Qinjwp {
      justify-self: end
    }

    .XVHXq_.mDzRgi .hDJzl4 {
      grid-area: 1/1/1/1;
      height: 100%;
      overflow: var(--slides-overflow);
      position: absolute;
      width: 100%
    }

    .XVHXq_.mDzRgi .hDJzl4.tAoGqR {
      overflow: var(--transition-overflow, var(--slides-overflow))
    }

    .XVHXq_.mDzRgi .hDJzl4>* {
      overflow: var(--slides-overflow)
    }

    .XVHXq_.mDzRgi .T23gn7.hDJzl4 {
      height: auto;
      position: relative
    }

    .XVHXq_.mDzRgi .T23gn7.hDJzl4>* {
      top: 0
    }

    .XVHXq_.mDzRgi .T23gn7.hDJzl4>:not(:first-child) {
      --force-state-metadata: false;
      position: absolute !important
    }

    .XVHXq_.mDzRgi .N2NGoO {
      border-radius: var(--rd, 0);
      bottom: 0;
      box-shadow: var(--shd, 0 0 0 rgba(0, 0, 0, .6));
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0
    }

    .XVHXq_.mDzRgi .lZ5yqT {
      align-self: end;
      display: var(--nav-dot-section-display);
      grid-area: 1/1/1/1;
      justify-self: center;
      margin-bottom: var(--nav-dot-section-bottom-margin);
      position: absolute;
      transform: translateY(50%)
    }

    .XVHXq_.mDzRgi .lZ5yqT .dZxZOk {
      align-items: center;
      display: flex;
      justify-content: center;
      pointer-events: none;
      position: relative;
      width: 100%
    }

    .XVHXq_.mDzRgi .lZ5yqT .ZVUGJp {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      height: var(--nav-dot-size);
      margin: 0 var(--nav-dot-margin);
      pointer-events: auto;
      position: relative;
      width: var(--nav-dot-size)
    }

    .XVHXq_.mDzRgi .lZ5yqT .ZVUGJp.Ale4Rm {
      height: var(--nav-dot-size-selected);
      width: var(--nav-dot-size-selected)
    }

    .XVHXq_.mDzRgi {
      min-height: unset
    }
  </style>
  <style>
    .imK94d .eF_jBL {
      border-radius: var(--rd, 0);
      will-change: var(--corners-overflow-fix-will-change, initial)
    }

    .imK94d .wfm0FO {
      border: var(--brw, 0) solid rgba(var(--brd, var(--color_11, color_11)), var(--alpha-brd, 1));
      border-radius: var(--rd, 0);
      bottom: 0;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0
    }

    .HlRz5e {
      display: block;
      height: 100%;
      width: 100%
    }

    .HlRz5e img {
      max-width: var(--wix-img-max-width, 100%)
    }

    .HlRz5e[data-animate-blur] img {
      filter: blur(9px);
      transition: filter .8s ease-in
    }

    .HlRz5e[data-animate-blur] img[data-load-done] {
      filter: none
    }

    .MW5IWV {
      height: 100%;
      width: 100%
    }

    .MW5IWV {
      left: 0;
      -webkit-mask-image: var(--mask-image, none);
      mask-image: var(--mask-image, none);
      -webkit-mask-position: var(--mask-position, 0);
      mask-position: var(--mask-position, 0);
      -webkit-mask-repeat: var(--mask-repeat, no-repeat);
      mask-repeat: var(--mask-repeat, no-repeat);
      -webkit-mask-size: var(--mask-size, 100%);
      mask-size: var(--mask-size, 100%);
      overflow: hidden;
      pointer-events: var(--fill-layer-background-media-pointer-events);
      position: absolute;
      top: 0
    }

    .MW5IWV.N3eg0s {
      clip: rect(0, auto, auto, 0)
    }

    .MW5IWV .Kv1aVt {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%
    }

    .MW5IWV .dLPlxY {
      height: var(--fill-layer-image-height, 100%);
      opacity: var(--fill-layer-image-opacity)
    }

    .MW5IWV .dLPlxY img {
      height: 100%;
      width: 100%
    }

    @supports(-webkit-hyphens:none) {
      .MW5IWV.N3eg0s {
        clip: auto;
        -webkit-clip-path: inset(0)
      }
    }

    .VgO9Yg {
      height: 100%
    }

    .LWbAav {
      background-color: var(--bg-overlay-color);
      background-image: var(--bg-gradient);
      transition: var(--inherit-transition)
    }

    .mNGsUM {
      transform: scale(var(--scale, 1));
      transition: var(--transform-duration, transform 0s)
    }

    .SUz0WK {
      left: 0;
      pointer-events: var(--fill-layer-background-media-pointer-events);
      position: var(--fill-layer-background-media-position)
    }

    .SUz0WK,
    .m4khSP {
      height: 100%;
      top: 0;
      width: 100%
    }

    .m4khSP {
      background-color: var(--fill-layer-background-overlay-color);
      opacity: var(--fill-layer-background-overlay-blend-opacity-fallback, 1);
      position: var(--fill-layer-background-overlay-position);
      transform: var(--fill-layer-background-overlay-transform)
    }

    @supports(mix-blend-mode:overlay) {
      .m4khSP {
        mix-blend-mode: var(--fill-layer-background-overlay-blend-mode);
        opacity: var(--fill-layer-background-overlay-blend-opacity, 1)
      }
    }
  </style>
  <style>
    @-moz-document url-prefix() {
      :invalid {
        box-shadow: none
      }

      :-moz-submit-invalid,
      :-moz-ui-invalid {
        box-shadow: none
      }
    }

    @keyframes kYZz2Z {
      0% {
        animation-timing-function: ease-out;
        transform: rotate(180deg)
      }

      45% {
        transform: rotate(198deg)
      }

      55% {
        transform: rotate(234deg)
      }

      to {
        transform: rotate(540deg)
      }
    }

    @keyframes wlf4P4 {
      to {
        opacity: 1;
        transform: rotate(115deg)
      }
    }

    .bkIuWA.xXaCpo {
      --display: flex;
      align-items: center;
      display: var(--display);
      justify-content: center
    }

    .d1WWt1 {
      animation: kYZz2Z 1s linear infinite;
      height: 72px;
      margin-left: -18px;
      overflow: hidden;
      position: absolute;
      transform-origin: 100% 50%;
      width: 36px
    }

    .d1WWt1:after,
    .d1WWt1:before {
      animation: wlf4P4 .5s linear infinite alternate;
      border: 3px solid currentColor;
      border-color: currentColor transparent transparent currentColor;
      border-radius: 50%;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: -100%;
      top: 0;
      transform: rotate(-45deg)
    }

    .d1WWt1:before {
      color: #7fccf7
    }

    .d1WWt1:after {
      color: #3899ec;
      opacity: 0
    }
  </style>
  <style>
    .JVi7i2,
    .hrbzfT {
      background-color: var(--corvid-background-color, rgba(var(--bg, 0, 0, 0), var(--alpha-bg, 0)));
      border: solid var(--corvid-border-color, rgba(var(--brd, 227, 227, 227), var(--alpha-brd, 1))) var(--corvid-border-width, var(--brw, 0));
      border-radius: var(--corvid-border-radius, var(--rd, 0));
      box-shadow: var(--shd, 0 0 0 transparent)
    }

    .d3n4V3 {
      height: 100%;
      left: -var(--corvid-border-width, var(--brw, 0));
      position: absolute;
      top: -var(--corvid-border-width, var(--brw, 0));
      width: 100%
    }
  </style>
  <style>
    .OQ8Tzd,
    .nKphmK {
      overflow: hidden
    }

    .nKphmK {
      height: 100%;
      position: relative;
      width: 100%
    }

    .nKphmK:-ms-fullscreen {
      min-height: auto !important
    }

    .nKphmK:fullscreen {
      min-height: auto !important
    }

    .pvlz2w {
      visibility: hidden
    }
  </style>
  <style>
    .TWFxr5 {
      height: auto !important
    }

    .May50y {
      overflow: hidden !important
    }
  </style>
  <title>Home | Stockalert Co In</title>
  <link rel="canonical" href="#">
  <meta name="robots" content="noindex">
  <meta property="og:title" content="Home | Stockalert Co In">
  <meta property="og:url" content="#">
  <meta property="og:site_name" content="Stockalert Co In">
  <meta property="og:type" content="website">
  <meta name="twitter:card" content="summary_large_image">
  <meta name="twitter:title" content="Home | Stockalert Co In">
  <style rel="stylesheet">
    .s__8Bs7BZ.oegn4nj--madefor,
    .soMKJ2w.o__9Ts4BE--madefor {
      --wbu-font-stack: var(--wix-font-stack);
      --wbu-font-weight-regular: var(--wix-font-weight-regular);
      --wbu-font-weight-medium: var(--wix-font-weight-medium);
      --wbu-font-weight-bold: var(--wix-font-weight-bold)
    }

    .Q8TtId {
      padding: 0;
      position: relative
    }

    .Q8TtId>svg {
      bottom: 0;
      left: 0;
      position: absolute !important;
      right: 0;
      top: 0
    }

    .aZhaoZ {
      opacity: 0
    }

    .s1dvzA {
      display: block;
      outline: none;
      text-decoration: none;
      width: 100%
    }

    .s1dvzA,
    .s1dvzA svg {
      overflow: visible
    }

    .js-focus-visible .s1dvzA:focus {
      box-shadow: none;
      position: relative
    }

    .js-focus-visible .s1dvzA:focus:after {
      box-shadow: inset 0 0 1px 1px #3899ec, inset 0 0 0 2px hsla(0, 0%, 100%, .9);
      content: "";
      height: 100%;
      left: 0;
      outline: none;
      position: absolute;
      top: 0;
      width: 100%
    }

    .C2k87E {
      display: block;
      outline: none;
      overflow: visible;
      text-decoration: none;
      width: 100%
    }

    .js-focus-visible .C2k87E:focus {
      box-shadow: none;
      position: relative
    }

    .js-focus-visible .C2k87E:focus:after {
      box-shadow: inset 0 0 1px 1px #3899ec, inset 0 0 0 2px hsla(0, 0%, 100%, .9);
      content: "";
      height: 100%;
      left: 0;
      outline: none;
      position: absolute;
      top: 0;
      width: 100%
    }

    ._EREUE {
      display: block;
      outline: none;
      overflow: hidden;
      text-decoration: none;
      width: 100%
    }

    .js-focus-visible ._EREUE:focus {
      box-shadow: none;
      position: relative
    }

    .js-focus-visible ._EREUE:focus:after {
      box-shadow: inset 0 0 1px 1px #3899ec, inset 0 0 0 2px hsla(0, 0%, 100%, .9);
      content: "";
      height: 100%;
      left: 0;
      outline: none;
      position: absolute;
      top: 0;
      width: 100%
    }

    .tx4Jvn text.uxskpx {
      font-size: 50px !important
    }

    .tx4Jvn.qZfbbY .uxskpx {
      font-size: 45px !important
    }

    .tx4Jvn.fzGViX .uxskpx {
      font-size: 37px !important
    }

    .DRb0Pe.qZfbbY .uxskpx {
      font-size: 80px !important
    }

    .DRb0Pe.fzGViX .uxskpx {
      font-size: 58px !important
    }

    .WWgVyT.qZfbbY .uxskpx {
      font-size: 60px !important
    }

    .WWgVyT.fzGViX .uxskpx {
      font-size: 45px !important
    }

    .XPTyZQ.qZfbbY .uxskpx {
      font-size: 60px !important
    }

    .XPTyZQ.fzGViX .uxskpx {
      font-size: 40px !important
    }

    .KpNISr.qZfbbY .uxskpx {
      font-size: 70px !important
    }

    .KpNISr.fzGViX .uxskpx {
      font-size: 60px !important
    }

    .l3royO.qZfbbY .uxskpx {
      font-size: 80px !important
    }

    .l3royO.fzGViX .uxskpx {
      font-size: 60px !important
    }

    .hAeODa.qZfbbY .uxskpx {
      font-size: 75px !important
    }

    .hAeODa.fzGViX .uxskpx {
      font-size: 55px !important
    }

    .spQjTI.qZfbbY .uxskpx {
      font-size: 75px !important
    }

    .spQjTI.fzGViX .uxskpx {
      font-size: 59px !important
    }

    .yA1DNe.qZfbbY .uxskpx {
      font-size: 80px !important
    }

    .yA1DNe.fzGViX .uxskpx {
      font-size: 65px !important
    }

    .Rl4inp.qZfbbY .uxskpx {
      font-size: 75px !important
    }

    .Rl4inp.fzGViX .uxskpx {
      font-size: 60px !important
    }

    .of9Ja5.qZfbbY .uxskpx {
      font-size: 80px !important
    }

    .of9Ja5.fzGViX .uxskpx {
      font-size: 60px !important
    }
  </style>
  <style id="css_masterPage">
    #masterPage:not(.landingPage) #PAGES_CONTAINER {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    #masterPage.landingPage #SITE_HEADER {
      display: none;
    }

    #masterPage.landingPage #SITE_FOOTER {
      display: none;
    }

    #masterPage.landingPage #comp-ita18ph6 {
      display: none;
    }

    #masterPage.landingPage #comp-ir9le55m {
      display: none;
    }

    #masterPage.landingPage #SITE_HEADER-placeholder {
      display: none;
    }

    #masterPage.landingPage #SITE_FOOTER-placeholder {
      display: none;
    }

    #SITE_CONTAINER.focus-ring-active :not(.has-custom-focus):not(.ignore-focus):not([tabindex="-1"]):focus,
    #SITE_CONTAINER.focus-ring-active :not(.has-custom-focus):not(.ignore-focus):not([tabindex="-1"]):focus~.wixSdkShowFocusOnSibling {
      --focus-ring-box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #116dff;
      box-shadow: var(--focus-ring-box-shadow) !important;
      z-index: 1;
    }

    .has-inner-focus-ring {
      --focus-ring-box-shadow: inset 0 0 0 1px #ffffff, inset 0 0 0 3px #116dff !important;
    }

    #masterPage {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    #SITE_HEADER {
      z-index: 50;
      --above-all-in-container: 10000;
    }

    #SITE_FOOTER {
      --pinned-layer-in-container: 51;
      --above-all-in-container: 49;
    }

    #PAGES_CONTAINER {
      --pinned-layer-in-container: 52;
      --above-all-in-container: 49;
    }

    #comp-ita18ph6-pinned-layer {
      z-index: calc(var(--pinned-layers-in-page, 0) + 53);
      --above-all-in-container: 10000;
    }

    #comp-ir9le55m-pinned-layer {
      z-index: calc(var(--pinned-layers-in-page, 0) + 54);
      --above-all-in-container: 10000;
    }

    #comp-jqqt3y1u-pinned-layer {
      z-index: calc(var(--pinned-layers-in-page, 0) + 55);
      --above-all-in-container: 10000;
    }

    #comp-ita18ph6 {
      justify-self: start;
      align-self: start;
      position: absolute;
      grid-area: 1 / 1 / 2 / 2;
      pointer-events: auto;
    }

    #comp-ir9le55m {
      justify-self: end;
      margin-right: 31px;
      align-self: start;
      margin-top: 21px;
      position: absolute;
      grid-area: 1 / 1 / 2 / 2;
      pointer-events: auto;
    }

    #comp-jqqt3y1u {
      justify-self: start;
      align-self: end;
      position: absolute;
      grid-area: 1 / 1 / 2 / 2;
      pointer-events: auto;
    }

    :root,
    :host,
    .spxThemeOverride,
    .max-width-container {
      --color_0: 255, 255, 255;
      --color_1: 255, 255, 255;
      --color_2: 0, 0, 0;
      --color_3: 237, 28, 36;
      --color_4: 0, 136, 203;
      --color_5: 255, 203, 5;
      --color_6: 114, 114, 114;
      --color_7: 176, 176, 176;
      --color_8: 255, 255, 255;
      --color_9: 114, 114, 114;
      --color_10: 176, 176, 176;
      --color_11: 6, 21, 81;
      --color_12: 240, 240, 240;
      --color_13: 160, 160, 159;
      --color_14: 47, 46, 46;
      --color_15: 47, 46, 46;
      --color_16: 228, 217, 255;
      --color_17: 173, 139, 247;
      --color_18: 133, 77, 255;
      --color_19: 89, 51, 170;
      --color_20: 44, 26, 85;
      --color_21: 242, 247, 255;
      --color_22: 158, 193, 247;
      --color_23: 46, 88, 255;
      --color_24: 24, 51, 158;
      --color_25: 6, 21, 81;
      --color_26: 235, 254, 255;
      --color_27: 142, 245, 250;
      --color_28: 12, 221, 232;
      --color_29: 18, 175, 184;
      --color_30: 14, 88, 92;
      --color_31: 232, 255, 238;
      --color_32: 155, 250, 180;
      --color_33: 71, 255, 120;
      --color_34: 55, 204, 95;
      --color_35: 34, 125, 58;
      --color_36: 255, 255, 255;
      --color_37: 47, 46, 46;
      --color_38: 240, 240, 240;
      --color_39: 160, 160, 159;
      --color_40: 96, 94, 94;
      --color_41: 133, 77, 255;
      --color_42: 173, 139, 247;
      --color_43: 6, 21, 81;
      --color_44: 235, 254, 255;
      --color_45: 47, 46, 46;
      --color_46: 47, 46, 46;
      --color_47: 96, 94, 94;
      --color_48: 133, 77, 255;
      --color_49: 133, 77, 255;
      --color_50: 255, 255, 255;
      --color_51: 255, 255, 255;
      --color_52: 133, 77, 255;
      --color_53: 133, 77, 255;
      --color_54: 160, 160, 159;
      --color_55: 160, 160, 159;
      --color_56: 255, 255, 255;
      --color_57: 255, 255, 255;
      --color_58: 133, 77, 255;
      --color_59: 133, 77, 255;
      --color_60: 133, 77, 255;
      --color_61: 133, 77, 255;
      --color_62: 255, 255, 255;
      --color_63: 255, 255, 255;
      --color_64: 160, 160, 159;
      --color_65: 160, 160, 159;
      --font_0: normal normal bold 28px/1.4em raleway, sans-serif;
      --font_1: normal normal normal 16px/1.4em din-next-w01-light, sans-serif;
      --font_2: normal normal bold 55px/1.4em raleway, sans-serif;
      --font_3: normal normal bold 55px/1.4em raleway, sans-serif;
      --font_4: normal normal bold 40px/1.4em raleway, sans-serif;
      --font_5: normal normal bold 20px/1.4em raleway, sans-serif;
      --font_6: normal normal normal 18px/1.4em raleway, sans-serif;
      --font_7: normal normal normal 18px/1.4em raleway, sans-serif;
      --font_8: normal normal normal 16px/1.4em raleway, sans-serif;
      --font_9: normal normal normal 14px/1.4em raleway, sans-serif;
      --font_10: normal normal normal 12px/1.4em din-next-w01-light, sans-serif;
      --wix-ads-height: 0px;
      --sticky-offset: 50px;
      --wix-ads-top-height: 50px;
      --vh-unit: calc((100vh - 50px) / 100);
      --site-width: 980px;
      --above-all-z-index: 100000;
      --portals-z-index: 100001;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      --minViewportSize: 320;
      --maxViewportSize: 1920;
      --theme-spx-ratio: 1px;
      --scaling-factor: min((100 * var(--one-unit)), var(--section-max-width));
      --customScaleViewportLimit: clamp(var(--minViewportSize) * 1px, 100 * var(--one-unit), min(var(--section-max-width), var(--maxViewportSize) * 1px));
    }

    .font_0 {
      font: var(--font_0);
      color: rgb(var(--color_43));
      letter-spacing: 0em;
    }

    .font_1 {
      font: var(--font_1);
      color: rgb(var(--color_40));
      letter-spacing: 0em;
    }

    .font_2 {
      font: var(--font_2);
      color: rgb(var(--color_45));
      letter-spacing: 0em;
    }

    .font_3 {
      font: var(--font_3);
      color: rgb(var(--color_45));
      letter-spacing: 0em;
    }

    .font_4 {
      font: var(--font_4);
      color: rgb(var(--color_45));
      letter-spacing: 0em;
    }

    .font_5 {
      font: var(--font_5);
      color: rgb(var(--color_19));
      letter-spacing: 0em;
    }

    .font_6 {
      font: var(--font_6);
      color: rgb(var(--color_19));
      letter-spacing: 0em;
    }

    .font_7 {
      font: var(--font_7);
      color: rgb(var(--color_15));
      letter-spacing: 0em;
    }

    .font_8 {
      font: var(--font_8);
      color: rgb(var(--color_40));
      letter-spacing: 0em;
    }

    .font_9 {
      font: var(--font_9);
      color: rgb(var(--color_15));
      letter-spacing: 0em;
    }

    .font_10 {
      font: var(--font_10);
      color: rgb(var(--color_40));
      letter-spacing: 0em;
    }

    .color_0 {
      color: rgb(var(--color_0));
    }

    .color_1 {
      color: rgb(var(--color_1));
    }

    .color_2 {
      color: rgb(var(--color_2));
    }

    .color_3 {
      color: rgb(var(--color_3));
    }

    .color_4 {
      color: rgb(var(--color_4));
    }

    .color_5 {
      color: rgb(var(--color_5));
    }

    .color_6 {
      color: rgb(var(--color_6));
    }

    .color_7 {
      color: rgb(var(--color_7));
    }

    .color_8 {
      color: rgb(var(--color_8));
    }

    .color_9 {
      color: rgb(var(--color_9));
    }

    .color_10 {
      color: rgb(var(--color_10));
    }

    .color_11 {
      color: rgb(var(--color_11));
    }

    .color_12 {
      color: rgb(var(--color_12));
    }

    .color_13 {
      color: rgb(var(--color_13));
    }

    .color_14 {
      color: rgb(var(--color_14));
    }

    .color_15 {
      color: rgb(var(--color_15));
    }

    .color_16 {
      color: rgb(var(--color_16));
    }

    .color_17 {
      color: rgb(var(--color_17));
    }

    .color_18 {
      color: rgb(var(--color_18));
    }

    .color_19 {
      color: rgb(var(--color_19));
    }

    .color_20 {
      color: rgb(var(--color_20));
    }

    .color_21 {
      color: rgb(var(--color_21));
    }

    .color_22 {
      color: rgb(var(--color_22));
    }

    .color_23 {
      color: rgb(var(--color_23));
    }

    .color_24 {
      color: rgb(var(--color_24));
    }

    .color_25 {
      color: rgb(var(--color_25));
    }

    .color_26 {
      color: rgb(var(--color_26));
    }

    .color_27 {
      color: rgb(var(--color_27));
    }

    .color_28 {
      color: rgb(var(--color_28));
    }

    .color_29 {
      color: rgb(var(--color_29));
    }

    .color_30 {
      color: rgb(var(--color_30));
    }

    .color_31 {
      color: rgb(var(--color_31));
    }

    .color_32 {
      color: rgb(var(--color_32));
    }

    .color_33 {
      color: rgb(var(--color_33));
    }

    .color_34 {
      color: rgb(var(--color_34));
    }

    .color_35 {
      color: rgb(var(--color_35));
    }

    .color_36 {
      color: rgb(var(--color_36));
    }

    .color_37 {
      color: rgb(var(--color_37));
    }

    .color_38 {
      color: rgb(var(--color_38));
    }

    .color_39 {
      color: rgb(var(--color_39));
    }

    .color_40 {
      color: rgb(var(--color_40));
    }

    .color_41 {
      color: rgb(var(--color_41));
    }

    .color_42 {
      color: rgb(var(--color_42));
    }

    .color_43 {
      color: rgb(var(--color_43));
    }

    .color_44 {
      color: rgb(var(--color_44));
    }

    .color_45 {
      color: rgb(var(--color_45));
    }

    .color_46 {
      color: rgb(var(--color_46));
    }

    .color_47 {
      color: rgb(var(--color_47));
    }

    .color_48 {
      color: rgb(var(--color_48));
    }

    .color_49 {
      color: rgb(var(--color_49));
    }

    .color_50 {
      color: rgb(var(--color_50));
    }

    .color_51 {
      color: rgb(var(--color_51));
    }

    .color_52 {
      color: rgb(var(--color_52));
    }

    .color_53 {
      color: rgb(var(--color_53));
    }

    .color_54 {
      color: rgb(var(--color_54));
    }

    .color_55 {
      color: rgb(var(--color_55));
    }

    .color_56 {
      color: rgb(var(--color_56));
    }

    .color_57 {
      color: rgb(var(--color_57));
    }

    .color_58 {
      color: rgb(var(--color_58));
    }

    .color_59 {
      color: rgb(var(--color_59));
    }

    .color_60 {
      color: rgb(var(--color_60));
    }

    .color_61 {
      color: rgb(var(--color_61));
    }

    .color_62 {
      color: rgb(var(--color_62));
    }

    .color_63 {
      color: rgb(var(--color_63));
    }

    .color_64 {
      color: rgb(var(--color_64));
    }

    .color_65 {
      color: rgb(var(--color_65));
    }

    .backcolor_0 {
      background-color: rgb(var(--color_0));
    }

    .backcolor_1 {
      background-color: rgb(var(--color_1));
    }

    .backcolor_2 {
      background-color: rgb(var(--color_2));
    }

    .backcolor_3 {
      background-color: rgb(var(--color_3));
    }

    .backcolor_4 {
      background-color: rgb(var(--color_4));
    }

    .backcolor_5 {
      background-color: rgb(var(--color_5));
    }

    .backcolor_6 {
      background-color: rgb(var(--color_6));
    }

    .backcolor_7 {
      background-color: rgb(var(--color_7));
    }

    .backcolor_8 {
      background-color: rgb(var(--color_8));
    }

    .backcolor_9 {
      background-color: rgb(var(--color_9));
    }

    .backcolor_10 {
      background-color: rgb(var(--color_10));
    }

    .backcolor_11 {
      background-color: rgb(var(--color_11));
    }

    .backcolor_12 {
      background-color: rgb(var(--color_12));
    }

    .backcolor_13 {
      background-color: rgb(var(--color_13));
    }

    .backcolor_14 {
      background-color: rgb(var(--color_14));
    }

    .backcolor_15 {
      background-color: rgb(var(--color_15));
    }

    .backcolor_16 {
      background-color: rgb(var(--color_16));
    }

    .backcolor_17 {
      background-color: rgb(var(--color_17));
    }

    .backcolor_18 {
      background-color: rgb(var(--color_18));
    }

    .backcolor_19 {
      background-color: rgb(var(--color_19));
    }

    .backcolor_20 {
      background-color: rgb(var(--color_20));
    }

    .backcolor_21 {
      background-color: rgb(var(--color_21));
    }

    .backcolor_22 {
      background-color: rgb(var(--color_22));
    }

    .backcolor_23 {
      background-color: rgb(var(--color_23));
    }

    .backcolor_24 {
      background-color: rgb(var(--color_24));
    }

    .backcolor_25 {
      background-color: rgb(var(--color_25));
    }

    .backcolor_26 {
      background-color: rgb(var(--color_26));
    }

    .backcolor_27 {
      background-color: rgb(var(--color_27));
    }

    .backcolor_28 {
      background-color: rgb(var(--color_28));
    }

    .backcolor_29 {
      background-color: rgb(var(--color_29));
    }

    .backcolor_30 {
      background-color: rgb(var(--color_30));
    }

    .backcolor_31 {
      background-color: rgb(var(--color_31));
    }

    .backcolor_32 {
      background-color: rgb(var(--color_32));
    }

    .backcolor_33 {
      background-color: rgb(var(--color_33));
    }

    .backcolor_34 {
      background-color: rgb(var(--color_34));
    }

    .backcolor_35 {
      background-color: rgb(var(--color_35));
    }

    .backcolor_36 {
      background-color: rgb(var(--color_36));
    }

    .backcolor_37 {
      background-color: rgb(var(--color_37));
    }

    .backcolor_38 {
      background-color: rgb(var(--color_38));
    }

    .backcolor_39 {
      background-color: rgb(var(--color_39));
    }

    .backcolor_40 {
      background-color: rgb(var(--color_40));
    }

    .backcolor_41 {
      background-color: rgb(var(--color_41));
    }

    .backcolor_42 {
      background-color: rgb(var(--color_42));
    }

    .backcolor_43 {
      background-color: rgb(var(--color_43));
    }

    .backcolor_44 {
      background-color: rgb(var(--color_44));
    }

    .backcolor_45 {
      background-color: rgb(var(--color_45));
    }

    .backcolor_46 {
      background-color: rgb(var(--color_46));
    }

    .backcolor_47 {
      background-color: rgb(var(--color_47));
    }

    .backcolor_48 {
      background-color: rgb(var(--color_48));
    }

    .backcolor_49 {
      background-color: rgb(var(--color_49));
    }

    .backcolor_50 {
      background-color: rgb(var(--color_50));
    }

    .backcolor_51 {
      background-color: rgb(var(--color_51));
    }

    .backcolor_52 {
      background-color: rgb(var(--color_52));
    }

    .backcolor_53 {
      background-color: rgb(var(--color_53));
    }

    .backcolor_54 {
      background-color: rgb(var(--color_54));
    }

    .backcolor_55 {
      background-color: rgb(var(--color_55));
    }

    .backcolor_56 {
      background-color: rgb(var(--color_56));
    }

    .backcolor_57 {
      background-color: rgb(var(--color_57));
    }

    .backcolor_58 {
      background-color: rgb(var(--color_58));
    }

    .backcolor_59 {
      background-color: rgb(var(--color_59));
    }

    .backcolor_60 {
      background-color: rgb(var(--color_60));
    }

    .backcolor_61 {
      background-color: rgb(var(--color_61));
    }

    .backcolor_62 {
      background-color: rgb(var(--color_62));
    }

    .backcolor_63 {
      background-color: rgb(var(--color_63));
    }

    .backcolor_64 {
      background-color: rgb(var(--color_64));
    }

    .backcolor_65 {
      background-color: rgb(var(--color_65));
    }

    #masterPage:not(.landingPage) {
      --top-offset: var(--header-height);
      --header-height: 79px;
    }

    #masterPage.landingPage {
      --top-offset: 0px;
    }

    #SITE_HEADER {
      --bg: var(--color_11);
      --shd: none;
      --brwt: 0px;
      --brd: var(--color_43);
      --brwb: 0px;
      --bgctr: var(--color_43);
      --rd: 0px;
      --alpha-bg: 1;
      --alpha-bgctr: 1;
      --alpha-brd: 1;
      --boxShadowToggleOn-shd: none;
      --backdrop-filter: none;
    }

    #SITE_HEADER {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    [data-mesh-id=SITE_HEADERinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=SITE_HEADERinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=SITE_HEADERinlineContent-gridContainer]>[id="comp-lq9dqai3"] {
      position: relative;
      margin: 19px 0px 20px calc((100% - 980px) * 0.5);
      left: 946px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    #SITE_HEADER {
      --bg-overlay-color: rgb(var(--color_43));
      --bg-gradient: none;
      --shc-mutated-brightness: 3, 11, 41;
      position: sticky !important;
      top: var(--wix-ads-height);
    }

    #comp-lq9dqai3 {
      width: 34px;
      height: 40px;
    }

    .comp-lq9dqai3 {
      --wix-direction: ltr;
      --textFont: normal normal normal 20px/1.4em raleway, sans-serif;
      --backgroundColor: 160, 160, 159;
      --backgroundColor-rgb: 160, 160, 159;
      --backgroundColor-opacity: 1;
      --textColor: 47, 46, 46;
      --textColor-rgb: 47, 46, 46;
      --textColor-opacity: 1;
      --textFont-style: normal;
      --textFont-variant: normal;
      --textFont-weight: normal;
      --textFont-size: 20px;
      --textFont-line-height: 1.4em;
      --textFont-family: raleway, sans-serif;
      --textFont-text-decoration: none;
      --wix-color-1: 6, 21, 81;
      --wix-color-2: 240, 240, 240;
      --wix-color-3: 160, 160, 159;
      --wix-color-4: 47, 46, 46;
      --wix-color-5: 47, 46, 46;
      --wix-color-6: 228, 217, 255;
      --wix-color-7: 173, 139, 247;
      --wix-color-8: 133, 77, 255;
      --wix-color-9: 89, 51, 170;
      --wix-color-10: 44, 26, 85;
      --wix-color-11: 242, 247, 255;
      --wix-color-12: 158, 193, 247;
      --wix-color-13: 46, 88, 255;
      --wix-color-14: 24, 51, 158;
      --wix-color-15: 6, 21, 81;
      --wix-color-16: 235, 254, 255;
      --wix-color-17: 142, 245, 250;
      --wix-color-18: 12, 221, 232;
      --wix-color-19: 18, 175, 184;
      --wix-color-20: 14, 88, 92;
      --wix-color-21: 232, 255, 238;
      --wix-color-22: 155, 250, 180;
      --wix-color-23: 71, 255, 120;
      --wix-color-24: 55, 204, 95;
      --wix-color-25: 34, 125, 58;
      --wix-color-26: 255, 255, 255;
      --wix-color-27: 47, 46, 46;
      --wix-color-28: 240, 240, 240;
      --wix-color-29: 160, 160, 159;
      --wix-color-30: 96, 94, 94;
      --wix-color-31: 133, 77, 255;
      --wix-color-32: 173, 139, 247;
      --wix-color-33: 6, 21, 81;
      --wix-color-34: 235, 254, 255;
      --wix-color-35: 47, 46, 46;
      --wix-color-36: 47, 46, 46;
      --wix-color-37: 96, 94, 94;
      --wix-color-38: 133, 77, 255;
      --wix-color-39: 133, 77, 255;
      --wix-color-40: 255, 255, 255;
      --wix-color-41: 255, 255, 255;
      --wix-color-42: 133, 77, 255;
      --wix-color-43: 133, 77, 255;
      --wix-color-44: 160, 160, 159;
      --wix-color-45: 160, 160, 159;
      --wix-color-46: 255, 255, 255;
      --wix-color-47: 255, 255, 255;
      --wix-color-48: 133, 77, 255;
      --wix-color-49: 133, 77, 255;
      --wix-color-50: 133, 77, 255;
      --wix-color-51: 133, 77, 255;
      --wix-color-52: 255, 255, 255;
      --wix-color-53: 255, 255, 255;
      --wix-color-54: 160, 160, 159;
      --wix-color-55: 160, 160, 159;
      --wix-font-Title: normal normal bold 28px/1.4em raleway, sans-serif;
      --wix-font-Title-style: normal;
      --wix-font-Title-variant: normal;
      --wix-font-Title-weight: bold;
      --wix-font-Title-size: 28px;
      --wix-font-Title-line-height: 1.4em;
      --wix-font-Title-family: raleway, sans-serif;
      --wix-font-Title-text-decoration: none;
      --wix-font-Menu: normal normal normal 16px/1.4em din-next-w01-light, sans-serif;
      --wix-font-Menu-style: normal;
      --wix-font-Menu-variant: normal;
      --wix-font-Menu-weight: normal;
      --wix-font-Menu-size: 16px;
      --wix-font-Menu-line-height: 1.4em;
      --wix-font-Menu-family: din-next-w01-light, sans-serif;
      --wix-font-Menu-text-decoration: none;
      --wix-font-Page-title: normal normal bold 55px/1.4em raleway, sans-serif;
      --wix-font-Page-title-style: normal;
      --wix-font-Page-title-variant: normal;
      --wix-font-Page-title-weight: bold;
      --wix-font-Page-title-size: 55px;
      --wix-font-Page-title-line-height: 1.4em;
      --wix-font-Page-title-family: raleway, sans-serif;
      --wix-font-Page-title-text-decoration: none;
      --wix-font-Heading-XL: normal normal bold 55px/1.4em raleway, sans-serif;
      --wix-font-Heading-XL-style: normal;
      --wix-font-Heading-XL-variant: normal;
      --wix-font-Heading-XL-weight: bold;
      --wix-font-Heading-XL-size: 55px;
      --wix-font-Heading-XL-line-height: 1.4em;
      --wix-font-Heading-XL-family: raleway, sans-serif;
      --wix-font-Heading-XL-text-decoration: none;
      --wix-font-Heading-L: normal normal bold 40px/1.4em raleway, sans-serif;
      --wix-font-Heading-L-style: normal;
      --wix-font-Heading-L-variant: normal;
      --wix-font-Heading-L-weight: bold;
      --wix-font-Heading-L-size: 40px;
      --wix-font-Heading-L-line-height: 1.4em;
      --wix-font-Heading-L-family: raleway, sans-serif;
      --wix-font-Heading-L-text-decoration: none;
      --wix-font-Heading-M: normal normal bold 20px/1.4em raleway, sans-serif;
      --wix-font-Heading-M-style: normal;
      --wix-font-Heading-M-variant: normal;
      --wix-font-Heading-M-weight: bold;
      --wix-font-Heading-M-size: 20px;
      --wix-font-Heading-M-line-height: 1.4em;
      --wix-font-Heading-M-family: raleway, sans-serif;
      --wix-font-Heading-M-text-decoration: none;
      --wix-font-Heading-S: normal normal normal 18px/1.4em raleway, sans-serif;
      --wix-font-Heading-S-style: normal;
      --wix-font-Heading-S-variant: normal;
      --wix-font-Heading-S-weight: normal;
      --wix-font-Heading-S-size: 18px;
      --wix-font-Heading-S-line-height: 1.4em;
      --wix-font-Heading-S-family: raleway, sans-serif;
      --wix-font-Heading-S-text-decoration: none;
      --wix-font-Body-L: normal normal normal 18px/1.4em raleway, sans-serif;
      --wix-font-Body-L-style: normal;
      --wix-font-Body-L-variant: normal;
      --wix-font-Body-L-weight: normal;
      --wix-font-Body-L-size: 18px;
      --wix-font-Body-L-line-height: 1.4em;
      --wix-font-Body-L-family: raleway, sans-serif;
      --wix-font-Body-L-text-decoration: none;
      --wix-font-Body-M: normal normal normal 16px/1.4em raleway, sans-serif;
      --wix-font-Body-M-style: normal;
      --wix-font-Body-M-variant: normal;
      --wix-font-Body-M-weight: normal;
      --wix-font-Body-M-size: 16px;
      --wix-font-Body-M-line-height: 1.4em;
      --wix-font-Body-M-family: raleway, sans-serif;
      --wix-font-Body-M-text-decoration: none;
      --wix-font-Body-S: normal normal normal 14px/1.4em raleway, sans-serif;
      --wix-font-Body-S-style: normal;
      --wix-font-Body-S-variant: normal;
      --wix-font-Body-S-weight: normal;
      --wix-font-Body-S-size: 14px;
      --wix-font-Body-S-line-height: 1.4em;
      --wix-font-Body-S-family: raleway, sans-serif;
      --wix-font-Body-S-text-decoration: none;
      --wix-font-Body-XS: normal normal normal 12px/1.4em din-next-w01-light, sans-serif;
      --wix-font-Body-XS-style: normal;
      --wix-font-Body-XS-variant: normal;
      --wix-font-Body-XS-weight: normal;
      --wix-font-Body-XS-size: 12px;
      --wix-font-Body-XS-line-height: 1.4em;
      --wix-font-Body-XS-family: din-next-w01-light, sans-serif;
      --wix-font-Body-XS-text-decoration: none;
      --wix-font-LIGHT: normal normal normal 12px/1.4em HelveticaNeueW01-45Ligh;
      --wix-font-LIGHT-style: normal;
      --wix-font-LIGHT-variant: normal;
      --wix-font-LIGHT-weight: normal;
      --wix-font-LIGHT-size: 12px;
      --wix-font-LIGHT-line-height: 1.4em;
      --wix-font-LIGHT-family: HelveticaNeueW01-45Ligh;
      --wix-font-LIGHT-text-decoration: none;
      --wix-font-MEDIUM: normal normal normal 12px/1.4em HelveticaNeueW01-55Roma;
      --wix-font-MEDIUM-style: normal;
      --wix-font-MEDIUM-variant: normal;
      --wix-font-MEDIUM-weight: normal;
      --wix-font-MEDIUM-size: 12px;
      --wix-font-MEDIUM-line-height: 1.4em;
      --wix-font-MEDIUM-family: HelveticaNeueW01-55Roma;
      --wix-font-MEDIUM-text-decoration: none;
      --wix-font-STRONG: normal normal normal 12px/1.4em HelveticaNeueW01-65Medi;
      --wix-font-STRONG-style: normal;
      --wix-font-STRONG-variant: normal;
      --wix-font-STRONG-weight: normal;
      --wix-font-STRONG-size: 12px;
      --wix-font-STRONG-line-height: 1.4em;
      --wix-font-STRONG-family: HelveticaNeueW01-65Medi;
      --wix-font-STRONG-text-decoration: none;
    }



    .comp-lq9dqai3 .ETC5zb {
      --cartWidget_cartIcon: #854DFF;
      --cartWidget_cartIconText: #854DFF;
      --cartWidget_cartIconTextFont: normal normal normal 16px/1.4em raleway, sans-serif;
      --cartWidget_cartIconNumberFont: normal normal normal 16px/1.4em raleway, sans-serif;
      --cartWidget_cartIconBubble: #854DFF
    }

    .comp-lq9dqai3 .s1dvzA rect,
    .comp-lq9dqai3 .s1dvzA polygon,
    .comp-lq9dqai3 .s1dvzA polyline,
    .comp-lq9dqai3 .s1dvzA circle,
    .comp-lq9dqai3 .s1dvzA path {
      fill: #854DFF
    }

    .comp-lq9dqai3 .s1dvzA text {
      fill: #854DFF;
      font: normal normal normal 16px/1.4em raleway, sans-serif;
      font-size: 90px
    }

    .comp-lq9dqai3 .s1dvzA .uxskpx {
      fill: #061551;
      font: normal normal normal 16px/1.4em raleway, sans-serif;
      font-size: 90px
    }

    .comp-lq9dqai3 .s1dvzA .uxskpx.M846Y_ {
      fill: #854DFF
    }

    .comp-lq9dqai3 .s1dvzA .ptVJi9 {
      fill: #854DFF
    }

    .comp-lq9dqai3 .C2k87E rect,
    .comp-lq9dqai3 .C2k87E polygon,
    .comp-lq9dqai3 .C2k87E polyline,
    .comp-lq9dqai3 .C2k87E circle,
    .comp-lq9dqai3 .C2k87E path {
      fill: #854DFF
    }

    .comp-lq9dqai3 .C2k87E text {
      fill: #854DFF;
      font: normal normal normal 16px/1.4em raleway, sans-serif;
      font-size: 90px
    }

    .comp-lq9dqai3 .C2k87E .uxskpx {
      fill: #061551;
      font: normal normal normal 16px/1.4em raleway, sans-serif;
      font-size: 90px
    }

    .comp-lq9dqai3 .C2k87E .uxskpx.M846Y_ {
      fill: #854DFF
    }

    .comp-lq9dqai3 .C2k87E .ptVJi9 {
      fill: #854DFF
    }

    .comp-lq9dqai3 ._EREUE rect,
    .comp-lq9dqai3 ._EREUE polygon,
    .comp-lq9dqai3 ._EREUE polyline,
    .comp-lq9dqai3 ._EREUE circle,
    .comp-lq9dqai3 ._EREUE path {
      fill: #854DFF
    }

    .comp-lq9dqai3 ._EREUE text {
      fill: #854DFF;
      font: normal normal normal 16px/1.4em raleway, sans-serif;
      font-size: 90px
    }

    .comp-lq9dqai3 ._EREUE .uxskpx {
      fill: #061551;
      font: normal normal normal 16px/1.4em raleway, sans-serif;
      font-size: 90px
    }

    .comp-lq9dqai3 ._EREUE .uxskpx.M846Y_ {
      fill: #854DFF
    }

    .comp-lq9dqai3 ._EREUE .ptVJi9 {
      fill: #854DFF
    }

    #SITE_FOOTER {
      --bg: var(--color_11);
      --shd: none;
      --brwt: 0px;
      --brd: var(--color_43);
      --brwb: 0px;
      --bgctr: var(--color_43);
      --rd: 0px;
      --alpha-bg: 1;
      --alpha-bgctr: 1;
      --alpha-brd: 0;
      --boxShadowToggleOn-shd: none;
      --backdrop-filter: none;
    }

    #SITE_FOOTER {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    [data-mesh-id=SITE_FOOTERinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=SITE_FOOTERinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: min-content 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=SITE_FOOTERinlineContent-gridContainer]>[id="comp-iruti9mw"] {
      position: relative;
      margin: 25px 0px 14px calc((100% - 980px) * 0.5);
      left: 422px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=SITE_FOOTERinlineContent-gridContainer]>[id="comp-irqduxcc"] {
      position: relative;
      margin: 0px 0px 24px calc((100% - 980px) * 0.5);
      left: 152px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    #SITE_FOOTER {
      --shc-mutated-brightness: 3, 11, 41;
      --bg-overlay-color: rgb(var(--color_43));
      --bg-gradient: none;
    }

    #comp-iruti9mw {
      width: 132px;
      height: 24px;
    }

    #comp-iruti9mw {
      --item-size: 24px;
      --item-margin: 0px 12px 0px 0px;
      --item-display: inline-block;
      width: 132px;
      height: 24px;
    }

    #comp-irqduxcc {
      width: 671px;
      height: auto;
    }

    #PAGES_CONTAINER {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    #SITE_PAGES {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    #SITE_PAGES {
      --transition-duration: 700ms;
    }

    #comp-ita18ph6 {
      --bg: 242, 247, 255;
      --alpha-bg: 1;
    }

    #comp-ita18ph6 {
      width: 236px;
    }

    [data-mesh-id=comp-ita18ph6inlineContent] {
      height: auto;
      width: 236px;
    }

    [data-mesh-id=comp-ita18ph6inlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-ita18ph6inlineContent-gridContainer]>[id="comp-ir9mnyu3"] {
      position: relative;
      margin: 21px 0px 27px 0;
      left: 58px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-ita18ph6inlineContent-gridContainer]>[id="comp-isejnyac"] {
      position: relative;
      margin: 31px 0px 33px 0;
      left: 158px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-ita18ph6 {
      --shc-mutated-brightness: 121, 124, 128;
    }

    #comp-ir9mnyu3 {
      width: 158px;
      height: auto;
    }

    #comp-isejnyac {
      width: 18px;
      height: 17px;
    }

    #comp-isejnyac {
      --fill-opacity: 1;
      --stroke-width: 1;
      --stroke: #854DFF;
      --stroke-opacity: 1;
      --fill: #854DFF;
      --svg-calculated-padding: 0px 1px 1px 0px;
      --svg-calculated-width: calc(100% - 1px);
      --svg-calculated-height: calc(100% - 1px);
    }

    #comp-ir9le55m {
      --menuTotalBordersX: 0px;
      --menuTotalBordersY: 0px;
      --bgDrop: var(--color_25);
      --rd: 0px;
      --shd: none;
      --fnt: normal normal 700 14px/1.4em raleway, sans-serif;
      --pad: 5px;
      --txt: var(--color_11);
      --trans: color 0.4s ease 0s;
      --txth: var(--color_18);
      --txts: var(--color_18);
      --boxShadowToggleOn-shd: none;
      --alpha-txt: 1;
      --alpha-bgDrop: 1;
      --alpha-txth: 1;
      --alpha-txts: 1;
    }

    #comp-ir9le55m {
      width: 723px;
      height: 34px;
    }

    #comp-ir9le55m {
      --menuTotalBordersY: 0px;
      --menuTotalBordersX: 0px;
    }

    #comp-jqqt3y1u {
      width: 230px;
      height: 66px;
    }
  </style>
  <style id="css_gkbn7">
    #gkbn7 {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    #comp-irte5gp9-pinned-layer {
      z-index: calc(var(--pinned-layer-in-container, 0) + 0);
    }

    #comp-irsxmitt-pinned-layer {
      z-index: calc(var(--pinned-layer-in-container, 0) + 1);
    }

    #masterPage {
      --pinned-layers-in-page: 2;
    }

    [data-mesh-id=Containergkbn7inlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=Containergkbn7inlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 40px;
      grid-template-rows: repeat(5, min-content) 1fr;
      grid-template-columns: 100%;
      padding-bottom: 0px;
      box-sizing: border-box;
    }

    [data-mesh-id=Containergkbn7inlineContent-gridContainer]>[id="comp-lq9dpgvk"] {
      position: relative;
      margin: 0px 0px 0 calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=Containergkbn7inlineContent-gridContainer]>[id="comp-lq9dpgvl"] {
      position: relative;
      margin: 0px 0px 0 calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=Containergkbn7inlineContent-gridContainer]>[id="comp-lq9dpgvm"] {
      position: relative;
      margin: 0px 0px 0 calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 3 / 1 / 4 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=Containergkbn7inlineContent-gridContainer]>[id="comp-lq9dpgvn"] {
      position: relative;
      margin: 0px 0px 0 calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 4 / 1 / 5 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=Containergkbn7inlineContent-gridContainer]>[id="comp-lq9dpgvo"] {
      position: relative;
      margin: 0px 0px 0 calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 5 / 1 / 6 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=Containergkbn7inlineContent-gridContainer]>[id="comp-lq9dpgvp"] {
      position: relative;
      margin: 0px 0px 0 calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 6 / 1 / 7 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-irte5gp9 {
      justify-self: end;
      margin-right: 40px;
      align-self: center;
      margin-bottom: 56px;
      position: absolute;
      grid-area: 1 / 1 / 2 / 2;
      pointer-events: auto;
    }

    #comp-irsxmitt {
      justify-self: end;
      margin-right: 24px;
      align-self: end;
      margin-bottom: 24px;
      position: absolute;
      grid-area: 1 / 1 / 2 / 2;
      pointer-events: auto;
    }

    #comp-lq9dpgvk {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    [data-mesh-id=comp-lq9dpgvkinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-lq9dpgvkinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: min-content 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-lq9dpgvkinlineContent-gridContainer]>[id="comp-irqduxer"] {
      position: relative;
      margin: 0px 0px 0 calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-lq9dpgvkinlineContent-gridContainer]>[id="comp-irqdux7i"] {
      position: relative;
      margin: 0px 0px 0px calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-irqduxer {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 980px;
    }

    [data-mesh-id=comp-irqduxf8inlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-irqduxf8inlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: repeat(8, min-content) 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-irqduxf8inlineContent-gridContainer]>[id="comp-isehzfde2"] {
      position: relative;
      margin: 88px 0px 16px calc((100% - 980px) * 0.5);
      left: 792px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxf8inlineContent-gridContainer]>[id="comp-irqduxfv"] {
      position: relative;
      margin: 0px 0px 28px calc((100% - 980px) * 0.5);
      left: 168px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxf8inlineContent-gridContainer]>[id="comp-isehzfdf"] {
      position: relative;
      margin: 0px 0px -11px calc((100% - 980px) * 0.5);
      left: 25px;
      grid-area: 4 / 1 / 5 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxf8inlineContent-gridContainer]>[id="comp-irqduxg0"] {
      position: relative;
      margin: 0px 0px -6px calc((100% - 980px) * 0.5);
      left: 171px;
      grid-area: 3 / 1 / 4 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxf8inlineContent-gridContainer]>[id="comp-irqduxg4"] {
      position: relative;
      margin: 0px 0px -9px calc((100% - 980px) * 0.5);
      left: 384px;
      grid-area: 5 / 1 / 6 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxf8inlineContent-gridContainer]>[id="comp-isei16an"] {
      position: relative;
      margin: 0px 0px 10px calc((100% - 980px) * 0.5);
      left: 912px;
      grid-area: 6 / 1 / 7 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxf8inlineContent-gridContainer]>[id="comp-isehzfdf1"] {
      position: relative;
      margin: 0px 0px 2px calc((100% - 980px) * 0.5);
      left: -139px;
      grid-area: 8 / 1 / 9 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxf8inlineContent-gridContainer]>[id="comp-isejgyj5"] {
      position: relative;
      margin: 0px 0px 51px calc((100% - 980px) * 0.5);
      left: -94px;
      grid-area: 9 / 1 / 10 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxf8inlineContent-wedge-8] {
      visibility: hidden;
      height: 744px;
      width: 0;
      grid-area: 1 / 1 / 8 / 2;
    }

    #comp-irqduxf8 {
      width: 980px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isehzfde2:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-isehzfde2 {
      width: 28px;
      height: 27px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxfv:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irqduxfv {
      width: 659px;
      height: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isehzfdf:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-isehzfdf {
      width: 31px;
      height: 44px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxg0:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irqduxg0 {
      width: 637px;
      height: auto;
    }

    #comp-irqduxg4 {
      --rd: 50px;
      --trans1: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
      --shd: none;
      --fnt: normal normal 700 16px/1.4em raleway, sans-serif;
      --trans2: color 0.4s ease 0s;
      --txt: var(--color_50);
      --brw: 1px;
      --bg: var(--color_48);
      --brd: 255, 255, 255;
      --bgh: var(--color_51);
      --brdh: 182, 232, 227;
      --txth: var(--color_53);
      --bgd: 204, 204, 204;
      --alpha-bgd: 1;
      --brdd: 204, 204, 204;
      --alpha-brdd: 1;
      --txtd: 255, 255, 255;
      --alpha-txtd: 1;
      --boxShadowToggleOn-shd: none;
      --alpha-txt: 1;
      --alpha-brdh: 1;
      --alpha-brd: 0;
      --alpha-txth: 1;
      --alpha-bg: 1;
      --alpha-bgh: 1;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxg4:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irqduxg4 {
      width: 202px;
      height: 49px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isei16an:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-isei16an {
      width: 36px;
      height: 31px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isehzfdf1:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-isehzfdf1 {
      width: 46px;
      height: 55px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isejgyj5:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-isejgyj5 {
      width: 24px;
      height: 22px;
    }

    #comp-irqdux7i {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 980px;
    }

    [data-mesh-id=comp-irqdux85inlineContent] {
      height: auto;
      width: 100%;
      display: flex;
    }

    [data-mesh-id=comp-irqdux85inlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 645px;
      margin-top: -427px;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-irqdux85inlineContent-gridContainer]>[id="comp-irqduxgc"] {
      position: relative;
      margin: 0px 0px 10px calc((100% - 980px) * 0.5);
      left: 37px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-irqdux85 {
      width: 980px;
    }

    #comp-irqduxgc {
      --shd: 0 0 0 rgba(0, 0, 0, 0.6);
      --dotsColor: 255, 255, 255;
      --arrowColor: var(--color_12);
      --rd: 0px;
      --brw: 0px;
      --brd: 255, 255, 255;
      --alpha-arrowColor: 1;
      --alpha-brd: 1;
      --alpha-dotsColor: 1;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxgc:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irqduxgc {
      width: 905px;
      height: 559px;
    }

    #comp-irt9hbxb {
      width: 905px;
    }

    [data-mesh-id=comp-irt9hbxbinlineContent] {
      height: auto;
      width: 100%;
      position: static;
      min-height: 559px;
    }

    #comp-irut3nj4 {
      width: 907px;
    }

    [data-mesh-id=comp-irut3nj4inlineContent] {
      height: auto;
      width: 100%;
      position: static;
      min-height: 559px;
    }

    #comp-irte5gp9 {
      --symbolColor: var(--color_21);
      --symbolColorSelected: var(--color_21);
      --symbolAndTextColor: var(--color_21);
      --alpha-symbolAndTextColor: 1;
      --selectedColor: var(--color_21);
      --alpha-selectedColor: 1;
      --alpha-symbolColor: 1;
      --alpha-symbolColorSelected: 1;
    }

    #comp-irte5gp9 {
      width: 12px;
      height: 112px;
    }

    #comp-irsxmitt {
      --brw: 0px;
      --brd: 50, 65, 88;
      --bg: 133, 77, 255;
      --shd: none;
      --alpha-bg: 1;
      --alpha-brd: 1;
      --boxShadowToggleOn-shd: none;
    }

    #comp-irsxmitt {
      width: 45px;
    }

    [data-mesh-id=comp-irsxmittinlineContent] {
      height: auto;
      width: 45px;
    }

    [data-mesh-id=comp-irsxmittinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id="comp-irsxlh58-rotated-wrapper"]>[id="comp-irsxlh58"] {
      position: relative;
      left: 9px;
      top: 1px;
    }

    [data-mesh-id=comp-irsxlh58-rotated-wrapper] {
      position: static;
      height: 26px;
      width: 0;
      margin: 6px 0px 13px 0;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-irsxlh58 {
      width: 25px;
      height: 25px;
      transform: rotate(180deg);
      --comp-rotate-z: 180deg;
    }

    #comp-lq9dpgvl {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    [data-mesh-id=comp-lq9dpgvlinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-lq9dpgvlinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-lq9dpgvlinlineContent-gridContainer]>[id="comp-irqduxcp"] {
      position: relative;
      margin: 0px 0px 0px calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-lq9dpgvlinlineContent-gridContainer]>[id="comp-irte5pmq"] {
      position: relative;
      margin: 0px 0 10px 0;
      left: 0;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: stretch;
      align-self: start;
    }

    #comp-irqduxcp {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 980px;
    }

    [data-mesh-id=comp-irqduxcuinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-irqduxcuinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: repeat(2, min-content) 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-irqduxcuinlineContent-gridContainer]>[id="comp-isejheta"] {
      position: relative;
      margin: 74px 0px 21px calc((100% - 325px) * 0.5);
      left: 138px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxcuinlineContent-gridContainer]>[id="comp-irqduxd4"] {
      position: relative;
      margin: 0px 0px 19px calc((100% - 325px) * 0.5);
      left: 67px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxcuinlineContent-gridContainer]>[id="comp-irqduxcy"] {
      position: relative;
      margin: 0px 0px 12px calc((100% - 325px) * 0.5);
      left: 18px;
      grid-area: 3 / 1 / 4 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxdjinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-irqduxdjinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 487px;
      grid-template-rows: repeat(2, min-content) 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-irqduxdjinlineContent-gridContainer]>[id="comp-isei1r55"] {
      position: relative;
      margin: 65px 0px 16px calc((100% - 324px) * 0.5);
      left: 137px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxdjinlineContent-gridContainer]>[id="comp-irqduxds"] {
      position: relative;
      margin: 0px 0px 19px calc((100% - 324px) * 0.5);
      left: 62px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxdjinlineContent-gridContainer]>[id="comp-irqduxdn"] {
      position: relative;
      margin: 0px 0px 10px calc((100% - 324px) * 0.5);
      left: 16px;
      grid-area: 3 / 1 / 4 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxdzinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-irqduxdzinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 487px;
      grid-template-rows: repeat(2, min-content) 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-irqduxdzinlineContent-gridContainer]>[id="comp-isejuitl"] {
      position: relative;
      margin: 65px 0px 16px calc((100% - 331px) * 0.5);
      left: 141px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxdzinlineContent-gridContainer]>[id="comp-irqduxeb"] {
      position: relative;
      margin: 0px 0px 19px calc((100% - 331px) * 0.5);
      left: 74px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxdzinlineContent-gridContainer]>[id="comp-irqduxe2"] {
      position: relative;
      margin: 0px 0px 10px calc((100% - 331px) * 0.5);
      left: 21px;
      grid-area: 3 / 1 / 4 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-irqduxcu {
      width: 325px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isejheta:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-isejheta {
      width: 44px;
      height: 42px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxd4:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irqduxd4 {
      width: 193px;
      height: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxcy:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irqduxcy {
      width: 288px;
      height: auto;
    }

    #comp-irqduxdj {
      width: 324px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isei1r55:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-isei1r55 {
      width: 48px;
      height: 56px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxds:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irqduxds {
      width: 200px;
      height: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxdn:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irqduxdn {
      width: 288px;
      height: auto;
    }

    #comp-irqduxdz {
      width: 331px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isejuitl:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-isejuitl {
      width: 50px;
      height: 55px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxeb:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irqduxeb {
      width: 185px;
      height: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxe2:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irqduxe2 {
      width: 288px;
      height: auto;
    }

    #comp-irte5pmq {
      width: 100%;
      left: 0;
      margin-left: 0;
      min-width: initial;
      height: 90px;
    }

    #comp-lq9dpgvm {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    [data-mesh-id=comp-lq9dpgvminlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-lq9dpgvminlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-lq9dpgvminlineContent-gridContainer]>[id="comp-irqduxas"] {
      position: relative;
      margin: 0px 0px 0px calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-lq9dpgvminlineContent-gridContainer]>[id="comp-irte6ovt"] {
      position: relative;
      margin: 0px 0 10px 0;
      left: 0;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: stretch;
      align-self: start;
    }

    #comp-irqduxas {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 980px;
    }

    [data-mesh-id=comp-irqduxawinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 1218px;
      grid-template-rows: repeat(13, min-content) 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-irtb3bz7"] {
      position: relative;
      margin: 117px 0px 35px calc((100% - 980px) * 0.5);
      left: 115px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-irsxtu2g"] {
      position: relative;
      margin: 0px 0px 26px calc((100% - 980px) * 0.5);
      left: 35px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-irsxtr31"] {
      position: relative;
      margin: 0px 0px 6px calc((100% - 980px) * 0.5);
      left: 20px;
      grid-area: 3 / 1 / 4 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-irsxsok1"] {
      position: relative;
      margin: 13px 0px 10px calc((100% - 980px) * 0.5);
      left: 42px;
      grid-area: 4 / 1 / 5 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-iruwiqnd"] {
      position: relative;
      margin: 10px 0px -60px calc((100% - 980px) * 0.5);
      left: 115px;
      grid-area: 3 / 1 / 6 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-iruwjbi6"] {
      position: relative;
      margin: 0px 0px 69px calc((100% - 980px) * 0.5);
      left: 267px;
      grid-area: 7 / 1 / 10 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-isejjlss"] {
      position: relative;
      margin: 1px 0px 18px calc((100% - 980px) * 0.5);
      left: 372px;
      grid-area: 6 / 1 / 7 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-isehzfd9"] {
      position: relative;
      margin: 0px 0px 10px calc((100% - 980px) * 0.5);
      left: 197px;
      grid-area: 8 / 1 / 9 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-irqduxb1"] {
      position: relative;
      margin: 0px 0px 14px calc((100% - 980px) * 0.5);
      left: 558px;
      grid-area: 4 / 1 / 5 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-irqduxb4"] {
      position: relative;
      margin: 0px 0px 35px calc((100% - 980px) * 0.5);
      left: 558px;
      grid-area: 6 / 1 / 8 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-iruxih60"] {
      position: relative;
      margin: 0px 0px 0 calc((100% - 980px) * 0.5);
      left: 526px;
      grid-area: 10 / 1 / 11 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-irqduxbr"] {
      position: relative;
      margin: 0px 0px 15px calc((100% - 980px) * 0.5);
      left: 150px;
      grid-area: 12 / 1 / 13 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-irsw090n"] {
      position: relative;
      margin: 0px 0px 10px calc((100% - 980px) * 0.5);
      left: 150px;
      grid-area: 13 / 1 / 14 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-isejkuti"] {
      position: relative;
      margin: 0px 0px 18px calc((100% - 980px) * 0.5);
      left: 500px;
      grid-area: 11 / 1 / 12 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxawinlineContent-gridContainer]>[id="comp-iruxgy2h"] {
      position: relative;
      margin: 10px 0px 10px calc((100% - 980px) * 0.5);
      left: 538px;
      grid-area: 11 / 1 / 15 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-irqduxaw {
      width: 980px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irtb3bz7:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irtb3bz7 {
      width: 746px;
      height: auto;
    }

    #comp-irsxtu2g {
      width: 24px;
      height: 24px;
    }

    #comp-irsxtr31 {
      width: 24px;
      height: 24px;
    }

    #comp-irsxsok1 {
      width: 24px;
      height: 24px;
    }

    #comp-iruwiqnd {
      --contentPaddingLeft: 0px;
      --contentPaddingRight: 0px;
      --contentPaddingTop: 0px;
      --contentPaddingBottom: 0px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-iruwiqnd:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-iruwiqnd {
      width: 239px;
      height: 188px;
    }

    #comp-iruwjbi6 {
      --contentPaddingLeft: 0px;
      --contentPaddingRight: 0px;
      --contentPaddingTop: 0px;
      --contentPaddingBottom: 0px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-iruwjbi6:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-iruwjbi6 {
      width: 239px;
      height: 188px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isejjlss:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-isejjlss {
      width: 29px;
      height: 26px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isehzfd9:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-isehzfd9 {
      width: 50px;
      height: 54px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxb1:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irqduxb1 {
      width: 304px;
      height: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxb4:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irqduxb4 {
      width: 290px;
      height: auto;
    }

    #comp-iruxih60 {
      --contentPaddingLeft: 0px;
      --contentPaddingRight: 0px;
      --contentPaddingTop: 0px;
      --contentPaddingBottom: 0px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-iruxih60:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-iruxih60 {
      width: 35px;
      height: 32px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxbr:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irqduxbr {
      width: 287px;
      height: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irsw090n:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irsw090n {
      width: 287px;
      height: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isejkuti:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-isejkuti {
      width: 29px;
      height: 26px;
    }

    #comp-iruxgy2h {
      --contentPaddingLeft: 0px;
      --contentPaddingRight: 0px;
      --contentPaddingTop: 0px;
      --contentPaddingBottom: 0px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-iruxgy2h:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-iruxgy2h {
      width: 318px;
      height: 338px;
    }

    #comp-irte6ovt {
      width: 100%;
      left: 0;
      margin-left: 0;
      min-width: initial;
      height: 90px;
    }

    #comp-lq9dpgvn {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    [data-mesh-id=comp-lq9dpgvninlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-lq9dpgvninlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-lq9dpgvninlineContent-gridContainer]>[id="comp-irqduxhq"] {
      position: relative;
      margin: 0px 0px 0px calc((100% - 980px) * 0.5);
      left: -215px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-lq9dpgvninlineContent-gridContainer]>[id="comp-irte7nrj"] {
      position: relative;
      margin: 0px 0 10px 0;
      left: 0;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: stretch;
      align-self: start;
    }

    #comp-irqduxhq {
      --shd: 0 0 0 rgba(0, 0, 0, 0.6);
      --dotsColor: var(--color_12);
      --arrowColor: var(--color_12);
      --rd: 0px;
      --brw: 0px;
      --brd: var(--color_11);
      --alpha-brd: 1;
      --alpha-arrowColor: 1;
      --alpha-dotsColor: 1;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irqduxhq:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-irqduxhq {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
      height: 463px;
    }

    #comp-irqduxhy {
      width: 1410px;
    }

    [data-mesh-id=comp-irqduxhyinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-irqduxhyinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: repeat(8, min-content) 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id="comp-isenegh8-rotated-wrapper"]>[id="comp-isenegh8"] {
      position: relative;
      left: 685px;
      top: -2px;
    }

    [data-mesh-id="comp-isenckdb-rotated-wrapper"]>[id="comp-isenckdb"] {
      position: relative;
      left: 27px;
      top: -2px;
    }

    [data-mesh-id="comp-isehzfde-rotated-wrapper"]>[id="comp-isehzfde"] {
      position: relative;
      left: 834px;
      top: 5px;
    }

    [data-mesh-id="comp-isenm3m4-rotated-wrapper"]>[id="comp-isenm3m4"] {
      position: relative;
      left: 102px;
      top: 12px;
    }

    [data-mesh-id=comp-irqduxhyinlineContent-gridContainer]>[id="comp-isen9lv3"] {
      position: relative;
      margin: 40px 0px -20px calc((100% - 980px) * 0.5);
      left: 130px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxhyinlineContent-gridContainer]>[id="comp-irqduxi8"] {
      position: relative;
      margin: 24px 0px 13px calc((100% - 980px) * 0.5);
      left: 295px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-isenegh8-rotated-wrapper] {
      position: static;
      height: 51px;
      width: 0;
      margin: 0px 0px 7px calc((100% - 980px) * 0.5);
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxhyinlineContent-gridContainer]>[id="comp-isenkhwl"] {
      position: relative;
      margin: 40px 0px 0 calc((100% - 980px) * 0.5);
      left: 887px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxhyinlineContent-gridContainer]>[id="comp-irqduxie"] {
      position: relative;
      margin: 0px 0px 20px calc((100% - 980px) * 0.5);
      left: 462px;
      grid-area: 3 / 1 / 4 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-isenckdb-rotated-wrapper] {
      position: static;
      height: 51px;
      width: 0;
      margin: 10px 0px -21px calc((100% - 980px) * 0.5);
      grid-area: 4 / 1 / 5 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxhyinlineContent-gridContainer]>[id="comp-irqduxi4"] {
      position: relative;
      margin: 0px 0px 1px calc((100% - 980px) * 0.5);
      left: 162px;
      grid-area: 4 / 1 / 7 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-isehzfde-rotated-wrapper] {
      position: static;
      height: 121px;
      width: 0;
      margin: 0px 0px -6px calc((100% - 980px) * 0.5);
      grid-area: 5 / 1 / 6 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-isenm3m4-rotated-wrapper] {
      position: static;
      height: 135px;
      width: 0;
      margin: 0px 0px 21px calc((100% - 980px) * 0.5);
      grid-area: 7 / 1 / 10 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxhyinlineContent-gridContainer]>[id="comp-isenexd1"] {
      position: relative;
      margin: 38px 0px -5px calc((100% - 980px) * 0.5);
      left: 362px;
      grid-area: 7 / 1 / 8 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irqduxhyinlineContent-gridContainer]>[id="comp-iseng1vk"] {
      position: relative;
      margin: 0px 0px 49px calc((100% - 980px) * 0.5);
      left: 710px;
      grid-area: 8 / 1 / 9 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-isen9lv3 {
      width: 41px;
      height: 49px;
    }

    #comp-irqduxi8 {
      width: 389px;
      height: auto;
    }

    #comp-isenegh8 {
      width: 50px;
      height: 54px;
      transform: rotate(270deg);
      --comp-rotate-z: 270deg;
    }

    #comp-isenkhwl {
      width: 28px;
      height: 29px;
    }

    #comp-irqduxie {
      --lnw: 1px;
      --brd: var(--color_36);
      --alpha-brd: 1;
    }

    #comp-irqduxie {
      width: 53px;
      height: 5px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isenckdb:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-isenckdb {
      width: 50px;
      height: 54px;
      transform: rotate(270deg);
      --comp-rotate-z: 270deg;
    }

    #comp-irqduxi4 {
      width: 655px;
      height: auto;
    }

    #comp-isehzfde {
      width: 94px;
      height: 111px;
      transform: rotate(186.16100449730425deg);
      --comp-rotate-z: 186.16100449730425deg;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-isenm3m4:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-isenm3m4 {
      width: 94px;
      height: 111px;
      transform: rotate(297.3047520121039deg);
      --comp-rotate-z: 297.3047520121039deg;
    }

    #comp-isenexd1 {
      width: 42px;
      height: 45px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-iseng1vk:not([data-motion-enter="done"]) {
        opacity: var(--comp-opacity, 1);
      }
    }

    #comp-iseng1vk {
      width: 28px;
      height: 29px;
    }

    #comp-iseo1p9u {
      width: 980px;
    }

    [data-mesh-id=comp-iseo1p9uinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-iseo1p9uinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: repeat(8, min-content) 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id="comp-iseo1pdl-rotated-wrapper"]>[id="comp-iseo1pdl"] {
      position: relative;
      left: 708px;
      top: -2px;
    }

    [data-mesh-id="comp-iseo1pcv-rotated-wrapper"]>[id="comp-iseo1pcv"] {
      position: relative;
      left: 19px;
      top: -2px;
    }

    [data-mesh-id="comp-iseo1pf6-rotated-wrapper"]>[id="comp-iseo1pf6"] {
      position: relative;
      left: 849px;
      top: 5px;
    }

    [data-mesh-id="comp-iseo1pgr-rotated-wrapper"]>[id="comp-iseo1pgr"] {
      position: relative;
      left: 104px;
      top: 12px;
    }

    [data-mesh-id=comp-iseo1p9uinlineContent-gridContainer]>[id="comp-iseo1pcd"] {
      position: relative;
      margin: 40px 0px 4px calc((100% - 980px) * 0.5);
      left: 130px;
      grid-area: 1 / 1 / 4 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iseo1p9uinlineContent-gridContainer]>[id="comp-iseo1pbb"] {
      position: relative;
      margin: 0px 0px 12px calc((100% - 980px) * 0.5);
      left: 296px;
      grid-area: 4 / 1 / 5 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iseo1pdl-rotated-wrapper] {
      position: static;
      height: 51px;
      width: 0;
      margin: 0px 0px -19px calc((100% - 980px) * 0.5);
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iseo1p9uinlineContent-gridContainer]>[id="comp-iseo1pga"] {
      position: relative;
      margin: 40px 0px -8px calc((100% - 980px) * 0.5);
      left: 888px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iseo1p9uinlineContent-gridContainer]>[id="comp-iseo1pbj"] {
      position: relative;
      margin: 0px 0px 20px calc((100% - 980px) * 0.5);
      left: 462px;
      grid-area: 5 / 1 / 6 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iseo1pcv-rotated-wrapper] {
      position: static;
      height: 51px;
      width: 0;
      margin: 22px 0px 10px calc((100% - 980px) * 0.5);
      grid-area: 6 / 1 / 7 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iseo1p9uinlineContent-gridContainer]>[id="comp-iseo1pb3"] {
      position: relative;
      margin: 0px 0px 0 calc((100% - 980px) * 0.5);
      left: 164px;
      grid-area: 6 / 1 / 7 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iseo1pf6-rotated-wrapper] {
      position: static;
      height: 121px;
      width: 0;
      margin: 21px 0px 12px calc((100% - 980px) * 0.5);
      grid-area: 6 / 1 / 7 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iseo1pgr-rotated-wrapper] {
      position: static;
      height: 135px;
      width: 0;
      margin: 0px 0px 23px calc((100% - 980px) * 0.5);
      grid-area: 7 / 1 / 10 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iseo1p9uinlineContent-gridContainer]>[id="comp-iseo1pdu"] {
      position: relative;
      margin: 40px 0px -18px calc((100% - 980px) * 0.5);
      left: 381px;
      grid-area: 7 / 1 / 8 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iseo1p9uinlineContent-gridContainer]>[id="comp-iseo1pew"] {
      position: relative;
      margin: 0px 0px 62px calc((100% - 980px) * 0.5);
      left: 830px;
      grid-area: 8 / 1 / 9 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-iseo1pcd {
      width: 41px;
      height: 49px;
    }

    #comp-iseo1pbb {
      width: 389px;
      height: auto;
    }

    #comp-iseo1pdl {
      width: 50px;
      height: 54px;
      transform: rotate(270deg);
      --comp-rotate-z: 270deg;
    }

    #comp-iseo1pga {
      width: 28px;
      height: 29px;
    }

    #comp-iseo1pbj {
      --lnw: 1px;
      --brd: var(--color_36);
      --alpha-brd: 1;
    }

    #comp-iseo1pbj {
      width: 53px;
      height: 5px;
    }

    #comp-iseo1pcv {
      width: 50px;
      height: 54px;
      transform: rotate(270deg);
      --comp-rotate-z: 270deg;
    }

    #comp-iseo1pb3 {
      width: 652px;
      height: auto;
    }

    #comp-iseo1pf6 {
      width: 94px;
      height: 111px;
      transform: rotate(186.16100449730425deg);
      --comp-rotate-z: 186.16100449730425deg;
    }

    #comp-iseo1pgr {
      width: 94px;
      height: 111px;
      transform: rotate(297.3047520121039deg);
      --comp-rotate-z: 297.3047520121039deg;
    }

    #comp-iseo1pdu {
      width: 42px;
      height: 45px;
    }

    #comp-iseo1pew {
      width: 28px;
      height: 29px;
    }

    #comp-irte7nrj {
      width: 100%;
      left: 0;
      margin-left: 0;
      min-width: initial;
      height: 90px;
    }

    #comp-lq9dpgvo {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    [data-mesh-id=comp-lq9dpgvoinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-lq9dpgvoinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-lq9dpgvoinlineContent-gridContainer]>[id="comp-irtm47ta"] {
      position: relative;
      margin: 0px 0px 0px calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-irtm47ta {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 980px;
    }

    [data-mesh-id=comp-irtm47toinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-irtm47toinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 251px;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-irtm47toinlineContent-gridContainer]>[id="comp-irtm47u3"] {
      position: relative;
      margin: 77px 0px 10px calc((100% - 196px) * 0.5);
      left: 53px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irtm4h7ginlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-irtm4h7ginlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 251px;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-irtm4h7ginlineContent-gridContainer]>[id="comp-irtm4h7s"] {
      position: relative;
      margin: 89px 0px 10px calc((100% - 196px) * 0.5);
      left: 23px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irtm47uhinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-irtm47uhinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 251px;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-irtm47uhinlineContent-gridContainer]>[id="comp-irtm47us"] {
      position: relative;
      margin: 73px 0px 10px calc((100% - 196px) * 0.5);
      left: 49px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irtm47v3inlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-irtm47v3inlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 251px;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-irtm47v3inlineContent-gridContainer]>[id="comp-irtm47vc"] {
      position: relative;
      margin: 92px 0px 10px calc((100% - 196px) * 0.5);
      left: 29px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-irtm47voinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-irtm47voinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 251px;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-irtm47voinlineContent-gridContainer]>[id="comp-irtm47w0"] {
      position: relative;
      margin: 76px 0px 10px calc((100% - 196px) * 0.5);
      left: 49px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-irtm47to {
      width: 196px;
    }

    #comp-irtm47u3 {
      --contentPaddingLeft: 0px;
      --contentPaddingRight: 0px;
      --contentPaddingTop: 0px;
      --contentPaddingBottom: 0px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irtm47u3:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irtm47u3 {
      width: 96px;
      height: 103px;
    }

    #comp-irtm4h7g {
      width: 196px;
    }

    #comp-irtm4h7s {
      --contentPaddingLeft: 0px;
      --contentPaddingRight: 0px;
      --contentPaddingTop: 0px;
      --contentPaddingBottom: 0px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irtm4h7s:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irtm4h7s {
      width: 150px;
      height: 73px;
    }

    #comp-irtm47uh {
      width: 196px;
    }

    #comp-irtm47us {
      --contentPaddingLeft: 0px;
      --contentPaddingRight: 0px;
      --contentPaddingTop: 0px;
      --contentPaddingBottom: 0px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irtm47us:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irtm47us {
      width: 102px;
      height: 102px;
    }

    #comp-irtm47v3 {
      width: 196px;
    }

    #comp-irtm47vc {
      --contentPaddingLeft: 0px;
      --contentPaddingRight: 0px;
      --contentPaddingTop: 0px;
      --contentPaddingBottom: 0px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irtm47vc:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irtm47vc {
      width: 150px;
      height: 69px;
    }

    #comp-irtm47vo {
      width: 196px;
    }

    #comp-irtm47w0 {
      --contentPaddingLeft: 0px;
      --contentPaddingRight: 0px;
      --contentPaddingTop: 0px;
      --contentPaddingBottom: 0px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irtm47w0:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irtm47w0 {
      width: 102px;
      height: 101px;
    }

    #comp-lq9dpgvp {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 0;
    }

    [data-mesh-id=comp-lq9dpgvpinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=comp-lq9dpgvpinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-lq9dpgvpinlineContent-gridContainer]>[id="comp-irteb1ql"] {
      position: relative;
      margin: 0px 0 10px 0;
      left: 0;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: stretch;
      align-self: start;
    }

    [data-mesh-id=comp-lq9dpgvpinlineContent-gridContainer]>[id="comp-irujafzq"] {
      position: relative;
      margin: 0px 0px 0px calc((100% - 980px) * 0.5);
      left: 0px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-irteb1ql {
      width: 100%;
      left: 0;
      margin-left: 0;
      min-width: initial;
      height: 90px;
    }

    #comp-irujafzq {
      left: 0;
      margin-left: 0;
      width: 100%;
      min-width: 980px;
    }

    [data-mesh-id=mediairukrt4yinlineContent] {
      height: auto;
      width: 100%;
    }

    [data-mesh-id=mediairukrt4yinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: 899px;
      grid-template-rows: repeat(4, min-content) 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id="comp-iruuj3h0-rotated-wrapper"]>[id="comp-iruuj3h0"] {
      position: relative;
      left: 344px;
      top: 14px;
    }

    [data-mesh-id="comp-iruuk73j-rotated-wrapper"]>[id="comp-iruuk73j"] {
      position: relative;
      left: 574px;
      top: 14px;
    }

    [data-mesh-id=mediairukrt4yinlineContent-gridContainer]>[id="comp-irtlor95"] {
      position: relative;
      margin: 131px 0px 23px calc((100% - 980px) * 0.5);
      left: 158px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=mediairukrt4yinlineContent-gridContainer]>[id="comp-irsvbur1"] {
      position: relative;
      margin: 131px 0px 23px calc((100% - 980px) * 0.5);
      left: 192px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=mediairukrt4yinlineContent-gridContainer]>[id="comp-irtkud8z"] {
      position: relative;
      margin: 0px 0px 17px calc((100% - 980px) * 0.5);
      left: 158px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=mediairukrt4yinlineContent-gridContainer]>[id="comp-jxad0a05"] {
      position: relative;
      margin: 0px 0px 9px calc((100% - 980px) * 0.5);
      left: 131px;
      grid-area: 4 / 1 / 5 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=mediairukrt4yinlineContent-gridContainer]>[id="comp-iruuh2gy"] {
      position: relative;
      margin: 15px 0px 10px calc((100% - 980px) * 0.5);
      left: 158px;
      grid-area: 5 / 1 / 6 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iruuj3h0-rotated-wrapper] {
      position: static;
      height: 33px;
      width: 0;
      margin: 17px 0px 10px calc((100% - 980px) * 0.5);
      grid-area: 5 / 1 / 6 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=mediairukrt4yinlineContent-gridContainer]>[id="comp-iruuhetc"] {
      position: relative;
      margin: 15px 0px 10px calc((100% - 980px) * 0.5);
      left: 392px;
      grid-area: 5 / 1 / 6 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-iruuk73j-rotated-wrapper] {
      position: static;
      height: 33px;
      width: 0;
      margin: 17px 0px 10px calc((100% - 980px) * 0.5);
      grid-area: 5 / 1 / 6 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=mediairukrt4yinlineContent-gridContainer]>[id="comp-iruuhpti"] {
      position: relative;
      margin: 0px 0px 10px calc((100% - 980px) * 0.5);
      left: 620px;
      grid-area: 5 / 1 / 6 / 2;
      justify-self: start;
      align-self: start;
    }

    #mediairukrt4y {
      width: 980px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irtlor95:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irtlor95 {
      width: 24px;
      height: 24px;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irsvbur1:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irsvbur1 {
      width: 295px;
      height: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irtkud8z:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-irtkud8z {
      width: 665px;
      height: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      #comp-irtlw0ek:not([data-motion-enter="done"]) {
        opacity: 0;
      }
    }

    #comp-jxad0a05 {
      width: 696px;
    }

    [data-mesh-id=comp-jxad0a05inlineContent] {
      height: auto;
      width: 696px;
    }

    [data-mesh-id=comp-jxad0a05inlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-jxad0a05inlineContent-gridContainer]>[id="comp-jxad0a2p"] {
      position: relative;
      margin: 0px 0px 0px 0;
      left: 0px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-jxad0a2p {
      --rd: 0px;
      --shd: 0 0 0 rgba(0, 0, 0, 0);
      --bg: 0, 0, 0;
      --alpha-bg: 0;
      --brd: 227, 227, 227;
      --alpha-brd: 1;
      --brw: 0px;
      --loader: 0;
    }

    #comp-jxad0a2p {
      width: 696px;
    }

    [data-mesh-id=comp-jxad0a2pinlineContent] {
      height: auto;
      width: 696px;
    }

    [data-mesh-id=comp-jxad0a2pinlineContent-gridContainer] {
      position: static;
      display: grid;
      height: auto;
      width: 100%;
      min-height: auto;
      grid-template-rows: repeat(3, min-content) 1fr;
      grid-template-columns: 100%;
    }

    [data-mesh-id=comp-jxad0a2pinlineContent-gridContainer]>[id="comp-jxad0a3m"] {
      position: relative;
      margin: 11px 0px 13px 0;
      left: 16px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-jxad0a2pinlineContent-gridContainer]>[id="comp-jxad0a4e"] {
      position: relative;
      margin: 11px 0px 13px 0;
      left: 354px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-jxad0a2pinlineContent-gridContainer]>[id="comp-jxad0a4w"] {
      position: relative;
      margin: 0px 0px 13px 0;
      left: 16px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-jxad0a2pinlineContent-gridContainer]>[id="comp-jxad0a5m"] {
      position: relative;
      margin: 0px 0px 7px 0;
      left: 144px;
      grid-area: 3 / 1 / 4 / 2;
      justify-self: start;
      align-self: start;
    }

    [data-mesh-id=comp-jxad0a2pinlineContent-gridContainer]>[id="comp-jxad0a66"] {
      position: relative;
      margin: 0px 0px 22px 0;
      left: 105px;
      grid-area: 4 / 1 / 5 / 2;
      justify-self: start;
      align-self: start;
    }

    #comp-jxad0a3m {
      --errorTextColor: 255, 64, 64;
      --alpha-errorTextColor: 1;
      --errorTextFont: var(--font_8);
      --shd: none;
      --rd: 0px;
      --fnt: var(--font_8);
      --brw: 0px;
      --bg: var(--color_11);
      --txt: var(--color_15);
      --alpha-txt: 1;
      --brd: var(--color_15);
      --txt2: var(--color_14);
      --alpha-txt2: 1;
      --brwh: 0px;
      --bgh: var(--color_17);
      --brdh: var(--color_15);
      --brwf: 0px;
      --bgf: var(--color_16);
      --brdf: var(--color_15);
      --brwe: 2px;
      --bge: var(--color_11);
      --brde: 255, 64, 64;
      --trns: opacity 0.5s ease 0s, border 0.5s ease 0s, color 0.5s ease 0s;
      --bgd: 255, 255, 255;
      --txtd: 219, 219, 219;
      --alpha-txtd: 1;
      --brwd: 0px;
      --brdd: 219, 219, 219;
      --alpha-brdd: 1;
      --fntlbl: var(--font_8);
      --txtlbl: var(--color_14);
      --alpha-txtlbl: 1;
      --txtlblrq: var(--color_14);
      --alpha-txtlblrq: 1;
      --fntprefix: normal normal normal 16px/1.4em helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif;
      --alpha-bg: 1;
      --alpha-bgd: 1;
      --alpha-bge: 1;
      --alpha-bgf: 1;
      --alpha-bgh: 1;
      --alpha-brd: 0.55;
      --alpha-brde: 1;
      --alpha-brdf: 0.55;
      --alpha-brdh: 0.55;
      --alpha-btn_brd: 0.55;
      --boxShadowToggleOn-shd: none;
      --btn_brd: var(--color_15);
      --btn_brw: 0;
      --btn_fnt: var(--font_8);
      --fnt2: var(--font_8);
      --txt-placeholder: var(--color_14);
      --alpha-txt-placeholder: 1;
    }

    #comp-jxad0a3m {
      width: 325px;
      height: 35px;
    }

    #comp-jxad0a4e {
      --errorTextColor: 255, 64, 64;
      --alpha-errorTextColor: 1;
      --errorTextFont: var(--font_8);
      --shd: none;
      --rd: 0px;
      --fnt: var(--font_8);
      --brw: 0px;
      --bg: var(--color_11);
      --txt: var(--color_15);
      --alpha-txt: 1;
      --brd: var(--color_15);
      --txt2: var(--color_14);
      --alpha-txt2: 1;
      --brwh: 0px;
      --bgh: var(--color_17);
      --brdh: var(--color_15);
      --brwf: 0px;
      --bgf: var(--color_16);
      --brdf: var(--color_15);
      --brwe: 2px;
      --bge: var(--color_11);
      --brde: 255, 64, 64;
      --trns: opacity 0.5s ease 0s, border 0.5s ease 0s, color 0.5s ease 0s;
      --bgd: 255, 255, 255;
      --txtd: 219, 219, 219;
      --alpha-txtd: 1;
      --brwd: 0px;
      --brdd: 219, 219, 219;
      --alpha-brdd: 1;
      --fntlbl: var(--font_8);
      --txtlbl: var(--color_14);
      --alpha-txtlbl: 1;
      --txtlblrq: var(--color_14);
      --alpha-txtlblrq: 1;
      --fntprefix: normal normal normal 16px/1.4em helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif;
      --alpha-bg: 1;
      --alpha-bgd: 1;
      --alpha-bge: 1;
      --alpha-bgf: 1;
      --alpha-bgh: 1;
      --alpha-brd: 0.55;
      --alpha-brde: 1;
      --alpha-brdf: 0.55;
      --alpha-brdh: 0.55;
      --alpha-btn_brd: 0.55;
      --boxShadowToggleOn-shd: none;
      --btn_brd: var(--color_15);
      --btn_brw: 0;
      --btn_fnt: var(--font_8);
      --fnt2: var(--font_8);
      --txt-placeholder: var(--color_14);
      --alpha-txt-placeholder: 1;
    }

    #comp-jxad0a4e {
      width: 325px;
      height: 35px;
    }

    #comp-jxad0a4w {
      --errorTextColor: 255, 64, 64;
      --alpha-errorTextColor: 1;
      --errorTextFont: var(--font_8);
      --shd: none;
      --rd: 0px 0px 0px 0px;
      --fnt: var(--font_8);
      --brw: 0px;
      --bg: var(--color_11);
      --txt: var(--color_15);
      --alpha-txt: 1;
      --brd: var(--color_15);
      --txt2: var(--color_14);
      --alpha-txt2: 1;
      --brwh: 0px;
      --bgh: var(--color_17);
      --brdh: var(--color_15);
      --bgd: 255, 255, 255;
      --txtd: 219, 219, 219;
      --alpha-txtd: 1;
      --brwd: 0px;
      --brdd: 219, 219, 219;
      --alpha-brdd: 1;
      --brwf: 0px;
      --bgf: var(--color_16);
      --brdf: var(--color_15);
      --brwe: 2px;
      --bge: var(--color_11);
      --brde: 255, 64, 64;
      --fntlbl: var(--font_8);
      --txtlbl: var(--color_14);
      --alpha-txtlbl: 1;
      --txtlblrq: var(--color_14);
      --alpha-txtlblrq: 1;
      --alpha-bg: 1;
      --alpha-bgd: 1;
      --alpha-bge: 1;
      --alpha-bgf: 1;
      --alpha-bgh: 1;
      --alpha-brd: 0.55;
      --alpha-brde: 1;
      --alpha-brdf: 0.55;
      --alpha-brdh: 0.55;
      --alpha-btn_brd: 0.55;
      --bg2: 170, 170, 170;
      --alpha-bg2: 1;
      --boxShadowToggleOn-shd: none;
      --btn_brd: var(--color_15);
      --btn_brw: 0;
      --btn_fnt: var(--font_8);
      --fnt2: var(--font_8);
      --txt-placeholder: var(--color_14);
      --alpha-txt-placeholder: 1;
    }

    #comp-jxad0a4w {
      width: 663px;
      height: 149px;
    }

    #comp-jxad0a5m {
      --rd: 50px;
      --trans1: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
      --shd: none;
      --fnt: normal normal 700 16px/1.4em raleway, sans-serif;
      --trans2: color 0.4s ease 0s;
      --txt: var(--color_50);
      --brw: 1px;
      --bg: var(--color_48);
      --brd: 255, 255, 255;
      --bgh: var(--color_51);
      --brdh: 182, 232, 227;
      --txth: var(--color_53);
      --bgd: 204, 204, 204;
      --alpha-bgd: 1;
      --brdd: 204, 204, 204;
      --alpha-brdd: 1;
      --txtd: 255, 255, 255;
      --alpha-txtd: 1;
      --alpha-bg: 1;
      --alpha-bgh: 1;
      --alpha-brd: 0;
      --alpha-brdh: 1;
      --alpha-txt: 1;
      --alpha-txth: 1;
      --boxShadowToggleOn-shd: none;
    }

    #comp-jxad0a5m {
      width: 432px;
      height: 35px;
    }

    #comp-jxad0a66 {
      width: 510px;
      height: auto;
    }

    #comp-iruuh2gy {
      width: 170px;
      height: auto;
    }

    #comp-iruuj3h0 {
      --lnw: 1px;
      --brd: var(--color_41);
      --alpha-brd: 1;
    }

    #comp-iruuj3h0 {
      width: 33px;
      height: 5px;
      transform: rotate(270deg);
      --comp-rotate-z: 270deg;
    }

    #comp-iruuhetc {
      width: 170px;
      height: auto;
    }

    #comp-iruuk73j {
      --lnw: 1px;
      --brd: var(--color_41);
      --alpha-brd: 1;
    }

    #comp-iruuk73j {
      width: 33px;
      height: 5px;
      transform: rotate(270deg);
      --comp-rotate-z: 270deg;
    }

    #comp-iruuhpti {
      width: 200px;
      height: auto;
    }
  </style>
  <style id="compCssMappers_gkbn7">
    #gkbn7 {
      width: auto;
      min-height: 40px;
    }

    #pageBackground_gkbn7 {
      --bg-position: absolute;
      --fill-layer-background-overlay-color: transparent;
      --fill-layer-background-overlay-position: absolute;
      --bg-overlay-color: rgb(var(--color_25));
      --bg-gradient: none;
    }

    #comp-lq9dpgvk {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      min-width: 980px;
    }

    #comp-irqduxer {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      --padding: 0px;
      --margin: 0px;
      min-width: 980px;
      --firstChildMarginTop: -1px;
      --lastChildMarginBottom: -1px;
      --items-direction: row;
    }

    #comp-irqduxf8 {
      --fill-layer-video-opacity: 1;
      --fill-layer-background-media-position: fixed;
      --fill-layer-background-media-pointer-events: none;
      --fill-layer-background-overlay-color: rgba(var(--color_11), 0.75);
      --fill-layer-background-overlay-position: fixed;
      --bg-overlay-color: rgb(var(--color_30));
      --bg-gradient: none;
      width: 100%;
      --column-width: 980px;
      --column-flex: 980;
    }

    #comp-isehzfde2 {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #854DFF;
    }

    #comp-isehzfdf {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #854DFF;
    }

    #comp-irqduxg4 {
      --shc-mutated-brightness: 67, 39, 128;
      --margin-start: 0px;
      --margin-end: 0px;
      --fnt: normal normal 700 16px/1.4em raleway, sans-serif;
      --label-align: center;
      --label-text-align: center;
    }

    #comp-isei16an {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #854DFF;
    }

    #comp-isehzfdf1 {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #854DFF;
    }

    #comp-isejgyj5 {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #854DFF;
    }

    #comp-irqdux7i {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      --padding: 0px;
      --margin: 0px;
      min-width: 980px;
      --firstChildMarginTop: -1px;
      --lastChildMarginBottom: -1px;
      --items-direction: row;
    }

    #comp-irqdux85 {
      --bg-overlay-color: rgb(var(--color_36));
      --bg-gradient: none;
      width: 100%;
      --column-width: 980px;
      --column-flex: 980;
    }

    #comp-irqduxgc {
      height: 559px;
      --nav-dot-section-display: none;
      --nav-dot-section-bottom-margin: 32px;
      --nav-dot-margin: 8px;
      --nav-dot-size: 7px;
      --nav-dot-size-selected: 11px;
      --nav-button-width: 17px;
      --nav-button-offset: -19.5px;
      --nav-button-display: block;
      --slides-overflow: clip;
      --transition-duration: 800ms;
    }

    #comp-irt9hbxb {
      position: absolute;
      width: 100%;
      height: 100%;
      --fill-layer-image-opacity: 1;
      --bg-overlay-color: transparent;
      --bg-gradient: none;
    }

    #comp-irut3nj4 {
      position: absolute;
      width: 100%;
      height: 100%;
      --fill-layer-image-opacity: 1;
      --bg-overlay-color: transparent;
      --bg-gradient: none;
    }

    #comp-irte5gp9 {
      --direction: inherit;
      --text-alignment: end;
      --orientation: row-reverse;
      --paddingEnd: 14px;
    }

    #comp-irsxmitt {
      --shc-mutated-brightness: 67, 39, 128;
    }

    #comp-irsxlh58 {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #ED1566;
      --stroke-opacity: 1;
      --fill: #061551;
    }

    #comp-lq9dpgvl {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      min-width: 980px;
    }

    #comp-irqduxcp {
      --bg-overlay-color: rgb(var(--color_36));
      --bg-gradient: none;
      --padding: 0px;
      --margin: 0px;
      min-width: 980px;
      --firstChildMarginTop: -1px;
      --lastChildMarginBottom: -1px;
      --items-direction: row;
    }

    #comp-irqduxcu {
      --bg-overlay-color: rgb(var(--color_36));
      --bg-gradient: none;
      width: 100%;
      --column-width: 325px;
      --column-flex: 325;
    }

    #comp-isejheta {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #854DFF;
    }

    #comp-irqduxdj {
      --bg-overlay-color: rgb(var(--color_36));
      --bg-gradient: none;
      width: 100%;
      --column-width: 324px;
      --column-flex: 324;
    }

    #comp-isei1r55 {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #854DFF;
    }

    #comp-irqduxdz {
      --bg-overlay-color: rgb(var(--color_36));
      --bg-gradient: none;
      width: 100%;
      --column-width: 331px;
      --column-flex: 331;
    }

    #comp-isejuitl {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #854DFF;
    }

    #comp-lq9dpgvm {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      min-width: 980px;
    }

    #comp-irqduxas {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      --padding: 0px;
      --margin: 0px;
      min-width: 980px;
      --firstChildMarginTop: -1px;
      --lastChildMarginBottom: -1px;
      --items-direction: row;
    }

    #comp-irqduxaw {
      --bg-overlay-color: rgb(var(--color_21));
      --bg-gradient: none;
      width: 100%;
      --column-width: 980px;
      --column-flex: 980;
    }

    #comp-irsxtu2g {
      --fill-opacity: 0.5;
      --stroke-width: 1;
      --stroke: #061551;
      --stroke-opacity: 0;
      --fill: #EBFEFF;
      --svg-calculated-padding: 0px 1px 1px 0px;
      --svg-calculated-width: calc(100% - 1px);
      --svg-calculated-height: calc(100% - 1px);
    }

    #comp-irsxtr31 {
      --fill-opacity: 0.5;
      --stroke-width: 1;
      --stroke: #061551;
      --stroke-opacity: 0;
      --fill: #EBFEFF;
      --svg-calculated-padding: 0px 1px 1px 0px;
      --svg-calculated-width: calc(100% - 1px);
      --svg-calculated-height: calc(100% - 1px);
    }

    #comp-irsxsok1 {
      --fill-opacity: 0.5;
      --stroke-width: 1;
      --stroke: #061551;
      --stroke-opacity: 0;
      --fill: #EBFEFF;
      --svg-calculated-padding: 0px 1px 1px 0px;
      --svg-calculated-width: calc(100% - 1px);
      --svg-calculated-height: calc(100% - 1px);
    }

    #comp-iruwiqnd {
      --height: 188px;
      --width: 239px;
    }

    #comp-iruwjbi6 {
      --height: 188px;
      --width: 239px;
    }

    #comp-isejjlss {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #854DFF;
    }

    #comp-isehzfd9 {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #854DFF;
    }

    #comp-iruxih60 {
      --height: 32px;
      --width: 35px;
    }

    #comp-isejkuti {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #854DFF;
    }

    #comp-iruxgy2h {
      --height: 338px;
      --width: 318px;
    }

    #comp-lq9dpgvn {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      min-width: 980px;
    }

    #comp-irqduxhq {
      height: 463px;
      --nav-dot-section-display: block;
      --nav-dot-section-bottom-margin: 36px;
      --nav-dot-margin: 5.5px;
      --nav-dot-size: 6px;
      --nav-dot-size-selected: 9px;
      --nav-button-width: 50px;
      --nav-button-offset: 54px;
      --nav-button-display: none;
      --slides-overflow: visible;
      --transition-duration: 1000ms;
    }

    #comp-irqduxhy {
      position: absolute;
      width: 100%;
      height: 100%;
      --bg-overlay-color: rgb(var(--color_18));
      --bg-gradient: none;
    }

    #comp-isen9lv3 {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-isenegh8 {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-isenkhwl {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-irqduxie {
      transform-origin: center 0.5px;
    }

    #comp-isenckdb {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-isehzfde {
      --fill-opacity: 1;
      --stroke-width: 1;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
      --svg-calculated-padding: 0px 1px 1px 0px;
      --svg-calculated-width: calc(100% - 1px);
      --svg-calculated-height: calc(100% - 1px);
    }

    #comp-isenm3m4 {
      --fill-opacity: 1;
      --stroke-width: 1;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
      --svg-calculated-padding: 0px 1px 1px 0px;
      --svg-calculated-width: calc(100% - 1px);
      --svg-calculated-height: calc(100% - 1px);
    }

    #comp-isenexd1 {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-iseng1vk {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-iseo1p9u {
      position: absolute;
      width: 100%;
      height: 100%;
      --bg-overlay-color: rgb(var(--color_19));
      --bg-gradient: none;
    }

    #comp-iseo1pcd {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-iseo1pdl {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-iseo1pga {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-iseo1pbj {
      transform-origin: center 0.5px;
    }

    #comp-iseo1pcv {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-iseo1pf6 {
      --fill-opacity: 1;
      --stroke-width: 1;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
      --svg-calculated-padding: 0px 1px 1px 0px;
      --svg-calculated-width: calc(100% - 1px);
      --svg-calculated-height: calc(100% - 1px);
    }

    #comp-iseo1pgr {
      --fill-opacity: 1;
      --stroke-width: 1;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
      --svg-calculated-padding: 0px 1px 1px 0px;
      --svg-calculated-width: calc(100% - 1px);
      --svg-calculated-height: calc(100% - 1px);
    }

    #comp-iseo1pdu {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-iseo1pew {
      --fill-opacity: 1;
      --stroke-width: 0;
      --stroke: #000000;
      --stroke-opacity: 0;
      --fill: #AD8BF7;
    }

    #comp-lq9dpgvo {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      min-width: 980px;
    }

    #comp-irtm47ta {
      --bg-overlay-color: rgb(var(--color_36));
      --bg-gradient: none;
      --padding: 0px;
      --margin: 0px;
      min-width: 980px;
      --firstChildMarginTop: -1px;
      --lastChildMarginBottom: -1px;
      --items-direction: row;
    }

    #comp-irtm47to {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      width: 100%;
      --column-width: 196px;
      --column-flex: 196;
    }

    #comp-irtm47u3 {
      height: 103px;
      width: 96px;
      --height: 103px;
      --width: 96px;
    }

    #comp-irtm4h7g {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      width: 100%;
      --column-width: 196px;
      --column-flex: 196;
    }

    #comp-irtm4h7s {
      height: 73px;
      width: 150px;
      --height: 73px;
      --width: 150px;
    }

    #comp-irtm47uh {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      width: 100%;
      --column-width: 196px;
      --column-flex: 196;
    }

    #comp-irtm47us {
      height: 102px;
      width: 102px;
      --height: 102px;
      --width: 102px;
    }

    #comp-irtm47v3 {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      width: 100%;
      --column-width: 196px;
      --column-flex: 196;
    }

    #comp-irtm47vc {
      height: 69px;
      width: 150px;
      --height: 69px;
      --width: 150px;
    }

    #comp-irtm47vo {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      width: 100%;
      --column-width: 196px;
      --column-flex: 196;
    }

    #comp-irtm47w0 {
      height: 101px;
      width: 102px;
      --height: 101px;
      --width: 102px;
    }

    #comp-lq9dpgvp {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      min-width: 980px;
    }

    #comp-irujafzq {
      --bg-overlay-color: transparent;
      --bg-gradient: none;
      --padding: 0px;
      --margin: 0px;
      min-width: 980px;
      --firstChildMarginTop: -1px;
      --lastChildMarginBottom: -1px;
      --items-direction: row;
    }

    #mediairukrt4y {
      --fill-layer-image-opacity: 0.2;
      --fill-layer-background-media-position: fixed;
      --fill-layer-background-media-pointer-events: none;
      --bg-overlay-color: rgb(var(--color_11));
      --bg-gradient: none;
      width: 100%;
      --column-width: 980px;
      --column-flex: 980;
    }

    #comp-irtlor95 {
      --fill-opacity: 1;
      --stroke-width: 1;
      --stroke: #061551;
      --stroke-opacity: 0;
      --fill: #854DFF;
      --svg-calculated-padding: 0px 1px 1px 0px;
      --svg-calculated-width: calc(100% - 1px);
      --svg-calculated-height: calc(100% - 1px);
    }

    #comp-jxad0a3m {
      --fnt: normal normal normal 16px/1.4em raleway, sans-serif;
      --fntlbl: normal normal normal 16px/1.4em raleway, sans-serif;
      --direction: inherit;
      --align: start;
      --labelDirection: inherit;
      --inputDirection: inherit;
      --errorDirection: inherit;
      --requiredIndicationDisplay: inline;
      --labelMarginBottom: 9px;
      --textPadding: 3px;
      --textPadding_start: 12px;
      --textPadding_end: 3px;
      --labelPadding_start: 0px;
      --labelPadding_end: 20px;
      height: auto;
      --inputHeight: 35px;
    }

    #comp-jxad0a4e {
      --fnt: normal normal normal 16px/1.4em raleway, sans-serif;
      --fntlbl: normal normal normal 16px/1.4em raleway, sans-serif;
      --direction: inherit;
      --align: start;
      --labelDirection: inherit;
      --inputDirection: inherit;
      --errorDirection: inherit;
      --requiredIndicationDisplay: inline;
      --labelMarginBottom: 9px;
      --textPadding: 3px;
      --textPadding_start: 12px;
      --textPadding_end: 3px;
      --labelPadding_start: 0px;
      --labelPadding_end: 20px;
      height: auto;
      --inputHeight: 35px;
    }

    #comp-jxad0a4w {
      --direction: inherit;
      --labelDirection: inherit;
      --inputDirection: inherit;
      --errorDirection: inherit;
      --align: start;
      --textPaddingTop: 3px;
      --textPaddingStart: 16px;
      --textPaddingEnd: 10px;
      --labelMarginBottom: 9px;
      --requiredIndicationDisplay: inline;
      --labelPadding_start: 0px;
      --labelPadding_end: 20px;
      height: auto;
      --inputHeight: 149px;
    }

    #comp-jxad0a5m {
      --shc-mutated-brightness: 67, 39, 128;
      --margin-start: 0px;
      --margin-end: 0px;
      --fnt: normal normal 700 16px/1.4em raleway, sans-serif;
      --label-align: center;
      --label-text-align: center;
    }

    #comp-iruuj3h0 {
      transform-origin: center 0.5px;
    }

    #comp-iruuk73j {
      transform-origin: center 0.5px;
    }
  </style>

</head>

<body class="">

  <div id="SITE_CONTAINER">
    <style id="STYLE_OVERRIDES_ID">
      #comp-jxad0a66 {
        visibility: hidden !important;
      }
    </style>
    <div id="main_MF" class="main_MF">
      <div id="site-root" class="site-root">
        <div id="masterPage" class="mesh-layout remove-wrappers masterPage css-editing-scope">
          <div id="pinnedTopLeft" class="pinnedTopLeft">
            <div id="comp-ita18ph6-pinned-layer" class="comp-ita18ph6-pinned-layer big2ZD">
              <div id="comp-ita18ph6" class="jbrj02 comp-ita18ph6">
                <div class="IvR70V wixui-box" data-testid="container-bg"></div>
                <div data-mesh-id="comp-ita18ph6inlineContent" data-testid="inline-content" class="">
                  <div data-mesh-id="comp-ita18ph6inlineContent-gridContainer" data-testid="mesh-container-content">
                    <div id="comp-ir9mnyu3" class="HcOXKn SxM0TO QxJLC3 lq2cno comp-ir9mnyu3 wixui-rich-text"
                      data-testid="richTextElement">
                      <p class="font_0 wixui-rich-text__text" style="font-size:28px;"><span
                          style="letter-spacing:0.05em;" class="wixui-rich-text__text"><a
                            href="#" target="_self"
                            class="wixui-rich-text__text">analyti</a></span></p>
                    </div>
                    <div id="comp-isejnyac" class="comp-isejnyac wixui-vector-image"><a data-testid="linkElement"
                        href="#" class="a9YhBi">
                        <div data-testid="svgRoot-comp-isejnyac" class="AKxYR5 VZYmYf comp-isejnyac"><svg
                            preserveAspectRatio="xMidYMid meet" data-bbox="5.137 5.637 188.725 188.725" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" viewBox="5.137 5.637 188.725 188.725" role="presentation"
                            aria-hidden="true">
                            <g>
                              <path class="st0" d="M149.8,80l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3s-21.9-8.3-30.3,0l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3
		S141.4,88.3,149.8,80z"></path>
                              <path class="st0" d="M49.2,120l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3s21.9,8.3,30.3,0l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3
		S57.6,111.7,49.2,120z"></path>
                              <path class="st0" d="M149.8,120c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3l37.8,37.8c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3
		L149.8,120z"></path>
                              <path class="st0" d="M41.7,12c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3L49.2,80c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3L41.7,12
		z"></path>
                            </g>
                          </svg></div>
                      </a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="pinnedTopRight" class="pinnedTopRight">
            <div id="comp-ir9le55m-pinned-layer" class="comp-ir9le55m-pinned-layer big2ZD"><wix-dropdown-menu
                id="comp-ir9le55m" class="XwCBRN NHM1d1 comp-ir9le55m wixui-dropdown-menu hidden-during-prewarmup"
                tabindex="-1" dir="ltr" data-stretch-buttons-to-menu-width="true" data-same-width-buttons="false"
                data-num-items="6" data-menuborder-y="0" data-menubtn-border="0" data-ribbon-els="0" data-label-pad="0"
                data-ribbon-extra="0" data-dropalign="center" style="visibility: inherit; overflow-x: visible;"
                data-dropmode="dropDown">
                <nav class="R_TAzU" id="comp-ir9le55mnavContainer" aria-label="Site">
                  <ul class="y7qwii" id="comp-ir9le55mitemsContainer" style="text-align:center"
                    data-marginallchildren="true">
                    <li id="comp-ir9le55m0" data-direction="ltr" data-listposition="left"
                      data-data-id="dataItem-irqdux6j" data-state="menu selected  link" data-index="0"
                      class="Tg1gOB wixui-dropdown-menu__item xTjc1A" data-original-gap-between-text-and-btn="10"
                      aria-hidden="false"
                      style="width: 97px; height: 34px; position: relative; box-sizing: border-box; overflow: visible; visibility: inherit;">
                      <a data-testid="linkElement" href="#"
                        class="UiHgGh" aria-haspopup="false">
                        <div class="yRj2ms">
                          <div class="" style="text-align:right">
                            <p class="JS76Uv" style="text-align: right; line-height: 34px;" id="comp-ir9le55m0label">
                              Home</p>
                          </div>
                        </div>
                      </a></li>
                    <li id="comp-ir9le55m1" data-direction="ltr" data-listposition="center"
                      data-data-id="dataItem-irtedz1t" data-state="menu false  link" data-index="1"
                      class="Tg1gOB wixui-dropdown-menu__item xTjc1A" data-original-gap-between-text-and-btn="10"
                      aria-hidden="false"
                      style="width: 98px; height: 34px; position: relative; box-sizing: border-box; overflow: visible; visibility: inherit;">
                      <a data-testid="linkElement" data-anchor="dataItem-irte5pms"
                        href="#" target="_self" class="UiHgGh"
                        aria-haspopup="false">
                        <div class="yRj2ms">
                          <div class="" style="text-align:right">
                            <p class="JS76Uv" style="text-align: right; line-height: 34px;" id="comp-ir9le55m1label">
                              About</p>
                          </div>
                        </div>
                      </a></li>
                    <li id="comp-ir9le55m2" data-direction="ltr" data-listposition="center"
                      data-data-id="dataItem-irteelr3" data-state="menu false  link" data-index="2"
                      class="Tg1gOB wixui-dropdown-menu__item xTjc1A" data-original-gap-between-text-and-btn="10"
                      aria-hidden="false"
                      style="width: 142px; height: 34px; position: relative; box-sizing: border-box; overflow: visible; visibility: inherit;">
                      <a data-testid="linkElement" data-anchor="dataItem-irte6ovz"
                        href="#" target="_self" class="UiHgGh"
                        aria-haspopup="false">
                        <div class="yRj2ms">
                          <div class="" style="text-align:right">
                            <p class="JS76Uv" style="text-align: right; line-height: 34px;" id="comp-ir9le55m2label">How
                              it works</p>
                          </div>
                        </div>
                      </a></li>
                    <li id="comp-ir9le55m3" data-direction="ltr" data-listposition="center"
                      data-data-id="dataItem-irtef2yf" data-state="menu false  link" data-index="3"
                      class="Tg1gOB wixui-dropdown-menu__item xTjc1A" data-original-gap-between-text-and-btn="10"
                      aria-hidden="false"
                      style="width: 93px; height: 34px; position: relative; box-sizing: border-box; overflow: visible; visibility: inherit;">
                      <a data-testid="linkElement" data-anchor="dataItem-irte7nrn"
                        href="#" target="_self" class="UiHgGh"
                        aria-haspopup="false">
                        <div class="yRj2ms">
                          <div class="" style="text-align:right">
                            <p class="JS76Uv" style="text-align: right; line-height: 34px;" id="comp-ir9le55m3label">
                              Press</p>
                          </div>
                        </div>
                      </a></li>
                    <li id="comp-ir9le55m4" data-direction="ltr" data-listposition="center"
                      data-data-id="dataItem-irtefiku" data-state="menu false  link" data-index="4"
                      class="Tg1gOB wixui-dropdown-menu__item xTjc1A" data-original-gap-between-text-and-btn="10"
                      aria-hidden="false"
                      style="width: 155px; height: 34px; position: relative; box-sizing: border-box; overflow: visible; visibility: inherit;">
                      <a data-testid="linkElement" data-anchor="dataItem-irteb1qp"
                        href="#" target="_self" class="UiHgGh"
                        aria-haspopup="false">
                        <div class="yRj2ms">
                          <div class="" style="text-align:right">
                            <p class="JS76Uv" style="text-align: right; line-height: 34px;" id="comp-ir9le55m4label">
                              Request demo</p>
                          </div>
                        </div>
                      </a></li>
                    <li id="comp-ir9le55m5" data-direction="ltr" data-listposition="right"
                      data-data-id="dataItem-lq9dq29w" data-state="menu false  link" data-index="5"
                      class="Tg1gOB wixui-dropdown-menu__item xTjc1A" data-original-gap-between-text-and-btn="10"
                      aria-hidden="false"
                      style="width: 138px; height: 34px; position: relative; box-sizing: border-box; overflow: visible; visibility: inherit;">
                      <a data-testid="linkElement"
                        href="#/book-online" target="_self"
                        class="UiHgGh" aria-haspopup="false">
                        <div class="yRj2ms">
                          <div class="" style="text-align:right">
                            <p class="JS76Uv" style="text-align: right; line-height: 34px;" id="comp-ir9le55m5label">
                              Book Online</p>
                          </div>
                        </div>
                      </a></li>
                    <li id="comp-ir9le55m__more__" data-direction="ltr" data-listposition="right"
                      data-state="menu false  header" data-index="__more__" data-dropdown="false" class="p90CkU xTjc1A"
                      data-original-gap-between-text-and-btn="10" aria-hidden="true"
                      style="height: 0px; overflow: hidden; position: absolute; visibility: hidden;">
                      <div data-testid="linkElement" class="UiHgGh" tabindex="0" aria-haspopup="false">
                        <div class="yRj2ms">
                          <div class="" style="text-align:right">
                            <p class="JS76Uv" style="text-align:right" id="comp-ir9le55m__more__label" tabindex="-1">
                              More</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="h3jCPd" id="comp-ir9le55mdropWrapper" data-dropalign="center" data-dropdown-shown="false"
                    style="inset: 34px auto auto 195px;">
                    <ul class="wkJ2fp wixui-dropdown-menu__submenu" id="comp-ir9le55mmoreContainer"
                      style="left: 195px; right: auto;"></ul>
                  </div>
                  <div style="display:none" id="comp-ir9le55mnavContainer-hiddenA11ySubMenuIndication">Use tab to
                    navigate through the menu items.</div>
                </nav>
              </wix-dropdown-menu></div>
          </div>
          <header id="SITE_HEADER" class="xU8fqS SITE_HEADER wixui-header" tabindex="-1">
            <div class="_C0cVf">
              <div class="_4XcTfy" data-testid="screenWidthContainerBg"></div>
            </div>
            <div class="U4Bvut">
              <div class="G5K6X8">
                <div class="gUbusX" data-testid="screenWidthContainerBgCenter"></div>
              </div>
              <div class="CJF7A2">
                <div data-mesh-id="SITE_HEADERinlineContent" data-testid="inline-content" class="">
                  <div data-mesh-id="SITE_HEADERinlineContent-gridContainer" data-testid="mesh-container-content">
                    <!--$-->
                    <div id="comp-lq9dqai3" class="TWFxr5 ">
                      <div class="comp-lq9dqai3"><a class="ETC5zb tx4Jvn s1dvzA" data-hook="cart-icon-button"
                          href="#" role="button"
                          aria-label="Cart with 0 items">
                          <div class="Q8TtId" style="padding-bottom:119.5260663507109%" data-hook="svg-icon-wrapper"></div>
                        </a></div>
                    </div><!--/$-->
                  </div>
                </div>
              </div>
            </div>
          </header>
          <main id="PAGES_CONTAINER" class="PAGES_CONTAINER" tabindex="-1" data-main-content="true">
            <div id="SITE_PAGES" class="JsJXaX SITE_PAGES">
              <div id="SITE_PAGES_TRANSITION_GROUP" class="AnQkDU">
                <div id="gkbn7" class="dBAkHi gkbn7">
                  <div class="PFkO7r wixui-page" data-testid="page-bg"></div>
                  <div class="HT5ybB">
                    <div id="pagePinnedMiddleRight-gkbn7" class="pagePinnedMiddleRight-gkbn7">
                      <div id="comp-irte5gp9-pinned-layer" class="comp-irte5gp9-pinned-layer big2ZD">
                        <nav id="comp-irte5gp9" aria-label="Page" class="tCKgoz comp-irte5gp9 wixui-anchor-menu">
                          <ul class="QOGIpb">
                            <li class="wixui-anchor-menu__item"><a data-testid="linkElement" data-anchor="SCROLL_TO_TOP"
                                data-anchor-comp-id="PAGE_TOP_ANCHOR"
                                href="#" target="_self" class="jpyXrk"
                                aria-labelledby="anchor-label_PAGE_TOP_ANCHOR"><svg class="zJEZL4" viewBox="0 0 24 24">
                                  <circle cx="12" cy="12" r="10"></circle>
                                </svg><span id="anchor-label_PAGE_TOP_ANCHOR" class="JnSp5B">Top of Page</span></a></li>
                            <li class="wixui-anchor-menu__item"><a data-testid="linkElement"
                                data-anchor="dataItem-irte5pms" data-anchor-comp-id="comp-irte5pmq"
                                href="#" target="_self" class="jpyXrk"
                                aria-labelledby="anchor-label_comp-irte5pmq"><svg class="zJEZL4" viewBox="0 0 24 24">
                                  <circle cx="12" cy="12" r="10"></circle>
                                </svg><span id="anchor-label_comp-irte5pmq" class="JnSp5B">Who are we</span></a></li>
                            <li class="wixui-anchor-menu__item"><a data-testid="linkElement"
                                data-anchor="dataItem-irte6ovz" data-anchor-comp-id="comp-irte6ovt"
                                href="#" target="_self" class="jpyXrk"
                                aria-labelledby="anchor-label_comp-irte6ovt"><svg class="zJEZL4" viewBox="0 0 24 24">
                                  <circle cx="12" cy="12" r="10"></circle>
                                </svg><span id="anchor-label_comp-irte6ovt" class="JnSp5B">How it works</span></a></li>
                            <li class="wixui-anchor-menu__item"><a data-testid="linkElement"
                                data-anchor="dataItem-irte7nrn" data-anchor-comp-id="comp-irte7nrj"
                                href="#" target="_self" class="jpyXrk"
                                aria-labelledby="anchor-label_comp-irte7nrj"><svg class="zJEZL4" viewBox="0 0 24 24">
                                  <circle cx="12" cy="12" r="10"></circle>
                                </svg><span id="anchor-label_comp-irte7nrj" class="JnSp5B">Testimonials</span></a></li>
                            <li class="wixui-anchor-menu__item"><a data-testid="linkElement"
                                data-anchor="dataItem-irteb1qp" data-anchor-comp-id="comp-irteb1ql"
                                href="#" target="_self" class="jpyXrk"
                                aria-labelledby="anchor-label_comp-irteb1ql"><svg class="zJEZL4" viewBox="0 0 24 24">
                                  <circle cx="12" cy="12" r="10"></circle>
                                </svg><span id="anchor-label_comp-irteb1ql" class="JnSp5B">Request demo</span></a></li>
                            <li class="wixui-anchor-menu__item"><a data-testid="linkElement"
                                data-anchor="anchors-lq9dtzcx" data-anchor-comp-id="SITE_FOOTER"
                                href="#" target="_self" class="jpyXrk"
                                aria-labelledby="anchor-label_SITE_FOOTER"><svg class="zJEZL4" viewBox="0 0 24 24">
                                  <circle cx="12" cy="12" r="10"></circle>
                                </svg><span id="anchor-label_SITE_FOOTER" class="JnSp5B">Footer</span></a></li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <div id="Containergkbn7" class="Containergkbn7 SPY_vo">
                      <div data-mesh-id="Containergkbn7inlineContent" data-testid="inline-content" class="">
                        <div data-mesh-id="Containergkbn7inlineContent-gridContainer"
                          data-testid="mesh-container-content">
                          <section id="comp-lq9dpgvk" tabindex="-1" class="Oqnisf comp-lq9dpgvk wixui-section"
                            data-block-level-container="ClassicSection">
                            <div id="bgLayers_comp-lq9dpgvk" data-hook="bgLayers" data-motion-part="BG_LAYER"
                              class="MW5IWV">
                              <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                              <div id="bgMedia_comp-lq9dpgvk" data-motion-part="BG_MEDIA" class="VgO9Yg"></div>
                            </div>
                            <div data-mesh-id="comp-lq9dpgvkinlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-lq9dpgvkinlineContent-gridContainer"
                                data-testid="mesh-container-content">
                                <section id="comp-irqduxer" class="comp-irqduxer CohWsy wixui-column-strip">
                                  <div id="bgLayers_comp-irqduxer" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                    class="if7Vw2">
                                    <div data-testid="colorUnderlay" class="tcElKx i1tH8h"></div>
                                    <div id="bgMedia_comp-irqduxer" data-motion-part="BG_MEDIA" class="wG8dni"></div>
                                  </div>
                                  <div data-testid="columns" class="V5AUxf">
                                    <div id="comp-irqduxf8" class="comp-irqduxf8 YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_comp-irqduxf8" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV N3eg0s">
                                        <div data-testid="bgOverlay" class="m4khSP"></div>
                                      </div>
                                      <div data-mesh-id="comp-irqduxf8inlineContent" data-testid="inline-content"
                                        class="mwF7X1">
                                        <div data-mesh-id="comp-irqduxf8inlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-isehzfde2" class="comp-isehzfde2 wixui-vector-image"
                                            data-motion-enter="done">
                                            <div data-testid="svgRoot-comp-isehzfde2"
                                              class="AKxYR5 VZYmYf comp-isehzfde2"><svg
                                                preserveAspectRatio="xMidYMid meet"
                                                data-bbox="5.137 5.637 188.725 188.725" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="5.137 5.637 188.725 188.725"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M149.8,80l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3s-21.9-8.3-30.3,0l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3
		S141.4,88.3,149.8,80z"></path>
                                                  <path class="st0" d="M49.2,120l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3s21.9,8.3,30.3,0l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3
		S57.6,111.7,49.2,120z"></path>
                                                  <path class="st0" d="M149.8,120c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3l37.8,37.8c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3
		L149.8,120z"></path>
                                                  <path class="st0" d="M41.7,12c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3L49.2,80c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3L41.7,12
		z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-irqduxfv"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxfv wixui-rich-text"
                                            data-testid="richTextElement" data-motion-enter="done">
                                            <h1 class="font_3 wixui-rich-text__text"
                                              style="line-height:1.2em; text-align:center; font-size:55px;"><span
                                                class="color_36 wixui-rich-text__text">Get Real-time Stock Alerts</span>
                                            </h1>
                                          </div>
                                          <div id="comp-isehzfdf" class="comp-isehzfdf wixui-vector-image"
                                            data-motion-enter="done">
                                            <div data-testid="svgRoot-comp-isehzfdf"
                                              class="AKxYR5 VZYmYf comp-isehzfdf"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="1 1 198 198"
                                                version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="1 1 198 198"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M181.9,83H117V18.1C117,8.7,109.4,1,100,1c-9.4,0-17,7.7-17,17.1V83H18.1C8.7,83,1,90.6,1,100
	c0,9.4,7.7,17,17.1,17H83v64.9c0,9.4,7.6,17.1,17,17.1c9.4,0,17-7.7,17-17.1V117h64.9c9.4,0,17.1-7.6,17.1-17
	C199,90.6,191.3,83,181.9,83z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-irqduxg0"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxg0 wixui-rich-text"
                                            data-testid="richTextElement" data-motion-enter="done">
                                            <h6 class="font_6 wixui-rich-text__text"
                                              style="text-align:center; font-size:18px;"><span
                                                class="color_36 wixui-rich-text__text">Updates on your investments to
                                                take informed decisions</span></h6>
                                          </div>
                                          <div class="comp-irqduxg4 FubTgk" id="comp-irqduxg4" aria-disabled="false"
                                            data-motion-enter="done"><button data-testid="linkElement"
                                              data-anchor="dataItem-irteb1qp" data-anchor-comp-id="comp-irteb1ql"
                                              id="triggerButton"
                                              class="uDW_Qe wixui-button PlZyDq" aria-disabled="false"><span
                                                class="l7_2fn wixui-button__label">Sign Up Now</span></button></div>
                                          <div id="comp-isei16an" class="comp-isei16an wixui-vector-image"
                                            data-motion-enter="done">
                                            <div data-testid="svgRoot-comp-isei16an"
                                              class="AKxYR5 VZYmYf comp-isei16an"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="5.1 5.1 189.8 189.8"
                                                version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="5.1 5.1 189.8 189.8" role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M100,5.1C47.6,5.1,5.1,47.6,5.1,100c0,52.4,42.5,94.9,94.9,94.9c52.4,0,94.9-42.5,94.9-94.9
	C194.9,47.6,152.4,5.1,100,5.1z M100,160.4c-33.3,0-60.4-27-60.4-60.4c0-33.3,27-60.4,60.4-60.4c33.3,0,60.4,27,60.4,60.4
	C160.4,133.3,133.3,160.4,100,160.4z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-isehzfdf1" class="comp-isehzfdf1 wixui-vector-image"
                                            data-motion-enter="done">
                                            <div data-testid="svgRoot-comp-isehzfdf1"
                                              class="AKxYR5 VZYmYf comp-isehzfdf1"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="5.1 5.1 189.8 189.8"
                                                version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="5.1 5.1 189.8 189.8" role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M100,5.1C47.6,5.1,5.1,47.6,5.1,100c0,52.4,42.5,94.9,94.9,94.9c52.4,0,94.9-42.5,94.9-94.9
	C194.9,47.6,152.4,5.1,100,5.1z M100,160.4c-33.3,0-60.4-27-60.4-60.4c0-33.3,27-60.4,60.4-60.4c33.3,0,60.4,27,60.4,60.4
	C160.4,133.3,133.3,160.4,100,160.4z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-isejgyj5" class="comp-isejgyj5 wixui-vector-image"
                                            data-motion-enter="done">
                                            <div data-testid="svgRoot-comp-isejgyj5"
                                              class="AKxYR5 VZYmYf comp-isejgyj5"><svg
                                                preserveAspectRatio="xMidYMid meet"
                                                data-bbox="5.137 5.637 188.725 188.725" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="5.137 5.637 188.725 188.725"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M149.8,80l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3s-21.9-8.3-30.3,0l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3
		S141.4,88.3,149.8,80z"></path>
                                                  <path class="st0" d="M49.2,120l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3s21.9,8.3,30.3,0l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3
		S57.6,111.7,49.2,120z"></path>
                                                  <path class="st0" d="M149.8,120c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3l37.8,37.8c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3
		L149.8,120z"></path>
                                                  <path class="st0" d="M41.7,12c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3L49.2,80c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3L41.7,12
		z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div data-mesh-id="comp-irqduxf8inlineContent-wedge-8"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                                <section id="comp-irqdux7i" class="comp-irqdux7i CohWsy wixui-column-strip">
                                  <div id="bgLayers_comp-irqdux7i" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                    class="if7Vw2">
                                    <div data-testid="colorUnderlay" class="tcElKx i1tH8h"></div>
                                    <div id="bgMedia_comp-irqdux7i" data-motion-part="BG_MEDIA" class="wG8dni"></div>
                                  </div>
                                  <div data-testid="columns" class="V5AUxf">
                                    <div id="comp-irqdux85" class="comp-irqdux85 YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_comp-irqdux85" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                        <div id="bgMedia_comp-irqdux85" data-motion-part="BG_MEDIA" class="VgO9Yg">
                                        </div>
                                      </div>
                                      <div data-mesh-id="comp-irqdux85inlineContent" data-testid="inline-content"
                                        class="">
                                        <div data-mesh-id="comp-irqdux85inlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-irqduxgc"
                                            class="comp-irqduxgc mDzRgi QodGTM ignore-focus wixui-slideshow"
                                            role="region" tabindex="-1" aria-label="Slideshow" data-motion-enter="done">
                                            <div data-testid="shadowLayer" class="N2NGoO"></div>
                                            <div data-testid="slidesWrapper" aria-live="off" class="hDJzl4">
                                              <div id="comp-irt9hbxb" class="comp-irt9hbxb imK94d">
                                                <div id="bgLayers_comp-irt9hbxb" data-hook="bgLayers"
                                                  data-motion-part="BG_LAYER" class="MW5IWV eF_jBL">
                                                  <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                                  <div id="bgMedia_comp-irt9hbxb" data-motion-part="BG_MEDIA"
                                                    class="VgO9Yg"><wow-image id="img_comp-irt9hbxb"
                                                      class="HlRz5e Kv1aVt dLPlxY mNGsUM bgImage"
                                                      data-has-ssr-src="true"><img
                                                        src="/assets/d0220c_fc555432fc9542e994e1645024f3ebe1~mv2.png"
                                                        alt=""
                                                        style="width: 905px; height: 559px; object-fit: cover; object-position: 50% 50%;"
                                                        width="905" height="559" data-ssr-src-done="true"
                                                        fetchpriority="high"></wow-image></div>
                                                </div>
                                                <div class="wfm0FO"></div>
                                                <div data-mesh-id="comp-irt9hbxbinlineContent"
                                                  data-testid="inline-content" class="">
                                                  <div data-mesh-id="comp-irt9hbxbinlineContent-gridContainer"
                                                    data-testid="mesh-container-content"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </section>
                          <section id="comp-lq9dpgvl" tabindex="-1" class="Oqnisf comp-lq9dpgvl wixui-section"
                            data-block-level-container="ClassicSection">
                            <div id="bgLayers_comp-lq9dpgvl" data-hook="bgLayers" data-motion-part="BG_LAYER"
                              class="MW5IWV">
                              <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                              <div id="bgMedia_comp-lq9dpgvl" data-motion-part="BG_MEDIA" class="VgO9Yg"></div>
                            </div>
                            <div data-mesh-id="comp-lq9dpgvlinlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-lq9dpgvlinlineContent-gridContainer"
                                data-testid="mesh-container-content">
                                <section id="comp-irqduxcp" class="comp-irqduxcp CohWsy wixui-column-strip">
                                  <div id="bgLayers_comp-irqduxcp" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                    class="if7Vw2">
                                    <div data-testid="colorUnderlay" class="tcElKx i1tH8h"></div>
                                    <div id="bgMedia_comp-irqduxcp" data-motion-part="BG_MEDIA" class="wG8dni"></div>
                                  </div>
                                  <div data-testid="columns" class="V5AUxf">
                                    <div id="comp-irqduxcu" class="comp-irqduxcu YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_comp-irqduxcu" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                        <div id="bgMedia_comp-irqduxcu" data-motion-part="BG_MEDIA" class="VgO9Yg">
                                        </div>
                                      </div>
                                      <div data-mesh-id="comp-irqduxcuinlineContent" data-testid="inline-content"
                                        class="">
                                        <div data-mesh-id="comp-irqduxcuinlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-isejheta" class="comp-isejheta wixui-vector-image">
                                            <div data-testid="svgRoot-comp-isejheta"
                                              class="AKxYR5 VZYmYf comp-isejheta"><svg
                                                preserveAspectRatio="xMidYMid meet"
                                                data-bbox="5.137 5.637 188.725 188.725" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="5.137 5.637 188.725 188.725"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M149.8,80l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3s-21.9-8.3-30.3,0l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3
		S141.4,88.3,149.8,80z"></path>
                                                  <path class="st0" d="M49.2,120l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3s21.9,8.3,30.3,0l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3
		S57.6,111.7,49.2,120z"></path>
                                                  <path class="st0" d="M149.8,120c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3l37.8,37.8c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3
		L149.8,120z"></path>
                                                  <path class="st0" d="M41.7,12c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3L49.2,80c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3L41.7,12
		z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-irqduxd4"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxd4 wixui-rich-text"
                                            data-testid="richTextElement">
                                            <h5 class="font_5 wixui-rich-text__text"
                                              style="text-align:center; font-size:20px;">About StockAlert Co In</h5>
                                          </div>
                                          <div id="comp-irqduxcy"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxcy wixui-rich-text"
                                            data-testid="richTextElement">
                                            <p class="font_8 wixui-rich-text__text"
                                              style="line-height:1.7em; text-align:center; font-size:16px;">We are a
                                              stock alert site based in India. Our goal is to provide timely and
                                              relevant information to make informed investment decisions. We offer
                                              instant WhatsApp notifications and an end-of-day report summarizing the
                                              day's stock activity. You can customize your alerts based on specific
                                              criteria, and get the alerts directly on your WhatsApp.</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="comp-irqduxdj" class="comp-irqduxdj YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_comp-irqduxdj" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                        <div id="bgMedia_comp-irqduxdj" data-motion-part="BG_MEDIA" class="VgO9Yg">
                                        </div>
                                      </div>
                                      <div data-mesh-id="comp-irqduxdjinlineContent" data-testid="inline-content"
                                        class="">
                                        <div data-mesh-id="comp-irqduxdjinlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-isei1r55" class="comp-isei1r55 wixui-vector-image">
                                            <div data-testid="svgRoot-comp-isei1r55"
                                              class="AKxYR5 VZYmYf comp-isei1r55"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="5.1 5.1 189.8 189.8"
                                                version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="5.1 5.1 189.8 189.8" role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M100,5.1C47.6,5.1,5.1,47.6,5.1,100c0,52.4,42.5,94.9,94.9,94.9c52.4,0,94.9-42.5,94.9-94.9
	C194.9,47.6,152.4,5.1,100,5.1z M100,160.4c-33.3,0-60.4-27-60.4-60.4c0-33.3,27-60.4,60.4-60.4c33.3,0,60.4,27,60.4,60.4
	C160.4,133.3,133.3,160.4,100,160.4z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-irqduxds"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxds wixui-rich-text"
                                            data-testid="richTextElement">
                                            <h5 class="font_5 wixui-rich-text__text"
                                              style="text-align:center; font-size:20px;">Our Vision</h5>
                                          </div>
                                          <div id="comp-irqduxdn"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxdn wixui-rich-text"
                                            data-testid="richTextElement">
                                            <p class="font_8 wixui-rich-text__text"
                                              style="line-height:1.7em; text-align:center; font-size:16px;">Our vision
                                              is to empower traders with the latest information and tools to make
                                              informed trading decisions.</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="comp-irqduxdz" class="comp-irqduxdz YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_comp-irqduxdz" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                        <div id="bgMedia_comp-irqduxdz" data-motion-part="BG_MEDIA" class="VgO9Yg">
                                        </div>
                                      </div>
                                      <div data-mesh-id="comp-irqduxdzinlineContent" data-testid="inline-content"
                                        class="">
                                        <div data-mesh-id="comp-irqduxdzinlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-isejuitl" class="comp-isejuitl wixui-vector-image">
                                            <div data-testid="svgRoot-comp-isejuitl"
                                              class="AKxYR5 VZYmYf comp-isejuitl"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="1 1 198 198"
                                                version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="1 1 198 198"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M181.9,83H117V18.1C117,8.7,109.4,1,100,1c-9.4,0-17,7.7-17,17.1V83H18.1C8.7,83,1,90.6,1,100
	c0,9.4,7.7,17,17.1,17H83v64.9c0,9.4,7.6,17.1,17,17.1c9.4,0,17-7.7,17-17.1V117h64.9c9.4,0,17.1-7.6,17.1-17
	C199,90.6,191.3,83,181.9,83z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-irqduxeb"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxeb wixui-rich-text"
                                            data-testid="richTextElement">
                                            <h5 class="font_5 wixui-rich-text__text"
                                              style="text-align:center; font-size:20px;">Technology</h5>
                                          </div>
                                          <div id="comp-irqduxe2"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxe2 wixui-rich-text"
                                            data-testid="richTextElement">
                                            <p class="font_8 wixui-rich-text__text"
                                              style="line-height:1.7em; text-align:center; font-size:16px;">Our
                                              technology is designed to provide real-time stock alerts and end-of-day
                                              reports to our users. We use advanced algorithms to analyze the stock
                                              market data and provide alerts based on specific criteria.</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </section>
                          <section id="comp-lq9dpgvm" tabindex="-1" class="Oqnisf comp-lq9dpgvm wixui-section"
                            data-block-level-container="ClassicSection">
                            <div id="bgLayers_comp-lq9dpgvm" data-hook="bgLayers" data-motion-part="BG_LAYER"
                              class="MW5IWV">
                              <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                              <div id="bgMedia_comp-lq9dpgvm" data-motion-part="BG_MEDIA" class="VgO9Yg"></div>
                            </div>
                            <div data-mesh-id="comp-lq9dpgvminlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-lq9dpgvminlineContent-gridContainer"
                                data-testid="mesh-container-content">
                                <section id="comp-irqduxas" class="comp-irqduxas CohWsy wixui-column-strip">
                                  <div id="bgLayers_comp-irqduxas" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                    class="if7Vw2">
                                    <div data-testid="colorUnderlay" class="tcElKx i1tH8h"></div>
                                    <div id="bgMedia_comp-irqduxas" data-motion-part="BG_MEDIA" class="wG8dni"></div>
                                  </div>
                                  <div data-testid="columns" class="V5AUxf">
                                    <div id="comp-irqduxaw" class="comp-irqduxaw YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_comp-irqduxaw" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                        <div id="bgMedia_comp-irqduxaw" data-motion-part="BG_MEDIA" class="VgO9Yg">
                                        </div>
                                      </div>
                                      <div data-mesh-id="comp-irqduxawinlineContent" data-testid="inline-content"
                                        class="">
                                        <div data-mesh-id="comp-irqduxawinlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-irtb3bz7"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irtb3bz7 wixui-rich-text"
                                            data-testid="richTextElement">
                                            <h4 class="font_4 wixui-rich-text__text"
                                              style="text-align:center; font-size:40px;">How It Works</h4>
                                          </div>
                                          <div id="comp-irsxtu2g" class="comp-irsxtu2g wixui-vector-image">
                                            <div data-testid="svgRoot-comp-irsxtu2g"
                                              class="AKxYR5 VZYmYf comp-irsxtu2g"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="1.9 1.9 56.2 56.2"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="1.9 1.9 56.2 56.2"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path
                                                    d="M30 1.9C14.5 1.9 1.9 14.5 1.9 30c0 15.5 12.6 28.1 28.1 28.1S58.1 45.5 58.1 30C58.1 14.5 45.5 1.9 30 1.9zm0 44c-8.8 0-15.9-7.1-15.9-15.9S21.2 14.1 30 14.1 45.9 21.2 45.9 30 38.8 45.9 30 45.9z">
                                                  </path>
                                                </g>
                                              </svg>
                                            </div>
                                          </div>
                                          <div id="comp-irsxtr31" class="comp-irsxtr31 wixui-vector-image">
                                            <div data-testid="svgRoot-comp-irsxtr31"
                                              class="AKxYR5 VZYmYf comp-irsxtr31"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="1.9 1.9 56.2 56.2"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="1.9 1.9 56.2 56.2"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path
                                                    d="M30 1.9C14.5 1.9 1.9 14.5 1.9 30c0 15.5 12.6 28.1 28.1 28.1S58.1 45.5 58.1 30C58.1 14.5 45.5 1.9 30 1.9zm0 44c-8.8 0-15.9-7.1-15.9-15.9S21.2 14.1 30 14.1 45.9 21.2 45.9 30 38.8 45.9 30 45.9z">
                                                  </path>
                                                </g>
                                              </svg>
                                            </div>
                                          </div>
                                          <div id="comp-irsxsok1" class="comp-irsxsok1 wixui-vector-image">
                                            <div data-testid="svgRoot-comp-irsxsok1"
                                              class="AKxYR5 VZYmYf comp-irsxsok1"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="1.9 1.9 56.2 56.2"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="1.9 1.9 56.2 56.2"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path
                                                    d="M30 1.9C14.5 1.9 1.9 14.5 1.9 30c0 15.5 12.6 28.1 28.1 28.1S58.1 45.5 58.1 30C58.1 14.5 45.5 1.9 30 1.9zm0 44c-8.8 0-15.9-7.1-15.9-15.9S21.2 14.1 30 14.1 45.9 21.2 45.9 30 38.8 45.9 30 45.9z">
                                                  </path>
                                                </g>
                                              </svg>
                                            </div>
                                          </div>
                                          <div id="comp-iruwiqnd" class="MazNVa comp-iruwiqnd wixui-image">
                                            <div data-testid="linkElement" class="j7pOnl"><wow-image
                                                id="img_comp-iruwiqnd" class="HlRz5e BI8PVQ"><img
                                                  src="/assets/d0220c_a7f38a088979498b9254e7a1c6ccac1d~mv2.png"
                                                  alt=""
                                                  style="width: 239px; height: 188px; object-fit: cover; object-position: 50% 50%;"
                                                  width="239" height="188" loading="lazy" data-ssr-src-done="true"
                                                  fetchpriority="high"></wow-image></div>
                                          </div>
                                          <div id="comp-iruwjbi6" class="MazNVa comp-iruwjbi6 wixui-image">
                                            <div data-testid="linkElement" class="j7pOnl"><wow-image
                                                id="img_comp-iruwjbi6" class="HlRz5e BI8PVQ"><img
                                                  src="/assets/d0220c_6140a2819d134cc28245da959cd2336b~mv2.png"
                                                  alt=""
                                                  style="width: 239px; height: 188px; object-fit: cover; object-position: 50% 50%;"
                                                  width="239" height="188" loading="lazy" data-ssr-src-done="true"
                                                  fetchpriority="high"></wow-image></div>
                                          </div>
                                          <div id="comp-isejjlss" class="comp-isejjlss wixui-vector-image">
                                            <div data-testid="svgRoot-comp-isejjlss"
                                              class="AKxYR5 VZYmYf comp-isejjlss"><svg
                                                preserveAspectRatio="xMidYMid meet"
                                                data-bbox="5.137 5.637 188.725 188.725" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="5.137 5.637 188.725 188.725"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M149.8,80l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3s-21.9-8.3-30.3,0l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3
		S141.4,88.3,149.8,80z"></path>
                                                  <path class="st0" d="M49.2,120l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3s21.9,8.3,30.3,0l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3
		S57.6,111.7,49.2,120z"></path>
                                                  <path class="st0" d="M149.8,120c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3l37.8,37.8c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3
		L149.8,120z"></path>
                                                  <path class="st0" d="M41.7,12c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3L49.2,80c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3L41.7,12
		z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-isehzfd9" class="comp-isehzfd9 wixui-vector-image">
                                            <div data-testid="svgRoot-comp-isehzfd9"
                                              class="AKxYR5 VZYmYf comp-isehzfd9"><svg
                                                preserveAspectRatio="xMidYMid meet"
                                                data-bbox="2.075 2.275 195.95 195.588" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="2.075 2.275 195.95 195.588"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M188.5,11.8c-12.7-12.7-33.4-12.7-46.2,0c-10.1,10.1-12.2,25.3-6.2,37.5L49.4,136c-12.2-6.2-27.6-4.2-37.8,6.1
	c-12.7,12.7-12.7,33.4,0,46.2s33.4,12.7,46.2,0c10.2-10.2,12.2-25.6,6-37.9l86.5-86.5c12.3,6.4,27.8,4.5,38.2-5.9
	C201.2,45.2,201.2,24.6,188.5,11.8z M43.4,173.8c-4.8,4.8-12.6,4.8-17.4,0c-4.8-4.8-4.8-12.6,0-17.4c4.8-4.8,12.6-4.8,17.4,0
	C48.3,161.2,48.3,169,43.4,173.8z M174.1,43.6c-4.8,4.8-12.6,4.8-17.4,0c-4.8-4.8-4.8-12.6,0-17.4c4.8-4.8,12.6-4.8,17.4,0
	C178.9,31,178.9,38.8,174.1,43.6z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-irqduxb1"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxb1 wixui-rich-text"
                                            data-testid="richTextElement">
                                            <h4 class="font_4 wixui-rich-text__text" style="font-size:40px;">Real-time
                                              Alerts</h4>
                                          </div>
                                          <div id="comp-irqduxb4"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxb4 wixui-rich-text"
                                            data-testid="richTextElement">
                                            <p class="font_8 wixui-rich-text__text"
                                              style="line-height:2em; font-size:16px;">Get real-time alerts directly on
                                              your WhatsApp based on your customized criteria.</p>
                                          </div>
                                          <div id="comp-iruxih60" class="MazNVa comp-iruxih60 wixui-image">
                                            <div data-testid="linkElement" class="j7pOnl"><wow-image
                                                id="img_comp-iruxih60" class="HlRz5e BI8PVQ"><img
                                                  src="/assets/d0220c_4e64d7e1227d4327a88458c858facae7~mv2.png"
                                                  alt=""
                                                  style="width: 35px; height: 32px; object-fit: cover; object-position: 50% 50%;"
                                                  width="35" height="32" loading="lazy" data-ssr-src-done="true"
                                                  fetchpriority="high"></wow-image></div>
                                          </div>
                                          <div id="comp-irqduxbr"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxbr wixui-rich-text"
                                            data-testid="richTextElement">
                                            <h4 class="font_4 wixui-rich-text__text" style="font-size:40px;">End-of-day
                                              Reports</h4>
                                          </div>
                                          <div id="comp-irsw090n"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irsw090n wixui-rich-text"
                                            data-testid="richTextElement">
                                            <p class="font_8 wixui-rich-text__text"
                                              style="line-height:2em; font-size:16px;">Get a summary of the day's stock
                                              activity delivered to your WhatsApp at the end of the day.</p>
                                          </div>
                                          <div id="comp-isejkuti" class="comp-isejkuti wixui-vector-image">
                                            <div data-testid="svgRoot-comp-isejkuti"
                                              class="AKxYR5 VZYmYf comp-isejkuti"><svg
                                                preserveAspectRatio="xMidYMid meet"
                                                data-bbox="5.137 5.637 188.725 188.725" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="5.137 5.637 188.725 188.725"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M149.8,80l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3s-21.9-8.3-30.3,0l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3
		S141.4,88.3,149.8,80z"></path>
                                                  <path class="st0" d="M49.2,120l-37.8,37.8c-8.3,8.3-8.3,21.9,0,30.3s21.9,8.3,30.3,0l37.8-37.8c8.3-8.3,8.3-21.9,0-30.3
		S57.6,111.7,49.2,120z"></path>
                                                  <path class="st0" d="M149.8,120c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3l37.8,37.8c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3
		L149.8,120z"></path>
                                                  <path class="st0" d="M41.7,12c-8.3-8.3-21.9-8.3-30.3,0s-8.3,21.9,0,30.3L49.2,80c8.3,8.3,21.9,8.3,30.3,0s8.3-21.9,0-30.3L41.7,12
		z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-iruxgy2h" class="MazNVa comp-iruxgy2h wixui-image">
                                            <div data-testid="linkElement" class="j7pOnl"><wow-image
                                                id="img_comp-iruxgy2h" class="HlRz5e BI8PVQ"><img
                                                  src="/assets/d0220c_e3bf6981882c401eb9faabbc11062691~mv2.png"
                                                  alt=""
                                                  style="width: 318px; height: 338px; object-fit: cover; object-position: 50% 50%;"
                                                  width="318" height="338" loading="lazy" data-ssr-src-done="true"
                                                  fetchpriority="high"></wow-image></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </section>
                          <section id="comp-lq9dpgvn" tabindex="-1" class="Oqnisf comp-lq9dpgvn wixui-section"
                            data-block-level-container="ClassicSection">
                            <div id="bgLayers_comp-lq9dpgvn" data-hook="bgLayers" data-motion-part="BG_LAYER"
                              class="MW5IWV">
                              <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                              <div id="bgMedia_comp-lq9dpgvn" data-motion-part="BG_MEDIA" class="VgO9Yg"></div>
                            </div>
                            <div data-mesh-id="comp-lq9dpgvninlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-lq9dpgvninlineContent-gridContainer"
                                data-testid="mesh-container-content">
                                <div id="comp-irqduxhq" class="comp-irqduxhq mDzRgi ENJ6Ca ignore-focus wixui-slideshow"
                                  role="region" tabindex="-1" aria-label="Slideshow">
                                  <div data-testid="slidesWrapper" aria-live="off" class="hDJzl4">
                                    <div id="comp-irqduxhy" class="comp-irqduxhy imK94d">
                                      <div id="bgLayers_comp-irqduxhy" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV eF_jBL">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                        <div id="bgMedia_comp-irqduxhy" data-motion-part="BG_MEDIA" class="VgO9Yg">
                                        </div>
                                      </div>
                                      <div class="wfm0FO"></div>
                                      <div data-mesh-id="comp-irqduxhyinlineContent" data-testid="inline-content"
                                        class="">
                                        <div data-mesh-id="comp-irqduxhyinlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-isen9lv3" class="comp-isen9lv3 wixui-vector-image">
                                            <div data-testid="svgRoot-comp-isen9lv3"
                                              class="AKxYR5 VZYmYf comp-isen9lv3"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="5.1 5.1 189.8 189.8"
                                                version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="5.1 5.1 189.8 189.8" role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M100,5.1C47.6,5.1,5.1,47.6,5.1,100c0,52.4,42.5,94.9,94.9,94.9c52.4,0,94.9-42.5,94.9-94.9
	C194.9,47.6,152.4,5.1,100,5.1z M100,160.4c-33.3,0-60.4-27-60.4-60.4c0-33.3,27-60.4,60.4-60.4c33.3,0,60.4,27,60.4,60.4
	C160.4,133.3,133.3,160.4,100,160.4z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-irqduxi8"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxi8 wixui-rich-text"
                                            data-testid="richTextElement">
                                            <h6 class="font_6 wixui-rich-text__text"
                                              style="text-align:center; font-size:18px;"><span
                                                class="color_36 wixui-rich-text__text">The Economic Times</span></h6>
                                          </div>
                                          <div data-mesh-id="comp-isenegh8-rotated-wrapper">
                                            <div id="comp-isenegh8" class="comp-isenegh8 wixui-vector-image">
                                              <div data-testid="svgRoot-comp-isenegh8"
                                                class="AKxYR5 VZYmYf comp-isenegh8"><svg
                                                  preserveAspectRatio="xMidYMid meet"
                                                  data-bbox="2.075 2.275 195.95 195.588" version="1.1"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="2.075 2.275 195.95 195.588" role="presentation"
                                                  aria-hidden="true">
                                                  <g>
                                                    <path class="st0" d="M188.5,11.8c-12.7-12.7-33.4-12.7-46.2,0c-10.1,10.1-12.2,25.3-6.2,37.5L49.4,136c-12.2-6.2-27.6-4.2-37.8,6.1
	c-12.7,12.7-12.7,33.4,0,46.2s33.4,12.7,46.2,0c10.2-10.2,12.2-25.6,6-37.9l86.5-86.5c12.3,6.4,27.8,4.5,38.2-5.9
	C201.2,45.2,201.2,24.6,188.5,11.8z M43.4,173.8c-4.8,4.8-12.6,4.8-17.4,0c-4.8-4.8-4.8-12.6,0-17.4c4.8-4.8,12.6-4.8,17.4,0
	C48.3,161.2,48.3,169,43.4,173.8z M174.1,43.6c-4.8,4.8-12.6,4.8-17.4,0c-4.8-4.8-4.8-12.6,0-17.4c4.8-4.8,12.6-4.8,17.4,0
	C178.9,31,178.9,38.8,174.1,43.6z"></path>
                                                  </g>
                                                </svg></div>
                                            </div>
                                          </div>
                                          <div id="comp-isenkhwl" class="comp-isenkhwl wixui-vector-image">
                                            <div data-testid="svgRoot-comp-isenkhwl"
                                              class="AKxYR5 VZYmYf comp-isenkhwl"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="5.1 5.1 189.8 189.8"
                                                version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="5.1 5.1 189.8 189.8" role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M100,5.1C47.6,5.1,5.1,47.6,5.1,100c0,52.4,42.5,94.9,94.9,94.9c52.4,0,94.9-42.5,94.9-94.9
	C194.9,47.6,152.4,5.1,100,5.1z M100,160.4c-33.3,0-60.4-27-60.4-60.4c0-33.3,27-60.4,60.4-60.4c33.3,0,60.4,27,60.4,60.4
	C160.4,133.3,133.3,160.4,100,160.4z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-irqduxie" class="comp-irqduxie aVng1S wixui-horizontal-line">
                                          </div>
                                          <div data-mesh-id="comp-isenckdb-rotated-wrapper">
                                            <div id="comp-isenckdb" class="comp-isenckdb wixui-vector-image">
                                              <div data-testid="svgRoot-comp-isenckdb"
                                                class="AKxYR5 VZYmYf comp-isenckdb"><svg
                                                  preserveAspectRatio="xMidYMid meet"
                                                  data-bbox="2.075 2.275 195.95 195.588" version="1.1"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="2.075 2.275 195.95 195.588" role="presentation"
                                                  aria-hidden="true">
                                                  <g>
                                                    <path class="st0" d="M188.5,11.8c-12.7-12.7-33.4-12.7-46.2,0c-10.1,10.1-12.2,25.3-6.2,37.5L49.4,136c-12.2-6.2-27.6-4.2-37.8,6.1
	c-12.7,12.7-12.7,33.4,0,46.2s33.4,12.7,46.2,0c10.2-10.2,12.2-25.6,6-37.9l86.5-86.5c12.3,6.4,27.8,4.5,38.2-5.9
	C201.2,45.2,201.2,24.6,188.5,11.8z M43.4,173.8c-4.8,4.8-12.6,4.8-17.4,0c-4.8-4.8-4.8-12.6,0-17.4c4.8-4.8,12.6-4.8,17.4,0
	C48.3,161.2,48.3,169,43.4,173.8z M174.1,43.6c-4.8,4.8-12.6,4.8-17.4,0c-4.8-4.8-4.8-12.6,0-17.4c4.8-4.8,12.6-4.8,17.4,0
	C178.9,31,178.9,38.8,174.1,43.6z"></path>
                                                  </g>
                                                </svg></div>
                                            </div>
                                          </div>
                                          <div id="comp-irqduxi4"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxi4 wixui-rich-text"
                                            data-testid="richTextElement">
                                            <h3 class="font_3 wixui-rich-text__text"
                                              style="line-height:1.4em; text-align:center; font-size:55px;"><span
                                                class="color_36 wixui-rich-text__text">"The best stock alert service in
                                                the market"</span></h3>
                                          </div>
                                          <div data-mesh-id="comp-isehzfde-rotated-wrapper">
                                            <div id="comp-isehzfde" class="comp-isehzfde wixui-vector-image">
                                              <div data-testid="svgRoot-comp-isehzfde"
                                                class="AKxYR5 VZYmYf comp-isehzfde"><svg
                                                  preserveAspectRatio="xMidYMid meet" data-bbox="7.5 4.6 184.938 190.8"
                                                  version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="7.5 4.6 184.938 190.8" role="presentation"
                                                  aria-hidden="true">
                                                  <g>
                                                    <path class="st0" d="M187.7,34.3c-5-5-12.6-6-18.6-3c-17.9-17.2-41.2-26.7-66.1-26.7C50.3,4.6,7.5,47.4,7.5,100
	c0,22.6,8.1,44.6,22.7,61.8c18.2,21.4,44.7,33.6,72.7,33.6c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2c-25,0-48.6-10.9-64.7-29.9
	c-13-15.3-20.2-34.9-20.2-55c0-46.9,38.1-85,85-85c22.2,0,42.9,8.4,58.8,23.6c-2.9,6-1.9,13.5,3.1,18.5c6.3,6.3,16.6,6.3,22.9,0
	C194,50.9,194,40.6,187.7,34.3z M180.6,50.1c-2.4,2.4-6.3,2.4-8.6,0c-2.4-2.4-2.4-6.3,0-8.6c2.4-2.4,6.3-2.4,8.6,0
	C183,43.8,183,47.7,180.6,50.1z"></path>
                                                  </g>
                                                </svg></div>
                                            </div>
                                          </div>
                                          <div data-mesh-id="comp-isenm3m4-rotated-wrapper">
                                            <div id="comp-isenm3m4" class="comp-isenm3m4 wixui-vector-image">
                                              <div data-testid="svgRoot-comp-isenm3m4"
                                                class="AKxYR5 VZYmYf comp-isenm3m4"><svg
                                                  preserveAspectRatio="xMidYMid meet" data-bbox="7.5 4.6 184.938 190.8"
                                                  version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="7.5 4.6 184.938 190.8" role="presentation"
                                                  aria-hidden="true">
                                                  <g>
                                                    <path class="st0" d="M187.7,34.3c-5-5-12.6-6-18.6-3c-17.9-17.2-41.2-26.7-66.1-26.7C50.3,4.6,7.5,47.4,7.5,100
	c0,22.6,8.1,44.6,22.7,61.8c18.2,21.4,44.7,33.6,72.7,33.6c2.9,0,5.2-2.3,5.2-5.2s-2.3-5.2-5.2-5.2c-25,0-48.6-10.9-64.7-29.9
	c-13-15.3-20.2-34.9-20.2-55c0-46.9,38.1-85,85-85c22.2,0,42.9,8.4,58.8,23.6c-2.9,6-1.9,13.5,3.1,18.5c6.3,6.3,16.6,6.3,22.9,0
	C194,50.9,194,40.6,187.7,34.3z M180.6,50.1c-2.4,2.4-6.3,2.4-8.6,0c-2.4-2.4-2.4-6.3,0-8.6c2.4-2.4,6.3-2.4,8.6,0
	C183,43.8,183,47.7,180.6,50.1z"></path>
                                                  </g>
                                                </svg></div>
                                            </div>
                                          </div>
                                          <div id="comp-isenexd1" class="comp-isenexd1 wixui-vector-image">
                                            <div data-testid="svgRoot-comp-isenexd1"
                                              class="AKxYR5 VZYmYf comp-isenexd1"><svg
                                                preserveAspectRatio="xMidYMid meet"
                                                data-bbox="2.075 2.275 195.95 195.588" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="2.075 2.275 195.95 195.588"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M188.5,11.8c-12.7-12.7-33.4-12.7-46.2,0c-10.1,10.1-12.2,25.3-6.2,37.5L49.4,136c-12.2-6.2-27.6-4.2-37.8,6.1
	c-12.7,12.7-12.7,33.4,0,46.2s33.4,12.7,46.2,0c10.2-10.2,12.2-25.6,6-37.9l86.5-86.5c12.3,6.4,27.8,4.5,38.2-5.9
	C201.2,45.2,201.2,24.6,188.5,11.8z M43.4,173.8c-4.8,4.8-12.6,4.8-17.4,0c-4.8-4.8-4.8-12.6,0-17.4c4.8-4.8,12.6-4.8,17.4,0
	C48.3,161.2,48.3,169,43.4,173.8z M174.1,43.6c-4.8,4.8-12.6,4.8-17.4,0c-4.8-4.8-4.8-12.6,0-17.4c4.8-4.8,12.6-4.8,17.4,0
	C178.9,31,178.9,38.8,174.1,43.6z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                          <div id="comp-iseng1vk" class="comp-iseng1vk wixui-vector-image">
                                            <div data-testid="svgRoot-comp-iseng1vk"
                                              class="AKxYR5 VZYmYf comp-iseng1vk"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="5.1 5.1 189.8 189.8"
                                                version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="5.1 5.1 189.8 189.8" role="presentation" aria-hidden="true">
                                                <g>
                                                  <path class="st0" d="M100,5.1C47.6,5.1,5.1,47.6,5.1,100c0,52.4,42.5,94.9,94.9,94.9c52.4,0,94.9-42.5,94.9-94.9
	C194.9,47.6,152.4,5.1,100,5.1z M100,160.4c-33.3,0-60.4-27-60.4-60.4c0-33.3,27-60.4,60.4-60.4c33.3,0,60.4,27,60.4,60.4
	C160.4,133.3,133.3,160.4,100,160.4z"></path>
                                                </g>
                                              </svg></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section id="comp-lq9dpgvo" tabindex="-1" class="Oqnisf comp-lq9dpgvo wixui-section"
                            data-block-level-container="ClassicSection">
                            <div id="bgLayers_comp-lq9dpgvo" data-hook="bgLayers" data-motion-part="BG_LAYER"
                              class="MW5IWV">
                              <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                              <div id="bgMedia_comp-lq9dpgvo" data-motion-part="BG_MEDIA" class="VgO9Yg"></div>
                            </div>
                            <div data-mesh-id="comp-lq9dpgvoinlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-lq9dpgvoinlineContent-gridContainer"
                                data-testid="mesh-container-content">
                                <section id="comp-irtm47ta" class="comp-irtm47ta CohWsy wixui-column-strip">
                                  <div id="bgLayers_comp-irtm47ta" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                    class="if7Vw2">
                                    <div data-testid="colorUnderlay" class="tcElKx i1tH8h"></div>
                                    <div id="bgMedia_comp-irtm47ta" data-motion-part="BG_MEDIA" class="wG8dni"></div>
                                  </div>
                                  <div data-testid="columns" class="V5AUxf">
                                    <div id="comp-irtm47to" class="comp-irtm47to YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_comp-irtm47to" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                        <div id="bgMedia_comp-irtm47to" data-motion-part="BG_MEDIA" class="VgO9Yg">
                                        </div>
                                      </div>
                                      <div data-mesh-id="comp-irtm47toinlineContent" data-testid="inline-content"
                                        class="">
                                        <div data-mesh-id="comp-irtm47toinlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-irtm47u3" class="MazNVa comp-irtm47u3 wixui-image"
                                            data-motion-enter="done">
                                            <div data-testid="linkElement" class="j7pOnl"><wow-image
                                                id="img_comp-irtm47u3" class="HlRz5e BI8PVQ"><img
                                                  src="/assets/d0220c_873ca1e0908148c684170a3b92d1adec~mv2.png"
                                                  alt=""
                                                  style="width: 96px; height: 103px; object-fit: contain; object-position: center center;"
                                                  width="96" height="103" loading="lazy" data-ssr-src-done="true"
                                                  fetchpriority="high"></wow-image></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="comp-irtm4h7g" class="comp-irtm4h7g YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_comp-irtm4h7g" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                        <div id="bgMedia_comp-irtm4h7g" data-motion-part="BG_MEDIA" class="VgO9Yg">
                                        </div>
                                      </div>
                                      <div data-mesh-id="comp-irtm4h7ginlineContent" data-testid="inline-content"
                                        class="">
                                        <div data-mesh-id="comp-irtm4h7ginlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-irtm4h7s" class="MazNVa comp-irtm4h7s wixui-image"
                                            data-motion-enter="done">
                                            <div data-testid="linkElement" class="j7pOnl"><wow-image
                                                id="img_comp-irtm4h7s" class="HlRz5e BI8PVQ"><img
                                                  src="/assets/d0220c_ade649a955f948dd80fab6734006f19c~mv2.png"
                                                  alt=""
                                                  style="width: 150px; height: 73px; object-fit: contain; object-position: center center;"
                                                  width="150" height="73" loading="lazy" data-ssr-src-done="true"
                                                  fetchpriority="high"></wow-image></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="comp-irtm47uh" class="comp-irtm47uh YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_comp-irtm47uh" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                        <div id="bgMedia_comp-irtm47uh" data-motion-part="BG_MEDIA" class="VgO9Yg">
                                        </div>
                                      </div>
                                      <div data-mesh-id="comp-irtm47uhinlineContent" data-testid="inline-content"
                                        class="">
                                        <div data-mesh-id="comp-irtm47uhinlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-irtm47us" class="MazNVa comp-irtm47us wixui-image"
                                            data-motion-enter="done">
                                            <div data-testid="linkElement" class="j7pOnl"><wow-image
                                                id="img_comp-irtm47us" class="HlRz5e BI8PVQ"><img
                                                  src="/assets/d0220c_f6170c99a84044fe9e84317826be74bf~mv2.png"
                                                  alt=""
                                                  style="width: 102px; height: 102px; object-fit: contain; object-position: center center;"
                                                  width="102" height="102" loading="lazy" data-ssr-src-done="true"
                                                  fetchpriority="high"></wow-image></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="comp-irtm47v3" class="comp-irtm47v3 YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_comp-irtm47v3" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                        <div id="bgMedia_comp-irtm47v3" data-motion-part="BG_MEDIA" class="VgO9Yg">
                                        </div>
                                      </div>
                                      <div data-mesh-id="comp-irtm47v3inlineContent" data-testid="inline-content"
                                        class="">
                                        <div data-mesh-id="comp-irtm47v3inlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-irtm47vc" class="MazNVa comp-irtm47vc wixui-image"
                                            data-motion-enter="done">
                                            <div data-testid="linkElement" class="j7pOnl"><wow-image
                                                id="img_comp-irtm47vc" class="HlRz5e BI8PVQ"><img
                                                  src="/assets/d0220c_8e89205ada5b4e298f93b39843a49e0d~mv2.png"
                                                  alt=""
                                                  style="width: 150px; height: 69px; object-fit: contain; object-position: center center;"
                                                  width="150" height="69" loading="lazy" data-ssr-src-done="true"
                                                  fetchpriority="high"></wow-image></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="comp-irtm47vo" class="comp-irtm47vo YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_comp-irtm47vo" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                                        <div id="bgMedia_comp-irtm47vo" data-motion-part="BG_MEDIA" class="VgO9Yg">
                                        </div>
                                      </div>
                                      <div data-mesh-id="comp-irtm47voinlineContent" data-testid="inline-content"
                                        class="">
                                        <div data-mesh-id="comp-irtm47voinlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-irtm47w0" class="MazNVa comp-irtm47w0 wixui-image"
                                            data-motion-enter="done">
                                            <div data-testid="linkElement" class="j7pOnl"><wow-image
                                                id="img_comp-irtm47w0" class="HlRz5e BI8PVQ"><img
                                                  src="/assets/d0220c_0f50074c127141a493e18f2f7feb5044~mv2.png"
                                                  alt=""
                                                  style="width: 102px; height: 101px; object-fit: contain; object-position: center center;"
                                                  width="102" height="101" loading="lazy" data-ssr-src-done="true"
                                                  fetchpriority="high"></wow-image></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </section>
                          <section id="comp-lq9dpgvp" tabindex="-1" class="Oqnisf comp-lq9dpgvp wixui-section"
                            data-block-level-container="ClassicSection">
                            <div id="bgLayers_comp-lq9dpgvp" data-hook="bgLayers" data-motion-part="BG_LAYER"
                              class="MW5IWV">
                              <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div>
                              <div id="bgMedia_comp-lq9dpgvp" data-motion-part="BG_MEDIA" class="VgO9Yg"></div>
                            </div>
                            <div data-mesh-id="comp-lq9dpgvpinlineContent" data-testid="inline-content" class="">
                              <div data-mesh-id="comp-lq9dpgvpinlineContent-gridContainer"
                                data-testid="mesh-container-content">
                                <section id="comp-irujafzq" class="comp-irujafzq CohWsy wixui-column-strip">
                                  <div id="bgLayers_comp-irujafzq" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                    class="if7Vw2">
                                    <div data-testid="colorUnderlay" class="tcElKx i1tH8h"></div>
                                    <div id="bgMedia_comp-irujafzq" data-motion-part="BG_MEDIA" class="wG8dni"></div>
                                  </div>
                                  <div data-testid="columns" class="V5AUxf">
                                    <div id="mediairukrt4y" class="mediairukrt4y YzqVVZ wixui-column-strip__column">
                                      <div id="bgLayers_mediairukrt4y" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                        class="MW5IWV N3eg0s">
                                        <div data-testid="colorUnderlay" class="LWbAav Kv1aVt"></div><wix-bg-media
                                          id="bgMedia_mediairukrt4y" class="SUz0WK" data-container-id="mediairukrt4y"
                                          data-container-size="0, 0" data-page-id="gkbn7"
                                          data-bg-effect-name="BgParallax" data-motion-part="BG_MEDIA"
                                          style="width: 1512px; height: 899px; left: 0px; top: 0px; will-change: transform; transform: translate3d(0px, 142.6px, 0px);"><wow-image
                                            id="img_mediairukrt4y" class="HlRz5e Kv1aVt dLPlxY mNGsUM bgImage"><img
                                              src="/assets/d0220c_740c7c5b57fa4adab3e6924433541cbb~mv2_d_5500_3671_s_4_2.jpg"
                                              alt=""
                                              style="width: 1512px; height: 899px; object-fit: cover; object-position: 50% 50%;"
                                              width="980" height="899" data-ssr-src-done="true"
                                              fetchpriority="high"></wow-image></wix-bg-media>
                                      </div>
                                      <div data-mesh-id="mediairukrt4yinlineContent" data-testid="inline-content"
                                        class="mwF7X1">
                                        <div data-mesh-id="mediairukrt4yinlineContent-gridContainer"
                                          data-testid="mesh-container-content">
                                          <div id="comp-irtlor95" class="comp-irtlor95 wixui-vector-image"
                                            data-motion-enter="done">
                                            <div data-testid="svgRoot-comp-irtlor95"
                                              class="AKxYR5 VZYmYf comp-irtlor95"><svg
                                                preserveAspectRatio="xMidYMid meet" data-bbox="1.9 1.9 56.2 56.2"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="1.9 1.9 56.2 56.2"
                                                role="presentation" aria-hidden="true">
                                                <g>
                                                  <path
                                                    d="M30 1.9C14.5 1.9 1.9 14.5 1.9 30c0 15.5 12.6 28.1 28.1 28.1S58.1 45.5 58.1 30C58.1 14.5 45.5 1.9 30 1.9zm0 44c-8.8 0-15.9-7.1-15.9-15.9S21.2 14.1 30 14.1 45.9 21.2 45.9 30 38.8 45.9 30 45.9z">
                                                  </path>
                                                </g>
                                              </svg>
                                            </div>
                                          </div>
                                          <div id="comp-irsvbur1"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irsvbur1 wixui-rich-text"
                                            data-testid="richTextElement" data-motion-enter="done">
                                            <h5 class="font_5 wixui-rich-text__text" style="font-size:20px;"><span
                                                class="color_18 wixui-rich-text__text">Get in Touch</span></h5>
                                          </div>
                                          <div id="comp-irtkud8z"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irtkud8z wixui-rich-text"
                                            data-testid="richTextElement" data-motion-enter="done">
                                            <h4 class="font_4 wixui-rich-text__text"
                                              style="line-height:1.4em; font-size:40px;"><span
                                                class="color_36 wixui-rich-text__text">To learn more about our services,
                                                please fill in your contact details.</span></h4>
                                          </div>
                                          <div id="comp-jxad0a05" class="bkIuWA comp-jxad0a05">
                                            <div data-mesh-id="comp-jxad0a05inlineContent" data-testid="inline-content"
                                              class="">
                                              <div data-mesh-id="comp-jxad0a05inlineContent-gridContainer"
                                                data-testid="mesh-container-content">
                                                <form id="comp-jxad0a2p" class="JVi7i2 comp-jxad0a2p wixui-form">
                                                  <div data-mesh-id="comp-jxad0a2pinlineContent"
                                                    data-testid="inline-content" class="">
                                                    <div data-mesh-id="comp-jxad0a2pinlineContent-gridContainer"
                                                      data-testid="mesh-container-content">
                                                      <div id="comp-jxad0a3m"
                                                        class="MpKiNN comp-jxad0a3m wixui-text-input">
                                                        <div class="pUnTVX"><input name="name" id="input_comp-jxad0a3m"
                                                            class="KvoMHf has-custom-focus wixui-text-input__input"
                                                            type="text" placeholder="Name" required=""
                                                            aria-required="true" aria-invalid="true" maxlength="100"
                                                            autocomplete="off" aria-label="Name" value=""></div>
                                                      </div>
                                                      <div id="comp-jxad0a4e"
                                                        class="MpKiNN comp-jxad0a4e wixui-text-input lPl_oN">
                                                        <div class="pUnTVX"><input name="email" id="input_comp-jxad0a4e"
                                                            class="KvoMHf has-custom-focus wixui-text-input__input"
                                                            type="email" placeholder="Email" required=""
                                                            aria-required="true" aria-invalid="true" maxlength="250"
                                                            autocomplete="off" aria-label="Email" value=""></div>
                                                      </div>
                                                      <div id="comp-jxad0a4w"
                                                        class="snt4Te comp-jxad0a4w wixui-text-box NyPO4H"><label
                                                          for="textarea_comp-jxad0a4w"
                                                          class="PSkPrR wixui-text-box__label"></label><textarea
                                                          id="textarea_comp-jxad0a4w"
                                                          class="rEindN has-custom-focus wixui-text-box__input"
                                                          placeholder="Type your message here..." required=""
                                                          aria-required="true" aria-invalid="true"></textarea></div>
                                                      <div class="comp-jxad0a5m FubTgk" id="comp-jxad0a5m"
                                                        aria-disabled="false"><button aria-disabled="false"
                                                          data-testid="buttonElement"
                                                          class="uDW_Qe wixui-button PlZyDq"><span
                                                            class="l7_2fn wixui-button__label">Submit</span></button>
                                                      </div>
                                                      <div id="comp-jxad0a66"
                                                        class="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxad0a66 wixui-rich-text"
                                                        data-testid="richTextElement">
                                                        <p class="font_8 wixui-rich-text__text"
                                                          style="text-align:center; font-size:15px;"><span
                                                            style="font-size:15px;" class="wixui-rich-text__text"><span
                                                              class="color_36 wixui-rich-text__text">Thank
                                                              You</span></span></p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                          <div id="comp-iruuh2gy"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-iruuh2gy wixui-rich-text"
                                            data-testid="richTextElement">
                                            <p class="font_8 wixui-rich-text__text"
                                              style="line-height:2em; text-align:center; font-size:16px;"><span
                                                style="font-family:raleway,sans-serif;"
                                                class="wixui-rich-text__text"><span
                                                  class="color_36 wixui-rich-text__text"><a
                                                    href="mailto:info@stockalert.co.in" target="_self"
                                                    class="wixui-rich-text__text">info@stockalert.co.in</a></span></span>
                                            </p>
                                          </div>
                                          <div data-mesh-id="comp-iruuj3h0-rotated-wrapper">
                                            <div id="comp-iruuj3h0" class="comp-iruuj3h0 aVng1S wixui-horizontal-line">
                                            </div>
                                          </div>
                                          <div id="comp-iruuhetc"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-iruuhetc wixui-rich-text"
                                            data-testid="richTextElement">
                                            <p class="font_8 wixui-rich-text__text"
                                              style="line-height:2em; text-align:center; font-size:16px;"><span
                                                style="font-family:raleway,sans-serif;"
                                                class="wixui-rich-text__text"><span
                                                  class="color_36 wixui-rich-text__text">Tel:
                                                  +91-63529-98664</span></span></p>
                                          </div>
                                          <div data-mesh-id="comp-iruuk73j-rotated-wrapper">
                                            <div id="comp-iruuk73j" class="comp-iruuk73j aVng1S wixui-horizontal-line">
                                            </div>
                                          </div>
                                          <div id="comp-iruuhpti"
                                            class="HcOXKn SxM0TO QxJLC3 lq2cno comp-iruuhpti wixui-rich-text"
                                            data-testid="richTextElement">
                                            <p class="font_8 wixui-rich-text__text"
                                              style="line-height:2em; text-align:center; font-size:16px;"><span
                                                style="font-family:raleway,sans-serif;"
                                                class="wixui-rich-text__text"><span
                                                  class="color_36 wixui-rich-text__text">Stock Alert, Ahmedabad,
                                                  India</span></span></p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                    <div id="pagePinnedBottomRight-gkbn7" class="pagePinnedBottomRight-gkbn7">
                      <div id="comp-irsxmitt-pinned-layer" class="comp-irsxmitt-pinned-layer big2ZD">
                        <div id="comp-irsxmitt" class="vsejaj comp-irsxmitt">
                          <div class="T6TiIN wixui-box" data-testid="container-bg"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <div id="pinnedBottomLeft" class="pinnedBottomLeft">
            <div id="comp-jqqt3y1u-pinned-layer" class="comp-jqqt3y1u-pinned-layer big2ZD">
              <div id="comp-jqqt3y1u" class="OQ8Tzd comp-jqqt3y1u"
                style="width: 230px; height: 66px; content: attr(x);"></div>
            </div>
          </div>
          <footer id="SITE_FOOTER" class="xU8fqS SITE_FOOTER wixui-footer" tabindex="-1">
            <div class="_C0cVf">
              <div class="_4XcTfy" data-testid="screenWidthContainerBg"></div>
            </div>
            <div class="U4Bvut">
              <div class="G5K6X8">
                <div class="gUbusX" data-testid="screenWidthContainerBgCenter"></div>
              </div>
              <div class="CJF7A2">
                <div data-mesh-id="SITE_FOOTERinlineContent" data-testid="inline-content" class="">
                  <div data-mesh-id="SITE_FOOTERinlineContent-gridContainer" data-testid="mesh-container-content">
                    <div id="comp-iruti9mw" class="comp-iruti9mw WzbAF8">
                      <ul class="mpGTIt" aria-label="Social Bar">
                        <li id="dataItem-iruti9o2-comp-iruti9mw" class="O6KwRn"><a data-testid="linkElement"
                            href="https://www.facebook.com" target="_blank" rel="noreferrer noopener" class="oRtuWN"
                            aria-label="Facebook"><wow-image id="img_0_comp-iruti9mw" class="HlRz5e YaS0jR"
                              style="--wix-img-max-width:max(200px, 100%)"><img alt="Facebook" data-ssr-src-done="true"
                                fetchpriority="high"
                                src="/assets/23fd2a2be53141ed810f4d3dcdcd01fa.png"
                                style="width: 24px; height: 24px; object-fit: cover;"></wow-image></a></li>
                        <li id="dataItem-iruti9o41-comp-iruti9mw" class="O6KwRn"><a data-testid="linkElement"
                            href="https://www.twitter.com" target="_blank" rel="noreferrer noopener" class="oRtuWN"
                            aria-label="Twitter"><wow-image id="img_1_comp-iruti9mw" class="HlRz5e YaS0jR"
                              style="--wix-img-max-width:max(200px, 100%)"><img alt="Twitter" data-ssr-src-done="true"
                                fetchpriority="high"
                                src="/assets/01ab6619093f45388d66736ec22e5885.png"
                                style="width: 24px; height: 24px; object-fit: cover;"></wow-image></a></li>
                        <li id="dataItem-iruti9o42-comp-iruti9mw" class="O6KwRn"><a data-testid="linkElement"
                            href="https://instagram.com" target="_blank" rel="noreferrer noopener" class="oRtuWN"
                            aria-label="Instagram"><wow-image id="img_2_comp-iruti9mw" class="HlRz5e YaS0jR"
                              style="--wix-img-max-width:max(200px, 100%)"><img alt="Instagram" data-ssr-src-done="true"
                                fetchpriority="high"
                                src="/assets/81af6121f84c41a5b4391d7d37fce12a.png"
                                style="width: 24px; height: 24px; object-fit: cover;"></wow-image></a></li>
                        <li id="dataItem-iruti9o5-comp-iruti9mw" class="O6KwRn"><a data-testid="linkElement"
                            href="https://www.linkedin.com" target="_blank" rel="noreferrer noopener"
                            class="oRtuWN" aria-label="LinkedIn"><wow-image id="img_3_comp-iruti9mw"
                              class="HlRz5e YaS0jR"
                              style="--wix-img-max-width:max(200px, 100%)"><img alt="LinkedIn" data-ssr-src-done="true"
                                fetchpriority="high"
                                src="/assets/7528824071724d12a3e6c31eee0b40d4.png"
                                style="width: 24px; height: 24px; object-fit: cover;"></wow-image></a></li>
                      </ul>
                    </div>
                    <div id="comp-irqduxcc" class="HcOXKn SxM0TO QxJLC3 lq2cno comp-irqduxcc wixui-rich-text"
                      data-testid="richTextElement">
                      <p class="font_9 color_18 wixui-rich-text__text"
                        style="line-height:1.5em; text-align:center; font-size:14px;"><span
                          class="color_18 wixui-rich-text__text">© 2025 by Stock Alert.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div id="tpapopup-1730282414764_rtby_comp-lq9dqai3"
        class="LQz5TQ tpapopup-1730282414764_rtby_comp-lq9dqai3 V7x572"
        style="position: fixed; display: block; width: 0%; height: 100%; margin-left: 0%; margin-top: -50%; left: 50%; top: 50%;">
        <div class="hn0VS1"></div>
      </div>
    </div>
  </div>

</body>

</html>
`