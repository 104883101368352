import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Alert from "@mui/material/Alert";
import { BACKEND_URL } from "../constants";

import { useAuth } from "../AuthContext";
import "./Login.css";

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [validMobile, setValidMobile] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  
  const navigate = useNavigate();
  const { userId, token, user, login } = useAuth();

  useEffect(() => {
    const id = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    if (id !== null && token !== null) {
      login(id, token, user);
    }
  }, [login]);

  useEffect(() => {
    if (userId && token && user) {
      navigate("/home");
    }
  }, [userId, token, user, navigate]);

  const validateMobileNumber = (number) => {
    const isValid = /^[6-9]\d{9}$/.test(number);
    console.log("isValid: ", isValid);
    console.log("mobileNumber: ", number);
    setValidMobile(isValid);
    setMobileError(isValid ? "" : "Please enter a valid mobile number");
  };

  const setNumber = (number) => {
    setMobileNumber(number);
    if (number.length === 10) {
      validateMobileNumber(number);
    }
  };

  const handleGenerateOTP = async () => {
    setLoading(true);
    setError(""); // Reset any previous error
    try {
      // Make API call to generate OTP
      const response = await axios.post(`${BACKEND_URL}/otp/generate`, {
        mobileNumber,
      });
      setShowOtp(true);
      console.log(response.data);
    } catch (error) {
      console.error("Error generating OTP:", error);
      setError("Failed to generate OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      // Make API call to verify OTP
      const response = await axios.post(`${BACKEND_URL}/otp/verify`, {
        mobileNumber,
        otp,
      });
      console.log(response.data.id, response.data.authToken);
      login(response.data.id, response.data.authToken, response.data);
      navigate("/home");
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError("Failed to verify OTP. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2 className="login-title">Login</h2>
        {error && <Alert severity="error">{error}</Alert>}
        <h4 className="login-subtitle">
          <span>
            Enter your active <span className="whatsapp">Whatsapp</span> number{" "}
          </span>
          <WhatsAppIcon className="whatsapp" />
        </h4>
        <TextField
          type="number"
          inputMode="numeric"
          label="Mobile Number"
          variant="filled"
          error={!!mobileError}
          helperText={mobileError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WhatsAppIcon className="whatsapp" />
                +91
              </InputAdornment>
            ),
          }}
          value={mobileNumber}
          onBlur={() => validateMobileNumber(mobileNumber)}
          onChange={(e) => setNumber(e.target.value)}
        />
        <Button
          className="otp-button"
          variant="outlined"
          type="button"
          onClick={handleGenerateOTP}
          endIcon={loading ? <CircularProgress size={20} className="spinner" /> : <SendIcon />}
          disabled={!validMobile || loading}
        >
          {loading ? "Generating OTP..." : "Generate OTP"}
        </Button>
        {showOtp && (
          <>
            <TextField
              className="otp-input"
              type="text"
              inputMode="numeric"
              value={otp}
              label="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
              inputProps={{ maxLength: 6 }}
            />
            <Button
              className="verify-button"
              variant="outlined"
              type="button"
              onClick={handleVerifyOTP}
              disabled={otp.length !== 6}
            >
              Verify OTP
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
