import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { List } from "react-virtualized";
import { BACKEND_URL } from "../constants";
import { useAuth } from "../AuthContext";
import logo from "../assets/logo.jpeg";
import styles from "./Home.module.css";

const Home = () => {
  const [stocks, setStocks] = useState([]);
  const [userStocks, setUserStocks] = useState([]);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailBlurred, setEmailBlurred] = useState(false);
  const { userId, token, loginFromLocalStorage, logout, setUser, user } = useAuth();
  const navigate = useNavigate();

  const tokenExpired = (error) => {
    if (error.response.status === 401) {
      logout();
      navigate("/");
    }
  };

  useEffect(() => {
    if (userId === null || token === null) {
      loginFromLocalStorage();
      return;
    }

    axios
      .get(`${BACKEND_URL}/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const user = response.data;
        setUser(user);
        setUserName(user?.userName || "");
        setEmail(user?.email || "");
      })
      .catch((error) => {
        console.error("Error fetching user");
        if (error.response.status === 401) {
          logout();
          navigate("/");
        }
      });

    axios
      .get(`${BACKEND_URL}/stocks?userId=${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => setStocks(response.data))
      .catch((error) => {
        console.error("Error fetching stocks");
        if (error.response.status === 401) {
          logout();
          navigate("/");
        }
      });

    axios
      .get(`${BACKEND_URL}/users/${userId}/subscriptions`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => setUserStocks(response.data))
      .catch((error) => {
        console.error("Error fetching user subscribed stocks");
        if (error.response.status === 401) {
          logout();
          navigate("/");
        }
      });
  }, [userId, token]);

  const getNameOfStock = (id) => {
    const stock = stocks.find((stock) => stock.id === id);
    return stock
      ? `${stock.stockName}${stock.stockExchange === "NSE" ? "" : " (BSE)"}`
      : "";
  };

  const handleUpdateUserDetails = () => {
    axios
      .put(
        `${BACKEND_URL}/users/${userId}`,
        { name: userName, email },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            userName,
            email,
          })
        );
        console.log("User details updated successfully");
      })
      .catch((error) => {
        console.error("Error updating user details");
        tokenExpired(error);
      });
  };

  const onChange = (event, newValue, reason) => {
    switch (reason) {
      case "selectOption":
        const newOption = newValue.find(
          (option) => !userStocks.includes(option)
        );
        if (newOption) {
          axios
            .post(
              `${BACKEND_URL}/users/${userId}/subscribe/${newOption.id}`,
              {},
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              setUserStocks([...userStocks, newOption.id]);
              console.log("User subscribed to stock successfully");
            })
            .catch((error) => {
              console.error("Error subscribing user to stock:", error);
              tokenExpired(error);
            });
        }
        break;
      case "removeOption":
        const removedOption = userStocks.find(
          (option) => !newValue.includes(option)
        );
        if (removedOption) {
          axios
            .delete(
              `${BACKEND_URL}/users/${userId}/subscribe/${removedOption}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              setUserStocks(
                userStocks.filter((option) => option !== removedOption)
              );
              console.log("User unsubscribed from stock successfully");
            })
            .catch((error) => {
              console.error("Error unsubscribing user from stock:", error);
              tokenExpired(error);
            });
        }
        break;
      default:
        break;
    }
  };

  const refAuto = useRef(null);

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isUpdateDisabled = () => {
    return (
      !isEmailValid(email) ||
      (userName === user?.userName && email === user?.email)
    );
  };

  const handleEmailBlur = () => {
    setEmailBlurred(true);
    setEmailError(isEmailValid(email) ? "" : "Invalid email");
  };

  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, role, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 50;

    return (
      <div ref={ref}>
        <div {...other}>
          <List
            height={250}
            width={refAuto.current.offsetWidth || 300}
            rowHeight={itemSize}
            overscanCount={5}
            rowCount={itemCount}
            rowRenderer={(props) => {
              const { index, style } = props;
              const option = children[index];
              const label = option.props.children;
              return React.cloneElement(option, {
                style: {
                  ...style,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
                children: <div style={{ width: "100%" }}>{label}</div>,
              });
            }}
            role={role}
          />
        </div>
      </div>
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={logo} alt="logo" className={styles.logo} />
      </div>
      <div className={styles.subscription}>
      <h2>Welcome, {user?.userName}</h2>
      <div className={styles.subscriptionType}>
          {user?.subscriptionType === "SUBSCRIBED" ? (
            <span className={`${styles.badge} ${styles.subscribed}`}>Subscribed</span>
          ) : user?.subscriptionType === "PAYMENT_INITIATED" ? (
            <span className={`${styles.badge} ${styles.paymentInitiated}`}>Payment Initiated</span>
          ) : (
            <span className={`${styles.badge} ${styles.free}`}>Free Tier</span>
          )}
          {user?.subscriptionType !== "SUBSCRIBED" && (
            <Button
              className={styles.makePaymentButton}
              onClick={() => navigate("/payment")}
            >
              Make Payment
            </Button>
          )}
        </div>
        <p>Manage your subscriptions and personal details below.</p>
      </div>
      <div className={styles.formContainer}>
        <TextField
          className={styles.textField}
          label="Name"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          variant="outlined"
        />
        <TextField
          className={styles.textField}
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={handleEmailBlur}
          error={emailBlurred && !!emailError}
          helperText={emailBlurred && emailError}
          variant="outlined"
        />
        <Button
          className={`${styles.button} ${
            isUpdateDisabled() && styles.wrapperDisabled
          }`}
          onClick={handleUpdateUserDetails}
          disabled={isUpdateDisabled()}
        >
          Update Details
        </Button>
      </div>
      <h3 className={styles.subscription}>Subscribed User Stocks</h3>
        <Autocomplete
        className={styles.autocomplete}
          multiple
          options={stocks}
          value={userStocks}
        ListboxComponent={ListboxComponent}
        getOptionLabel={(option) => {
          const stock = stocks.find((stock) => stock.id === option.id);
          return stock
            ? `${stock.stockName}${stock.stockExchange === "NSE" ? "" : " (BSE)"}`
            : "";
        }}
        getOptionKey={(option) => option.id}
        disableCloseOnSelect={true}
        isOptionEqualToValue={(option, value) => option.id === value}
        onChange={onChange}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              className={styles.chip}
              label={getNameOfStock(option)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Subscribe Stocks"
            placeholder="Select stocks to subscribe"
            ref={refAuto}
          />
        )}
      />
    </div>
  );
};

export default Home;
