import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import "./Analytics.css";
import htmlContent from './htmlContent';

const Analytics = () => {
    const navigate = useNavigate();
    const { userId, token, user, login } = useAuth();
    useEffect(() => {
        const id = localStorage.getItem("userId");
        const token = localStorage.getItem("token");
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        if (id !== null && token !== null) {
          login(id, token, user);
        }
    }, [login]);

    useEffect(() => {
        if (userId && token && user) {
            navigate("/home");
        }
    }, [userId, token, user, navigate]);

    const handleNavigate = (e) => {
        e.preventDefault();
        navigate("/login");
    };

    useEffect(() => {
        const button = document.getElementById('triggerButton');
        if (button) {
            button.addEventListener('click', handleNavigate);
        }

        return () => {
            if (button) {
                button.removeEventListener('click', handleNavigate);
            }
        };
    }, []);

    return (
        <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            style={{ width: '100%', height: 'auto' }}
        />
        // <div className="container">
        //     <h1>Stock Alert</h1>
        //     <p>Welcome to our information page about our Stock Alert platform. Here you can find details on how to analyze stock market trends and receive alerts via WhatsApp.</p>
            
        //     <h2>Features</h2>
        //     <ul>
        //         <li>Real-time stock market analysis</li>
        //         <li>WhatsApp alerts for stock movements</li>
        //     </ul>

        //     <h2>How It Works</h2>
        //     <p>Our platform uses advanced algorithms to analyze stock market data and provide you with actionable insights. You can set up WhatsApp alerts to stay informed about significant changes in the market.</p>
            
        //     <h2>Visit Our Analytics Platform</h2>
        //     <p>To access our Share Market Analytics platform, please visit the following link:</p>
        //     <a href="/login" onClick={handleNavigate}>Market Analytics Platform</a>

        //     <h2>Testimonials</h2>
        //     <p>Here’s what some of our users have to say about our platform:</p>
        //     <blockquote>
        //         <p>"The real-time analysis feature has been a game-changer for my trading strategy. I can now make informed decisions quickly!"</p>
        //     </blockquote>
        //     <blockquote>
        //         <p>"The WhatsApp alerts keep me updated on important stock movements even when I'm on the go. Highly recommend this service!"</p>
        //     </blockquote>

        //     <h2>FAQ</h2>
        //     <h3>What is Stock Alert?</h3>
        //     <p>Stock Alert is a platform that provides real-time and historical data analysis of stock market trends, helping users make informed investment decisions.</p>

        //     <h3>How do I set up WhatsApp alerts?</h3>
        //     <p>After creating an account on our platform, add stocks to receive alerts on WhatsApp.</p>

        //     {/* <h3>Is there a free trial available?</h3>
        //     <p>Yes, we offer a 14-day free trial with access to all features. Sign up today to start your trial.</p> */}

        //     <h2>Contact Us</h2>
        //     <p>If you have any questions or need further information, feel free to <a href="mailto:info@stockalert.co.in">contact us</a>.</p>
        // </div>
    );
};

export default Analytics;
